import React from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../Hooks/context";
import { useMediaQuery } from "react-responsive";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const RecentProd = ({ data, cartHandler, wishHandler }) => {
  const global = React.useContext(ThemeContext);
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  let val = data.price;
  let dis = data.discount ? data.discount / 100 : 0 / 100;
  let discountPrice = val - val * dis;
  return (
    <div className="product ">
      <figure className="product-media">
        <span>
          {/* <div
            style={{
              backgroundImage: "url(" + data.productImage[0] + ")",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
              backgroundSize: "cover",
              height: isTablet ? "16rem" : "28rem",
            }}
          ></div> */}
          <img
            src={data.productImage[0]}
            style={{
              height: isTablet ? "16rem" : "28rem",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />

          {/* <img src={data.productImage[0]} width={280} height={315} /> */}
        </span>
        {data.discount && data.discount > 0 ? (
          <div class="product-label-group">
            <label class="product-label label-sale">{data.discount}% off</label>
          </div>
        ) : null}
        <div
          className="product-action-vertical"
          onClick={() => {
            cartHandler(data._id);
          }}
        >
          <span
            href="#"
            className="btn-product-icon btn-cart"
            title="Add to cart"
          >
            <i className="d-icon-bag" />
          </span>
        </div>
        {/* terat here */}
      </figure>
      <div className="product-details">
        <span
          className="btn-wishlist"
          onClick={(res) => {
            wishHandler(data._id, res);
          }}
        >
          <i className="d-icon-heart" />
        </span>
        <div className="product-cat">
          <span href="shop-grid-3col.html">{data.category}</span>
        </div>
        <h3 className="product-name">
          <span href="product.html">{data.name}</span>
        </h3>
        <div className="product-price mb-6">
          <ins className="new-price">₦ {numberWithCommas(discountPrice)}</ins>
          {data.discount && data.discount > 0 ? (
            <del className="old-price">₦{numberWithCommas(data.price)}</del>
          ) : null}
        </div>

        {/* <div className="ratings-container">
          <div className="ratings-full">
            <span className="ratings" style={{ width: "100%" }} />
            <span className="tooltiptext tooltip-top" />
          </div>
          <span href="#" className="rating-reviews">
            ( <span className="review-count">6</span> reviews )
          </span>
        </div> */}

        <Link
          to={{
            pathname: `/product/c920d${data._id}ui89`,
            data: data,
          }}
          onClick={() => {
            global.setLoading(true);
            localStorage.setItem("product", JSON.stringify(data));
          }}
        >
          <div className="product-action">
            <span className="btn-product btn-quickview" title="Quick View">
              View Now
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};
