import axios from "./axios";
import { removeData } from "../Hooks/storage";

export const get_request = async (url) => {
  try {
    const token = await localStorage.getItem("token");
    let config = {
      headers: { Authorization: "Bearer " + token },
    };
    return await axios.get(url, config);
  } catch (err) {
    const error = err.response ? err.response : err;
    if (error.status === 401) {
      await removeData("token");
    }
    return error;
  }
};

export const post_request = async (url, payload) => {
  try {
    const token = await localStorage.getItem("token");
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return await axios.post(url, payload, options);
  } catch (err) {
    const error = err.response ? err.response : err;

    if (error.status === 401) {
      await removeData("token");
    }

    return error;
  }
};

export const patch_request = async (url, payload) => {
  try {
    const token = await localStorage.getItem("token");

    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return await axios.patch(url, payload, options);
  } catch (err) {
    const error = err.response ? err.response : err;

    if (error.status === 401) {
      await removeData("token");
    }
    return error;
  }
};

export const put_request = async (url, payload) => {
  try {
    const token = await localStorage.getItem("token");

    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return await axios.put(url, payload, options);
  } catch (err) {
    const error = err.response ? err.response : err;

    if (error.status === 401) {
      await removeData("token");
    }

    return error;
  }
};

export const delete_request = async (url, payload) => {
  try {
    const token = await AsyncStorage.getItem("token");
    const options = {
      headers: { Authorization: `Bearer ${token}` },
      data: payload,
    };

    return await axios.delete(url, options);
  } catch (err) {
    const error = err.response ? err.response : err;

    if (error.status === 401) {
      await removeData("token");
    }

    return error;
  }
};
