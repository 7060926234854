import React from "react";

const Errors = () => {
  return (
    <div>
      <span>error</span>
    </div>
  );
};

export default Errors;
