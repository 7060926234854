import React, { useState } from "react";
import Login from "./login";
import {
  Header,
  Footer,
  Mobilenav,
  MobileSidebar,
} from "../components/General";
import {
  ItemCase,
  SideList,
  EmptyProduct,
  Pagination,
} from "../components/ProductlistComp";
import { Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import { ThemeContext } from "../Hooks/context";

// import { products } from "../test/test-products";

const ProductList = (prop) => {
  const [products, setProducts] = React.useState([]);
  const [empty, setEmpty] = React.useState(false);

  const global = React.useContext(ThemeContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(48);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);

  React.useEffect(() => {
    if (prop.location.data) {
      if (prop.location.data.length < 1) {
        setEmpty(true);
      } else {
        setEmpty(false);
        setProducts(prop.location.data);
      }
    }
    if (!prop.location.data) {
      const value = localStorage.getItem("productlist");
      if (value && JSON.parse(value).length > 0) {
        setProducts(JSON.parse(value));
        // getProducts();
      } else {
        setEmpty(true);
      }
    }
    global.setLoading(false);
  }, [prop.location.data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const next = (pageNumber) => {
    setCurrentPage(pageNumber + 1);
  };

  const prev = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  return global.loading || global.error ? null : (
    <>
      <div className="page-wrapper push">
        {/* End Header */}
        <main style={{ marginTop: "7%",minHeight:'100vh',height:'100%' }}>
          {/* End PageHeader */}
          {empty ? (
            <div className="page-content mb-10" style={{ marginTop: "6%" }}>
              <div className="container">
                <div className="row main-content-wrap gutter-lg">
                  <SideList />
                  <div className="col-lg-9 main-content">
                    <nav className="toolbox sticky-toolbox sticky-content fix-top">
                      <EmptyProduct text={prop.location.text} />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="page-content mb-10" style={{ marginTop: "6%" }}>
              <div className="container">
                <div className="row main-content-wrap gutter-lg">
                  <SideList />

                  <div className="col-lg-9 main-content">
                    <nav className="toolbox sticky-toolbox sticky-content fix-top">
                      <Col
                        lg="12"
                        className="d-flex justify-content-center"
                        style={{ textAlign: "center", marginTop:"3em"  }}
                      >
                        <h4 className="mt-2 mb-2">
                          {prop.location.text ? prop.location.text : null}
                        </h4>
                      </Col>
                      {/* <div className="toolbox-left">
                        <a className="toolbox-item left-sidebar-toggle btn btn-sm btn-outline btn-primary d-lg-none">
                          Filters
                          <i className="d-icon-arrow-right" />
                        </a>
                      </div> */}
                      <div className="toolbox-right">

                      </div>
                    </nav>
                    <div className="row cols-2 cols-sm-3 cols-md-4 product-wrapper">
                      {currentPosts.map((res) => {
                        return <ItemCase data={res} />;
                      })}
                    </div>

                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={products.length}
                      paginate={paginate}
                      current={currentPage}
                      currentPosts={currentPosts}
                      next={next}
                      prev={prev}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
        {/* End Main */}
      </div>
    </>
  );
};

export default ProductList;
