import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ThemeContext } from '../Hooks/context';
import { Container, Col, Button } from 'react-bootstrap';
import { get_request, post_request } from '../services/makeRequest';


function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const SideList = () => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();
  const [cat, setCat] = useState([]);

  // console.log(cat);
  useEffect(() => {
    let mounted = true;
    getCategories().then((res) => {
      if (mounted) {
        setCat(res.categories);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);
  const getCategories = async () => {
    const request = await fetch(
      process.env.REACT_APP_API_URL + '/general/categories',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return request.json();
  };
  // const handleCategory = (value) => {
  //   global.setLoading(true);
  //   // res.preventDefault();

  //   fetch(process.env.REACT_APP_API_URL + '/products/category', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       category: value,
  //     }),
  //   })
  //     .then(async (response) => {
  //       if (!response.ok) {
  //         global.setLoading(false);
  //       }
  //       if (response.ok) {
  //         const data = await response.json();
  //         console.log(data)

  //         localStorage.setItem('productlist', JSON.stringify(data.category));
  //         history.push({
  //           pathname: '/productlist',
  //           data: data.category,
  //           text: value,
  //         });
  //         global.setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       global.setLoading(false);
  //       // setToast(true);
  //     });
  // };
  const handleCategory = async (value) => {
    console.log(value)

    global.setLoading(true);
    try {
      const url = '/products/category';

      const payload = {
        category: value,
      };
      let response;
      if (value.includes("uino")) {
        response = await post_request(url, { category: "Arduino" });
      } else {
        response = await post_request(url, payload);
      }
      // const response = await post_request(url, payload);

      if (!response) {
        global.setLoading(false);
      }

      if (response) {
        const data = response.data;
        localStorage.setItem('productlist', JSON.stringify(data.category));
        history.push({
          pathname: '/productlist',
          data: data.category,
          text: value,
        });
        global.setLoading(false);
      }
    } catch (err) {
      global.setLoading(false);
    }
  };
  return (
    <aside className="col-lg-3 sidebar sidebar-fixed sidebar-toggle-remain shop-sidebar sticky-sidebar-wrapper" style={{ position: 'relative' }}>
      <div className="sidebar-overlay">
        <a className="sidebar-close">
          <i className="d-icon-times" />
        </a>
      </div>
      <div className="sidebar-content" >
        <div className="sticky-sidebar" data-sticky-options="{'top': 10}" style={{ position: 'fixed', top: '15%', bottom: '0', height: '100%' }}>
          <div className="filter-actions">
            <a className="sidebar-toggle-btn toggle-remain btn btn-sm btn-outline btn-primary">
              {/* Filters */}
              <i className="d-icon-arrow-left" />
            </a>
            {/* <a className="filter-clean text-primary">Clean All</a> */}
          </div>
          <div className="widget widget-collapsible">
            <h3 className="widget-title">All Categories</h3>
            <ul className="widget-body filter-items search-ul">
              {cat.map((res, idx) => {
                return (
                  <li
                    style={{ cursor: 'pointer' }}
                    className="mb-2"
                    key={idx}
                    onClick={() => handleCategory(res.title)}
                  >
                    <a>{res.title}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export const ItemCase = ({ data }) => {
  const global = React.useContext(ThemeContext);

  function PostRecent(value) {
    fetch(
      process.env.REACT_APP_API_URL +
      '/recent/updaterecent/' +
      global.userInfo.recentId._id,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product: value,
        }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          global.setError(true);
          global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          // global.setLogin(false);
        }
      })
      .catch((error) => {
        global.setError(true);
        global.setLoading(false);
      });
  }

  let val = data ? data.price : "";
  let dis = data.discount ? data.discount / 100 : 0 / 100;
  let discountPrice = val - val * dis;
  return (
    <div className="product-wrap list-img-container">
      <Link
        to={{
          pathname: `/product/c920d${data._id}ui89`,
          data: data,
        }}
        onClick={() => {
          localStorage.setItem('product', JSON.stringify(data));
          global.userInfo ? PostRecent(data._id) : console.log();
        }}
      >
        <div className="product">
          <figure className="product-media">
            <span href="product.html">
              {/* <div
                style={{
                  height: "20rem",
                  backgroundImage: `url(${data.productImage[0]})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                alt="productImage"
              /> */}
              <img
                src={data.productImage[0]}
                style={{
                  height: '20rem',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              />
              {data.discount && data.discount > 0 ? (
                <div class="product-label-group">
                  <label class="product-label label-sale">
                    {data.discount}% off
                  </label>
                </div>
              ) : null}
            </span>
            {/* <div className="product-label-group">
            <label className="product-label label-new">new</label>
          </div> */}
            <div className="product-action-vertical">
              <span
                href="#"
                className="btn-product-icon btn-cart"
                data-toggle="modal"
                data-target="#addCartModal"
                title="Add to cart"
              >
                <i className="d-icon-bag" />
              </span>
            </div>
          </figure>
          <div className="product-details">
            {/* <span href="#" className="btn-wishlist" title="Add to wishlist">
              <i className="d-icon-heart" />
            </span> */}
            <div className="product-cat">
              <span href="shop-grid-3col.html">{data.category}</span>
            </div>
            <h3 className="product-name">
              <span href="product.html">{data.name}</span>
            </h3>
            <div className="product-price">
              <ins className="new-price">
                ₦ {numberWithCommas(discountPrice)}
              </ins>
              <del className="old-price">₦ {numberWithCommas(data.price)}</del>
            </div>
            <div className="ratings-container">
              {/* <div className="ratings-full">
                <span className="ratings" style={{ width: "100%" }} />
                <span className="tooltiptext tooltip-top" />
              </div>
              {/* <span href="product.html" className="rating-reviews">
              ( 6 reviews )
            </span> */}
            </div>
            <div className="product-action" style={{ zIndex: '0' }}>
              <span
                href="#"
                className="btn-product btn-quickview"
                title="Quick View"
              >
                View
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const EmptyProduct = ({ text }) => {
  return (
    <Container className="d-flex flex-column align-items-center pt-5 pb-5">
      <Col lg="10" className="d-flex justify-content-center">
        <img src="https://www.jumia.com.ng/assets_he/images/binoculars.41e1bc35.svg" />
      </Col>

      <Col lg="10" className="d-flex flex-column align-items-center">
        <h5>There are no results for “{text}”.</h5>
        <span>- Check your spelling for typing errors</span>
        <span>- Try searching with short and simple keywords</span>
        <span>
          - Try searching more general terms - you can then filter the search
          results
        </span>
      </Col>

      <Col lg="10" className="d-flex flex-column align-items-center mt-6 mb-6">
        <Link to="/">
          <Button
            size="lg"
            outline
            squared="true"
            theme="success"
            className="accontButton"
          // onClick={global.Logout}
          >
            GO TO HOMEPAGE
          </Button>
        </Link>
      </Col>
    </Container>
  );
};

export const Pagination = ({
  postsPerPage,
  totalPosts,
  paginate,
  current,
  currentPosts,
  next,
  prev,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="toolbox toolbox-pagination">
      <p className="show-info">
        Showing{' '}
        <span>
          {currentPosts.length} of {totalPosts}
        </span>
        Products
      </p>
      <ul className="pagination">
        <li className="page-item">
          <a
            className="page-link page-link-prev"
            aria-label="Previous"
            onClick={() => {
              current === 1 ? null : prev(current);
            }}
          >
            <i className="d-icon-arrow-left" />
            Prev
          </a>
        </li>

        {pageNumbers.map((number) => {
          return (
            <li
              key={number}
              className={number === current ? 'page-item active' : 'page-item'}
              aria-current="page"
            >
              <a
                onClick={() => {
                  paginate(number);
                }}
                className="page-link"
              >
                {number}
              </a>
            </li>
          );
        })}

        <li className="page-item">
          <a
            className="page-link page-link-next"
            aria-label="Next"
            onClick={() => {
              pageNumbers.length === current ? null : next(current);
            }}
          >
            Next
            <i className="d-icon-arrow-right" />
          </a>
        </li>
      </ul>
    </nav>
  );
};
