import React, { useState, useEffect } from "react";
import { ThemeContext } from "./Hooks/context";
import { Route, Switch, Redirect } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  Loader,
  Header,
  Footer,
  Mobilenav,
  MobileSidebar,
  Modals,
  Error,
} from "./components/General";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "shards-ui/dist/css/shards.min.css";
import './App.css'
import Home from "./pages/Home";
// import Carousels from "./pages/header";
import Errors from "./pages/Error";
import Product from "./pages/Product";
import About from "./pages/about";
import Cart from "./pages/cart";
import Contactus from "./pages/contactus";
import ProductList from "./pages/Productlist";
import UserHome from "./pages/Account";
import Seller from "./pages/seller";
import ScrollToTop from "./Hooks/scroll";
import ResetPassword from "./pages/Reset";
import VerifyAccount from "./pages/verify";
import ProductLink from "./middleware/product";
import SellerProfile from "./middleware/seller";
import Soon from "./pages/Soon";
import { LaidByPayment, LaidByPay } from "./components/cartComp"
// import TheLayout from "./pages/layout";
import Login from "./pages/login";

function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [bugger, setBugger] = useState(false);
  const [login, setLogin] = useState(false);
  const [open, setOpen] = useState();
  const [message, setMessage] = useState();
  const [exit, setExit] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 })
    const getUserinfo = async () => {
      try {
        const value = await localStorage.getItem("userInfo"); //removeItem() getAllKeys()

        if (value !== null && userInfo === null) {
          const json = await JSON.parse(value);
          setUserInfo(json);
        }

        if (value === null && userInfo === null) {
          // setSuccess(false);
          console.log("error");
        }
      } catch (e) {
        // error reading value
        console.log("shit could not be read");
      }
    };
    getUserinfo();
  }, []);

  const Logout = () => {
    localStorage.removeItem("userInfo");
    setUserInfo(null);
    setRefresh(true);
  };

  function toggleModal() {
    setOpen(!open);
    if (exit) {
      setRefresh(true);
    }
  }

  const isMobile = useMediaQuery({ query: "(max-width: 479px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <Switch>
      <ThemeContext.Provider
        value={{
          userInfo,
          setUserInfo,
          setLoading,
          loading,
          Logout,
          bugger,
          setBugger,
          login,
          setLogin,
          refresh,
          setRefresh,
          redirect,
          setRedirect,
          setOpen,
          setMessage,
          setExit,
          error,
          setError,
        }}
      >
        <Header />
        <ScrollToTop />
        <Route path="/" component={Home} exact />
        <Route path="/cart" component={Cart} />
        <Route path="/product/:id" component={ProductLink} />
        <Route path="/product/:id" component={Product} />
        <Route path="/aboutus" component={About} />
        <Route path="/productlist" component={ProductList} />
        <Route path="/contactus" component={Contactus} />
        <Route path="/account" component={UserHome} />
        <Route path="/sellerprofile/:id" component={SellerProfile} />
        <Route path="/sellerprofile" component={Seller} />
        <Route path="/lay-buy/:id" component = {LaidByPayment} />
        <Route path="/laybuy/:id" component = {LaidByPay} />
        <Route path="/passwordreset/:token/:id" component={ResetPassword} />
        <Route path="/verifyaccount/:token/:id" component={VerifyAccount} />
        <Route path="/404">
          <Errors />
        </Route>
        {error ? <Error /> : null}
        <Footer />
        {isMobile || isTablet ? <Mobilenav /> : null}
        {isMobile ? <MobileSidebar /> : null}
        <Modals open={open} message={message} toggleModal={toggleModal} />
        {loading ? <Loader /> : null}
        {login ? <Login /> : null}
        {refresh ? window.location.reload() : null}
        {redirect ? <Redirect to="/" /> : null}
      </ThemeContext.Provider>
    </Switch>
  );
}

export default App;
