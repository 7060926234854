import React, { useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

import { RecentProd } from "../components/productComp";
import { ThemeContext } from "../Hooks/context";
import { Col, Card, Button, Tooltip, Alert, Modal } from "react-bootstrap";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Modals } from "../components/General";
import { get_request, patch_request } from "../services/makeRequest";

// import "../styles/css/style.min.css";

const Product = (prop) => {
  const [tip, setTip] = React.useState(false);
  const [recent, setRecent] = useState();
  const [data, setData] = useState();
  const [home, setHome] = useState();
  const [seller, setSeller] = useState();

  const [current, setCurrent] = useState("desc");

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();

  const [showM, setShowM] = useState(false);

  const global = React.useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    global.userInfo && GetRecent();
    if (!prop.location.data) {
      const value = localStorage.getItem("product");
      value ? productHandler(JSON.parse(value)) : global.setRedirect(true);
      GetSeller(JSON.parse(value));
    }
    if (prop.location.data) {
      setData(prop.location.data);
      global.setLoading(false);
      GetSeller(prop.location.data);
    }
    setCurrent("desc");
  }, [global.userInfo, prop.location.data]);

  const productHandler = (value) => {
    setData(value);
    global.setLoading(false);
  };

  const cartRedirect = async () => {
    global.setOpen(true);
    global.setMessage("You need to sign in to ADD a product to cart 😅");
  };

  const cartHandler = async (value) => {
    global.setLoading(true);
    try {
      const url = "/cart/updatecart/" + global.userInfo.cartId;

      const payload = [
        {
          propName: "product",
          value: value,
        },
      ];

      const response = await patch_request(url, payload);

      if (!response) {
        global.setLoading(false);
      }

      if (response) {
        const data = response.data;
        setOpen(true);
        setMessage("Your cart has been Updated 👍");
        global.setLoading(false);
      }
    } catch (err) {
      setMessage("ErrMsg");
      global.setLoading(false);
    }
  };

  const wishHandler = async (value, res) => {
    global.setLoading(true);
    res.preventDefault();
    try {
      const url = "/wish/updatewish/" + global.userInfo.wishId._id;

      const payload = {
        product: value,
      };

      const response = await patch_request(url, payload);

      if (!response) {
        if (response.status === 409) {
          setOpen(true);
          setMessage("This product is already in your Wishlist  😅");
          global.setLoading(false);
        } else {
          setOpen(true);
          setMessage("wishError");
          global.setLoading(false);
        }

        global.setLoading(false);
      }

      if (response) {
        setOpen(true);
        setMessage("wishSuccess");
        global.setLoading(false);
      }
    } catch (err) {
      setMessage("ErrMsg");
      global.setLoading(false);
    }
  };

  const GetRecent = async () => {
    global.setLoading(true);
    try {
      const url = "/recent/" + global.userInfo.recentId._id;

      const response = await get_request(url);

      if (!response) {
        // global.setLoading(false);
      }

      if (response) {
        const data = response.data;
        data.recent.items.reverse();
        setRecent(data.recent.items);
        global.setLoading(false);
      }
    } catch (err) {
      global.setLoading(false);
    }
  };

  const GetSeller = async (value) => {
    global.setLoading(true);
    try {
      const url = "/seller/public/" + value.sellerId;

      const response = await get_request(url);

      if (!response) {
        // global.setLoading(false);
      }

      if (response) {
        const data = response.data;
        setSeller(data.seller);
        global.setLoading(false);
      }
    } catch (err) {
      // global.setLoading(false);
    }
  };

  // utils
  function toggleModal() {
    setOpen(!open);
    global.setRefresh(true);
  }

  let val = data ? data.price : 0;
  let dis = data && data.discount ? data.discount / 100 : 0 / 100;
  let discountPrice = val - val * dis;

  const deliveryDate = data
    ? new Date().setDate(new Date().getDate() + data.estimatedDelivery)
    : null;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const addToCart = () => {
    setShowM(true)
    global.userInfo ? cartHandler(data._id) : cartRedirect();
    // setTimeout(() => {
    //   window.location.reload()
    // }, 800);
  };
  const buyProduct = () => {
    global.userInfo ? cartHandler(data._id) : cartRedirect();
    if(global.userInfo){
    history.push("/cart");
    setTimeout(() => {
      window.location.reload();
    }, 800);
  } else{
    setShowM(true)
  }
  };

  //  app

  return global.loading || !data ? null : (
    <div>
      <div className="page-wrapper">
        {/* End Header */}
        <main className="main mt-4">
          <div className="page-content mb-10">
            <div className="container mainMargin">
              <div className="product product-single row mb-4">
                {global.userInfo && showM ? (
                  <div style={{ position: "fixed", background: "transparent", zIndex:"999999" }}>
                    <div class="modal-dialog" style={{ zIndex: "9999999999999" }}>
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Hello 👋
                          </h5>
                          {/* <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button> */}
                        </div>
                        <div class="modal-body">
                          Item succesfully added to cart
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-primary" onClick={() => { window.location.reload();}}>
                              Ok, thanks
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ): showM  ? (
                  <div style={{ position: "fixed", background: "transparent", zIndex:"999999" }}>
                  <div class="modal-dialog" style={{ zIndex: "9999999999999" }}>
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Hello 👋
                        </h5>
                        {/* <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button> */}
                      </div>
                      <div class="modal-body">
                        You need to login to add a product to cart or buy a product
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-primary" onClick={() => { setShowM(false); global.setLogin(true);}}>
                            Ok, thanks
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                )  : (
                  <></>
                )}

                <div className="col-md-6 mt-8">
                  <div className="product-gallery">
                    <CarouselProvider
                      naturalSlideWidth={600}
                      naturalSlideHeight={600}
                      totalSlides={data.productImage.length}
                      style={{ boxShadow: "none" }}
                      className="homeCat"
                    >
                      <Slider className="slider-img-case">
                        {data.productImage.map((res, index) => {
                          return (
                            <Slide index={index} key={index}>
                              <Col
                                lg="12"
                                xs=""
                                className="product-image img-fluid"
                              >
                                <img src={res} width={800} height={900} />
                              </Col>
                            </Slide>
                          );
                        })}
                      </Slider>

                      <div className="d-flex flex-row justify-content-space-between">
                        <ButtonBack className="c-btns ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            class="bi bi-chevron-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                            />
                          </svg>
                        </ButtonBack>
                        <ButtonNext className="c-btns" style={{ right: "1%" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            class="bi bi-chevron-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </ButtonNext>
                      </div>
                    </CarouselProvider>

                    <div className="product-thumbs-wrap">
                      <div className="product-thumbs">
                        {data.productImage.map((res, index) => {
                          return (
                            <div key={index} className="product-thumb">
                              <img
                                src={res}
                                alt="product thumbnail"
                                width={109}
                                height={122}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <button className="thumb-up disabled">
                        <i className="fas fa-chevron-left" />
                      </button>
                      <button className="thumb-down disabled">
                        <i className="fas fa-chevron-right" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-8">
                  <div className="product-details">
                    <h1 className="product-name">{data.name}</h1>
                    <h5>BRAND: {data.brand}</h5>
                    <div className="product-price">
                      ₦ {numberWithCommas(discountPrice)}
                    </div>

                    {data.discount && data.discount > 0 ? (
                      <div
                        className="product mb-5  old-price"
                        style={{ textDecoration: "line-through" }}
                      >
                        ₦ {numberWithCommas(data.price)}
                      </div>
                    ) : null}

                    <div style={{ marginTop: "25%" }} />
                    {/* <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: "80%" }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                      <span
                        href="#product-tab-reviews"
                        className="link-to-tab rating-reviews"
                      >
                        ( 6 reviews )
                      </span>
                    </div> */}

                    {/* <div className="product-form product-color">
                      <label>Color:</label>
                      <div className="product-variations">
                        <span
                          className="color"
                          data-src="images/demos/demo7/products/big1.jpg"
                          href="#"
                          style={{ backgroundColor: "#d99e76" }}
                        />
                        <span
                          className="color"
                          data-src="images/demos/demo7/products/2.jpg"
                          href="#"
                          style={{ backgroundColor: "#267497" }}
                        />
                        <span
                          className="color"
                          data-src="images/demos/demo7/products/3.jpg"
                          href="#"
                          style={{ backgroundColor: "#9a999d" }}
                        />
                        <span
                          className="color"
                          data-src="images/demos/demo7/products/4.jpg"
                          href="#"
                          style={{ backgroundColor: "#2b2b2b" }}
                        />
                      </div>
                    </div> */}

                    <div className="product-variation-price">
                      <span>₦ 239.00</span>
                    </div>
                    <div
                      style={{
                        margin: "10px",
                        position: "absolute",
                        top: "30%",
                        right: "0%",
                        cursor: "pointer",
                      }}
                    >
                      <h4
                        className="btn-product btn-wishlist"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `isheda.com${location.pathname}`
                          );
                          alert("link copied");
                        }}
                      >
                        <i class="fas fa-share-alt"></i>
                        share
                      </h4>
                    </div>
                    <hr className="product-divider mt-10" />
                    <div className="product-form product-qty">
                      {/* <label>QTY:</label> */}
                      <div className="product-form-group d-flex flex-row justify-content-center">
                        {/* <div className="input-group">
                          <button className="quantity-minus d-icon-minus" />
                          <input
                            className="quantity form-control"
                            type="number"
                            min={1}
                            max={1000000}
                          />
                          <button className="quantity-plus d-icon-plus" />
                        </div> */}

                        <div
                          className="product-action mr-3"
                          style={{ cursor: "pointer" }}
                          onClick={(res) => {
                            global.userInfo
                              ? wishHandler(data._id, res)
                              : global.setLogin(true);
                          }}
                        >
                          <h4 className="btn-product btn-wishlist">
                            <i className="d-icon-heart" />
                            Add To Wishlist
                          </h4>
                          <span className="divider" />
                        </div>

                        <button
                          className="btn-product btn-cart"
                          onClick={addToCart}
                        >
                          <i className="d-icon-bag" />
                          Add To Cart
                        </button>

                        <div
                          className="product-action mr-3"
                          id="shear"
                          style={{ cursor: "pointer" }}
                          onClick={(res) => {
                            navigator.clipboard.writeText(
                              "https://www.isheda.com/product/" + data._id
                            );
                            setTip(true);
                          }}
                        >
                          <span className="divider" />
                          <h4
                            className="btn-product btn-wishlist"
                            style={{ color: "#00a567", fontSize: "16px" }}
                            onClick={buyProduct}
                          >
                            Buy Now
                          </h4>
                          <Tooltip
                            open={tip}
                            target="#shear"
                            toggle={() => {
                              setTip(tip ? !tip : null);
                            }}
                          >
                            {/* <h6 className="mb-0">Link Copied ✌️</h6> */}
                          </Tooltip>

                          {/* <span className="divider" /> */}
                        </div>
                      </div>
                    </div>
                    <hr className="product-divider mb-3" />
                    <ul className="product-status mt-4 list-style-none list-type-check pl-0">
                      <li>
                        Place an order today and get it{" "}
                        <span style={{ color: "#00a567" }}>
                          ON OR BEFORE{" "}
                          {new Date(deliveryDate)
                            .toISOString()
                            .replace(/T.*/, "")
                            .split("-")
                            .reverse()
                            .join("-")}
                        </span>
                        .
                      </li>
                      <li>Nationwide shipping</li>
                      <li>
                        Return within 7 days of delivery &amp; Fulltime response
                        team
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tab tab-nav-simple product-tabs mb-4">
                <ul className="nav nav-tabs" role="tablist">
                  <li
                    className="nav-item"
                    onClick={() => {
                      setCurrent("desc");
                    }}
                  >
                    <span
                      className={
                        current === "desc" ? "nav-link active" : "nav-link"
                      }
                      href="#product-tab-description"
                    >
                      Description
                    </span>
                  </li>
                  {/* <li className="nav-item">
                    <span className="nav-link" href="#product-tab-additional">
                      Additional
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className="nav-link"
                      href="#product-tab-shipping-returns"
                    >
                      Shipping &amp; Returns
                    </span>
                  </li> */}
                  {/* <li
                    className="nav-item"
                    onClick={() => {
                      setCurrent("rev");
                    }}
                  >
                    <span
                      className={
                        current === "rev" ? "nav-link active" : "nav-link"
                      }
                    >
                      Reviews
                    </span>
                  </li> */}

                  {!seller ? null : (
                    <li
                      className="nav-item"
                      onClick={() => {
                        setCurrent("sel");
                      }}
                    >
                      <span
                        className={
                          current === "sel" ? "nav-link active" : "nav-link"
                        }
                      >
                        Seller Information
                      </span>
                    </li>
                  )}
                </ul>
                <div className="tab-content">
                  <div
                    className={
                      current === "desc"
                        ? "tab-pane  in active"
                        : "tab-pane  in"
                    }
                  >
                    <p>{data.description}</p>
                  </div>

                  <div
                    className={
                      current === "rev" ? "tab-pane  in active" : "tab-pane"
                    }
                  >
                    {/* <div className="d-flex align-items-center mb-5">
                      <h4 className="mb-0 mr-2">Average Rating:</h4>
                      <div className="ratings-container average-rating mb-0">
                        <div className="ratings-full">
                          <span className="ratings" style={{ width: "80%" }} />
                          <span className="tooltiptext tooltip-top">4.00</span>
                        </div>
                      </div>
                    </div>
                    <div className="comments mb-6">
                      <ul>
                        <li>
                          <div className="comment">
                            <figure className="comment-media">
                              <span href="#">
                                <img
                                  src="images/blog/comments/1.jpg"
                                  alt="avatar"
                                />
                              </span>
                            </figure>
                            <div className="comment-body">
                              <div className="comment-rating ratings-container mb-0">
                                <div className="ratings-full">
                                  <span
                                    className="ratings"
                                    style={{ width: "80%" }}
                                  />
                                  <span className="tooltiptext tooltip-top">
                                    4.00
                                  </span>
                                </div>
                              </div>
                              <div className="comment-user">
                                <h4>
                                  <span href="#">Jimmy Pearson</span>
                                </h4>
                                <span className="comment-date">
                                  November 9, 2018 at 2:19 pm
                                </span>
                              </div>
                              <div className="comment-content">
                                <p>
                                  Sed pretium, ligula sollicitudin laoreet
                                  viverra, tortor libero sodales leo, eget
                                  blandit nunc tortor eu nibh. Nullam mollis. Ut
                                  justo. Suspendisse potenti. Sed egestas, ante
                                  et vulputate volutpat, eros pede semper est,
                                  vitae luctus metus libero eu augue. Morbi
                                  purus libero, faucibus adipiscing, commodo
                                  quis, avida id, est. Sed lectus. Praesent
                                  elementum hendrerit tortor. Sed semper lorem
                                  at felis. Vestibulum volutpat.
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="comment">
                            <figure className="comment-media">
                              <span href="#">
                                <img
                                  src="images/blog/comments/3.jpg"
                                  alt="avatar"
                                />
                              </span>
                            </figure>
                            <div className="comment-body">
                              <div className="comment-rating ratings-container mb-0">
                                <div className="ratings-full">
                                  <span
                                    className="ratings"
                                    style={{ width: "80%" }}
                                  />
                                  <span className="tooltiptext tooltip-top">
                                    4.00
                                  </span>
                                </div>
                              </div>
                              <div className="comment-user">
                                <h4>
                                  <span href="#">Johnathan Castillo</span>
                                </h4>
                                <span className="comment-date">
                                  November 9, 2018 at 2:19 pm
                                </span>
                              </div>
                              <div className="comment-content">
                                <p>
                                  Vestibulum volutpat, lacus a ultrices
                                  sagittis, mi neque euismod dui, eu pulvinar
                                  nunc sapien ornare nisl. Phasellus pede arcu,
                                  dapibus eu, fermentum et, dapibus sed, urna.
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div> */}
                    <div>
                      <Card
                        style={{
                          boxShadow: "none",
                          border: "1px solid #ededed",
                        }}
                      >
                        <Card.Body>
                          <Col
                            lg="12"
                            className="d-flex justify-content-center pt-10 pb-5"
                          >
                            <h5>THERE ARE NO REVIEWS AT THE MOMENT</h5>
                          </Col>
                        </Card.Body>
                      </Card>
                    </div>
                    {/* End Comments */}
                  </div>

                  {!seller ? null : (
                    <div
                      className={
                        current === "sel" ? "tab-pane  in active" : "tab-pane"
                      }
                    >
                      <div>
                        <Card
                          style={{
                            boxShadow: "none",
                            border: "1px solid #ededed",
                            padding: "20px",
                            textAlign: "center",
                          }}
                        >
                          <Card.Body>
                            <Col
                              lg="12"
                              className="d-flex flex-column pt-5 pb-5"
                            >
                              <div className="d-flex justify-content-center text-uppercase">
                                <h5>{seller.business.name}</h5>
                              </div>

                              <h6 className="mb-0">
                                Address:
                                <span
                                  style={{
                                    fontFamily: "sanfrancisco",
                                    marginLeft: "1%",
                                  }}
                                >
                                  {seller.business.address}
                                </span>
                              </h6>
                              {/* <h6>
                                Contact Number:
                                <span
                                  style={{
                                    fontFamily: "sanfrancisco",
                                    marginLeft: "1%",
                                  }}
                                >
                                  {seller.phoneNo}
                                </span>
                              </h6> */}

                              {/* <h5>Performance</h5>

                              <div className="d-flex flex-row " style={{justifyContent:"center"}}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="#00a567"
                                  class="bi bi-star"
                                  viewBox="0 0 16 16"
                                  className="mr-2"
                                >
                                  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                </svg>
                                <h6 class="mb-0">
                                  Order Fulfillment Rate: Excellent
                                </h6>
                              </div>

                              <div className="d-flex flex-row " style={{justifyContent:"center"}}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="#00a567"
                                  class="bi bi-star"
                                  viewBox="0 0 16 16"
                                  className="mr-2"
                                >
                                  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                </svg>
                                <h6 class="mb-0">Quality Score: Excellent</h6>
                              </div> */}
                            </Col>
                          </Card.Body>

                          {/* <Card.Footer className="sideCard.Footer">
                            <Link
                              to={{
                                pathname: "/sellerprofile",
                                data: seller,
                              }}
                              onClick={() => {
                                global.setLoading(true);
                                localStorage.setItem(
                                  "seller",
                                  JSON.stringify(seller)
                                );
                              }}
                            >
                            </Link>
                          </Card.Footer> */}
                        </Card>
                      </div>
                      {/* End Comments */}
                    </div>
                  )}
                </div>
              </div>
              {/* {!recent ? null : (
                <section>
                  <h2 className="title">Recently viewed items</h2>
                  <div className=" row cols-2 cols-md-3 cols-lg-4 owl-carousel">
                    {recent.map((data) => {
                      return (
                        <RecentProd
                          data={data.product}
                          cartHandler={cartHandler}=6
                          wishHandler={wishHandler}
                        />
                      );
                    })}
                  </div>
                </section>
              )} */}
            </div>
          </div>
          <Modals open={open} message={message} toggleModal={toggleModal} />
        </main>
        {/* End Main */}
      </div>
    </div>
  );
};

export default Product;
