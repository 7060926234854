import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Container,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import { ThemeContext } from "../Hooks/context";

const ResetPassword = (props) => {
  const global = React.useContext(ThemeContext);

  const [password, setPassword] = useState("");
  const [Conpassword, setConPassword] = useState("");
  const [user, setUser] = useState();
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState();
  const [message, setMessage] = useState();

  const fpassword = password === Conpassword ? Conpassword : null;

  const token = props.match.params.token;
  const id = props.match.params.id;
  const url = "https://isheda-backend.onrender.com";

  useEffect(() => {
    handleInfo();
  }, []);

  function toggleModal() {
    setOpen(!open);
    global.setRedirect(true);
  }

  const handleInfo = async () => {
    global.setLoading(true);
    try {
      const finalUrl = url + "/user/" + id;

      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.get(finalUrl, options);

      if (!response) {
        global.setRedirect(true);
      }

      if (response) {
        const data = response.data;
        setUser(data);
        global.setLoading(false);
      }
    } catch (err) {
      global.setRedirect(true);
    }
  };

  const updatePassword = async () => {
    global.setLoading(true);
    try {
      const finalUrl = url + "/user/resetpass/" + user._id;

      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const payload = fpassword
        ? {
            password: fpassword,
          }
        : {};

      const response = await axios.patch(finalUrl, payload, options);

      if (!response) {
        if (response.status === 401) {
          global.setLogin(true);

          global.setLoading(false);
        }
      }

      if (response) {
        setMessage("success");
        setOpen(true);
        setSuccess(true);
        global.setLoading(false);
      }
    } catch (err) {
      setMessage("ErrPass");
      setOpen(true);
      global.setLoading(false);
    }
  };

  const Modals = ({ open, message, toggleModal }) => {
    return (
      <Modal size="sm" open={open} toggle={toggleModal}>
        <Modal.Header>
          {message === "success"
            ? "SUCCESS"
            : message === "ErrMsg"
            ? "ERROR!"
            : message === "editSuccess"
            ? "SUCCESS"
            : message === "editError"
            ? "sorry, something went wrong.Your profile will remain unchanged 💯"
            : "Message"}
        </Modal.Header>
        <Modal.Body>
          <h6>
            {message === "success"
              ? "Your password has been Updated 👍"
              : message === "wishSuccess"
              ? "Your wish list has been Updated 👍"
              : message === "wishError"
              ? "something Went Wrong, we could not update your wish list 😅"
              : message === "ErrMsg"
              ? "something Went Wrong, Your password will remain UNCHANGED 💯"
              : message === "editSuccess"
              ? "Your profile has been Updated 👍"
              : message === "editError"
              ? "sorry, something went wrong.Your profile will remain unchanged 💯"
              : message}
          </h6>
        </Modal.Body>
      </Modal>
    );
  };

  return global.loading ? null : (
    <div className="pt-10 pb-10">
      <Container className="mb-4 pt-10 d-flex justify-content-center">
        <Col lg="8" className="sideCol2">
          <Card style={{ minHeight: "100%" }}>
            <Card.Header
              style={{
                backgroundColor: "transparent",
                borderBottom: "1px solid #ededed",
                display: "flex",
                justifyContent: "center",
                color: "#495057",
              }}
            >
              Set New Password
            </Card.Header>
            <Card.Body>
              <Col className="d-flex justify-content-center">
                <h6>
                  Hello {user.firstname},Enter a new password for {user.email}
                </h6>
              </Col>
              <Col sm="12" md="6" lg="12">
                <Form>
                  <Form.Group>
                    <label htmlFor="username">New Password</label>
                    <Form.Control
                      required
                      type="password"
                      style={{ borderRadius: ".375rem" }}
                      id="LastName"
                      value={password}
                      onChange={(res) => {
                        setPassword(res.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>

                <Form>
                  <Form.Group>
                    <label htmlFor="username">Retype New Password</label>
                    <Form.Control
                      required
                      type="password"
                      style={{ borderRadius: ".375rem" }}
                      id="LastName"
                      value={Conpassword}
                      onChange={(res) => {
                        setConPassword(res.target.value);
                      }}
                      invalid={password === Conpassword ? false : true}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Card.Body>

            <Card.Footer
              className="sideCard.Footer"
              style={{
                borderTop: "1px solid #ededed",
              }}
            >
              <Button
                size="md"
                squared="true"
                theme="success"
                className="deliveredButton"
                disabled={fpassword ? false : true}
                onClick={updatePassword}
              >
                SUBMIT
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Container>
      <Modals open={open} message={message} toggleModal={toggleModal} />
    </div>
  );
};

export default ResetPassword;
