import React, { useState, useEffect } from "react";

import { ThemeContext } from "../Hooks/context";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faShoppingBag,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { Redirect, useHistory, Switch } from "react-router-dom";
import {
  Col,
  Container,
  Button,
  Modal,
} from "react-bootstrap";
// routes config
// import routes from "../navigation/routes";

import logo from "../styles/images/logo.png";
import logoWhite from "../styles/images/logoWhite.png";
import boxloader from "../styles/images/boxload.gif";
import people from "../styles/images/people.svg";

export const Header = React.memo(() => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();

  const [cart, setCart] = useState(null);
  const [text, setText] = useState("");

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    if (!global.userInfo) {
      const value = localStorage.getItem("userInfo");
      value ? handleCart(JSON.parse(value)) : console.log();
    }
    if (global.userInfo) {
      handleCart(global.userInfo);
    }
  }, [global.userInfo]);

  const handleCart = (value) => {
    fetch("https://isheda-backend.onrender.com/cart/" + value.cartId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
        }
        if (response.ok) {
          const data = await response.json();
          setCart(data.cart);
          // global.setLoading(false);
        }
      })
      .catch((error) => {
        // global.setLoading(false);
        // error.message === "Network request failed"
        //   ? setErrorMsg("net")
        //   : setErrorMsg("wrong");
      });
  };

  const handleSearch = (res) => {
    global.setLoading(true);
    res.preventDefault();

    fetch("https://isheda-backend.onrender.com/products/search", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: text,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem("productlist", JSON.stringify(data.result));
          history.push({
            pathname: "/productlist",
            data: data.result,
            text: text,
          });
          setText("");
          global.setLoading(false);
        }
      })
      .catch((error) => {
        global.setError(true);
        global.setLoading(false);
        // setToast(true);
      });
  };
  return (
    <header
      className="appear-animation-visible header"
      style={{ fontFamily: "sofia" }}
    >
      <div className="appear-animation-visible header-middle sticky-header fix-top sticky-content" >
        <div className="appear-animation-visible container" style={{ padding: '0'  }}>
          <div className="appear-animation-visible header-left" style={{ padding: '0', margin:"0 0em 0 15px"  }} >
            <span
              className="appear-animation-visible mobile-menu-toggle"
              onClick={() => {
                global.setBugger(true);
              }}

            >
              <i className="appear-animation-visible d-icon-bars2" />
            </span>
          </div>
          <div className="appear-animation-visible header-center" style={{ padding: '0', margin:"0 0em 0 -3.5em" }}>
            <Link to="/">
              <span className="appear-animation-visible logo">
                <img src={logo} alt="logo" style={{
                  width:'150px',
                  margin: '0 auto',
                  textAlign:'right'
                }} />
              </span>
            </Link>

            {/* End of Logo */}
            <nav className="appear-animation-visible main-nav">
              <ul className="appear-animation-visible menu">
                <li className="appear-animation-visible active">
                  {location.pathname === "/" ? null : (
                    <Link to="/">
                      <span> </span>
                    </Link>
                  )}
                </li>

                <li className="appear-animation-visible ">
                  {location.pathname === "/aboutus" ? null : (
                    <Link to="/aboutus">
                      <span>ABOUT US</span>
                    </Link>
                  )}
                </li>

                <li className="appear-animation-visible ">
                  {location.pathname === "/contactus" ? null : (
                    <Link to="/contactus">
                      <span>Contact Us</span>
                    </Link>
                  )}
                </li>

                {/* <li>
                  <Link to="/productlist">
                    <span>Categories</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/productlist">
                    <span>Products</span>
                  </Link>
                </li> */}
              </ul>
            </nav>
            <span className="appear-animation-visible divider" />
            {/* End of Divider */}
            <div className="appear-animation-visible header-search hs-toggle">
              <span className="appear-animation-visible search-toggle" >
                <i className="appear-animation-visible d-icon-search" />
              </span>
              <form action="#" className=" input-wrapper">
                <input
                  type="text"
                  className=" form-control"
                  placeholder="Search your keyword..."
                  required
                  value={text}
                  onChange={(res) => {
                    setText(res.target.value);
                  }}
                />
                <button
                  className="btn btn-search"
                  // type="submit"
                  onClick={handleSearch}
                >
                  <i className="d-icon-search" />
                </button>
              </form>
            </div>
            {/* End of Header Search */}
          </div>
          <div className="appear-animation-visible header-right">
            <span className="appear-animation-visible login">
              <i className="appear-animation-visible d-icon-user" />

              {global.userInfo === null ? (
                <span
                  onClick={() => {
                    global.setLogin(true);
                  }}
                >
                  Login
                </span>
              ) : (
                <div className="dropdown cart-dropdown">
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      marginLeft: "13px",
                    }}
                    className="cart-toggle"
                  >
                    <span style={{ color: "#6e6e6e" }}>Hello,</span>
                    <span>{global.userInfo.firstname}</span>
                  </span>
                  <div
                    className="dropdown-box"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "40%",
                      borderRadius: "20px",
                    }}
                  >
                    {location.pathname === "/account" ? (
                      <Link to="/account">
                        <h6 className="loginOpts">
                          <FontAwesomeIcon
                            icon={faUser}
                            className="loginIcon"
                          />{" "}
                          Account
                        </h6>
                      </Link>
                    ) : (
                      <Link
                        to="/account"
                        onClick={() => {
                          global.setLoading(true);
                        }}
                      >
                        <h6 className="loginOpts">
                          <FontAwesomeIcon
                            icon={faUser}
                            className="loginIcon"
                          />{" "}
                          Account
                        </h6>
                      </Link>
                    )}

                    {location.pathname === "/account" ? (
                      <Link
                        to={{
                          pathname: "/account",
                          data: "orders",
                        }}
                      >
                        <h6 className="loginOpts">
                          <FontAwesomeIcon
                            icon={faShoppingBag}
                            className="loginIcon"
                          />
                          Orders
                        </h6>
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: "/account",
                          data: "orders",
                        }}
                        onClick={() => {
                          global.setLoading(true);
                        }}
                      >
                        <h6 className="loginOpts">
                          <FontAwesomeIcon
                            icon={faShoppingBag}
                            className="loginIcon"
                          />
                          Orders
                        </h6>
                      </Link>
                    )}

                    {location.pathname === "/account" ? (
                      <Link
                        to={{
                          pathname: "/account",
                          data: "wish",
                        }}
                      >
                        <h6 className="loginOpts">
                          <FontAwesomeIcon
                            icon={faHeart}
                            className="loginIcon"
                          />
                          Wish
                        </h6>
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: "/account",
                          data: "wish",
                        }}
                        onClick={() => {
                          global.setLoading(true);
                        }}
                      >
                        <h6 className="loginOpts">
                          <FontAwesomeIcon
                            icon={faHeart}
                            className="loginIcon"
                          />
                          Wish
                        </h6>
                      </Link>
                    )}

                    <div>
                      <button
                        className="btn btn-primary btn-md ml-2"
                        style={{ margin: "0px" }}
                        onClick={global.Logout}
                      >
                        LOGOUT
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </span>
            {/* End of Login */}
            <span className="appear-animation-visible divider" />

            {!cart ? null : (
              <div className="appear-animation-visible dropdown cart-dropdown">
                <a className="appear-animation-visible cart-toggle">
                  <span className="appear-animation-visible cart-label">
                    <span className="appear-animation-visible cart-name">
                      My Cart
                    </span>
                    <span className="appear-animation-visible cart-price">
                      ₦{numberWithCommas(cart.totalprice)}
                    </span>
                  </span>
                  <i className="appear-animation-visible minicart-icon">
                    <span className="appear-animation-visible cart-count">
                      {cart.items.length}
                    </span>
                  </i>
                </a>
                {/* End of Cart Toggle */}
                <div className="appear-animation-visible dropdown-box">
                  <div className="appear-animation-visible product product-cart-header">
                    <span className="appear-animation-visible product-cart-counts">
                      {cart.items.length} items
                    </span>
                    <span>
                      <span>View cart</span>
                    </span>
                  </div>
                  <div className="appear-animation-visible products scrollable">
                    {/* End of Cart Product */}

                    {cart.items.map((res) => {
                      let val = res.product.price;
                      let dis = res.product.discount
                        ? res.product.discount / 100
                        : 0 / 100;
                      let discountPrice = val - val * dis;
                      return (
                        <div className="appear-animation-visible product product-cart">
                          <div className="appear-animation-visible product-detail">
                            <span className="appear-animation-visible product-name">
                              {res.product.name}
                            </span>
                            <div className="appear-animation-visible price-box">
                              <span className="appear-animation-visible product-quantity">
                                {res.quantity}
                              </span>
                              <span className="appear-animation-visible product-price">
                                ₦ {numberWithCommas(discountPrice)}
                              </span>
                            </div>
                          </div>
                          <figure className="appear-animation-visible product-media">
                            <span>
                              <img
                                src={res.product.productImage[0]}
                                alt="product"
                                width={90}
                                height={90}
                              />
                            </span>
                            {/* <button className="appear-animation-visible btn btn-link btn-close">
                              <i className="appear-animation-visible fas fa-times" />
                            </button> */}
                          </figure>
                        </div>
                      );
                    })}

                    {/* End of Cart Product */}
                  </div>
                  {/* End of Products  */}
                  <div className="appear-animation-visible cart-total">
                    <label>Subtotal:</label>
                    <span className="appear-animation-visible price">
                      ₦{numberWithCommas(cart.totalprice)}
                    </span>
                  </div>
                  {/* End of Cart Total */}
                  <div className="appear-animation-visible cart-action">
                    <span className="appear-animation-visible btn btn">
                      <Link
                        to="/cart"
                        onClick={() => {
                          global.setLoading(true);
                          setTimeout(() => {
                            window.location.reload();
                          }, 600);
                        }}
                      >
                        <span>View All</span>
                      </Link>
                    </span>
                  </div>
                  {/* End of Cart Action */}
                </div>
                {/* End of Dropdown Box */}
              </div>
            )}
            <div className="appear-animation-visible header-search hs-toggle mobile-search" style={{ padding: '0', margin:"0 15px 0 0"  }}>
              <span className="appear-animation-visible search-toggle">
                <i className="appear-animation-visible d-icon-search" />
              </span>
              <form action="#" className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search your keyword..."
                  required
                  value={text}
                  onChange={(res) => {
                    setText(res.target.value);
                  }}
                />
                <button className="btn btn-search" onClick={handleSearch}>
                  <i className="d-icon-search" />
                </button>
              </form>
            </div>
            {/* End of Header Search */}
          </div>
        </div>
      </div>
    </header>
  );
});

export const Content = () => {
  return (
    <Switch>
      {/* {routes.map((route, idx) => {
        return (
          route.component && (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={(props) => <route.component {...props} />}
            />
          )
        );
      })} */}
      <Redirect to="/home" />
    </Switch>
  );
};

export const Mobilenav = () => {
  const global = React.useContext(ThemeContext);
  const location = useLocation();

  const [cart, setCart] = useState(null);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    if (!global.userInfo) {
      const value = localStorage.getItem("userInfo");
      value ? handleCart(JSON.parse(value)) : console.log();
    }
    if (global.userInfo) {
      handleCart(global.userInfo);
    }
  }, []);

  const handleCart = (value) => {
    fetch("https://isheda-backend.onrender.com/cart/" + value.cartId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          // setErrorMsg("res");
          // setError(true);
          // global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          setCart(data.cart);
          // global.setLoading(false);
        }
      })
      .catch((error) => {
        // global.setLoading(false);
        // error.message === "Network request failed"
        //   ? setErrorMsg("net")
        //   : setErrorMsg("wrong");
      });
  };
  return (
    <div
      style={{ position: "sticky", backgroundColor: "white", zIndex: 1000000 }}
      className="appear-animation-visible sticky-footer sticky-content fix-bottom"
    >
      {location.pathname === "/" ? (
        <span className="appear-animation-visible sticky-link active">
          <i className="appear-animation-visible d-icon-home" />
          <span>Home</span>
        </span>
      ) : (
        <Link
          to="/"
          onClick={() => {
            global.setLoading(true);
          }}
        >
          <span className="appear-animation-visible sticky-link">
            <i className="appear-animation-visible d-icon-home" />
            <span>Home</span>
          </span>
        </Link>
      )}

      {/* <Link
        to={{
          pathname: "/productlist",
        }}
        onClick={() => {
          global.setLoading(true);
        }}
      >
        <span className="appear-animation-visible sticky-link">
          <i className="appear-animation-visible d-icon-volume" />
          <span>Categories</span>
        </span>
      </Link> */}

      {location.pathname === "/account" ? (
        <Link
          to={{
            pathname: "/account",
            data: "wish",
          }}
        >
          <span className="appear-animation-visible sticky-link">
            <i className="appear-animation-visible d-icon-heart" />
            <span>Wishlist</span>
          </span>
        </Link>
      ) : (
        <Link
          to={{
            pathname: "/account",
            data: "wish",
          }}
          onClick={() => {
            global.setLoading(true);
          }}
        >
          <span className="appear-animation-visible sticky-link">
            <i className="appear-animation-visible d-icon-heart" />
            <span>Wishlist</span>
          </span>
        </Link>
      )}

      {location.pathname === "/account" ? (
        <Link to="/account">
          <span className="appear-animation-visible sticky-link">
            <i className="appear-animation-visible d-icon-user" />
            <span>Account</span>
          </span>
        </Link>
      ) : (
        <Link
          to="/account"
          onClick={() => {
            global.setLoading(true);
          }}
        >
          <span className="appear-animation-visible sticky-link">
            <i className="appear-animation-visible d-icon-user" />
            <span>Account</span>
          </span>
        </Link>
      )}
      <div className="appear-animation-visible dropdown cart-dropdown dir-up">
        <span className="appear-animation-visible sticky-link cart-toggle">
          <i className="d-icon-bag" />
          <span>Cart</span>
        </span>
        {/* End of Cart Toggle */}
        {!cart ? null : (
          <>
            <div className="appear-animation-visible dropdown-box">
              <div className="appear-animation-visible product product-cart-header">
                <span className="appear-animation-visible product-cart-counts">
                  {cart.items.length} items
                </span>
                <span>
                  <span>View cart</span>
                </span>
              </div>

              <div className="appear-animation-visible products scrollable">
                {cart.items.map((res) => {
                  let val = res.product.price;
                  let dis = res.product.discount
                    ? res.product.discount / 100
                    : 0 / 100;
                  let discountPrice = val - val * dis;
                  return (
                    <div className="appear-animation-visible product product-cart">
                      <div className="appear-animation-visible product-detail">
                        <span className="appear-animation-visible product-name">
                          {res.product.name}
                        </span>
                        <div className="appear-animation-visible price-box">
                          <span className="appear-animation-visible product-quantity">
                            {res.quantity}
                          </span>
                          <span className="appear-animation-visible product-price">
                            ₦ {numberWithCommas(discountPrice)}
                          </span>
                        </div>
                      </div>
                      <figure className="appear-animation-visible product-media">
                        <span>
                          <img
                            src={res.product.productImage[0]}
                            alt="product"
                            width={90}
                            height={90}
                          />
                        </span>
                        <button className="appear-animation-visible btn btn-link btn-close">
                          <i className="appear-animation-visible fas fa-times" />
                        </button>
                      </figure>
                    </div>
                  );
                })}
              </div>

              <div className="appear-animation-visible cart-total">
                <label>Subtotal:</label>
                <span className="appear-animation-visible price">
                  ₦ {numberWithCommas(cart.totalprice)}
                </span>
              </div>

              {/* End of Products  */}

              {/* End of Cart Total */}
              <div className="appear-animation-visible cart-action">
                <span className="appear-animation-visible btn btn">
                  <Link
                    to="/cart"
                    onClick={() => {
                      global.setLoading(true);
                      setTimeout(() => {
                        window.location.reload();
                      }, 600);
                    }}
                  >
                    <span>View All</span>
                  </Link>
                </span>
              </div>
              {/* End of Cart Action */}
            </div>
          </>
        )}
        {/* End of Dropdown Box */}
      </div>
    </div>
  );
};

export const MobileSidebar = () => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();

  const [text, setText] = useState("");

  const handleSearch = (res) => {
    global.setLoading(true);
    global.setBugger(false);
    res.preventDefault();

    fetch(process.env.REACT_APP_API_URL + "/products/search", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: text,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem("productlist", JSON.stringify(data.result));
          history.push({
            pathname: "/productlist",
            data: data.result,
            text: text,
          });
          setText("");
          global.setLoading(false);
        }
      })
      .catch((error) => {
        global.setError(true);
        global.setLoading(false);
      });
  };
  return (
    <div
      className={
        global.bugger
          ? "mobile-menu-wrapper mmenu-active"
          : "mobile-menu-wrapper"
      }
      style={{ visibility: global.bugger ? "visible" : "hidden" }}
    >
      <div
        className="mobile-menu-overlay"
        onClick={() => {
          global.setBugger(false);
        }}
      />
      {/* End of Overlay */}
      <span
        className=" mobile-menu-close"
        onClick={() => {
          global.setBugger(false);
        }}
      >
        <i className="d-icon-times" />
      </span>
      {/* End of CloseButton */}
      <div
        className={
          global.bugger
            ? "mobile-menu-container scrollable bugger"
            : "mobile-menu-container scrollable"
        }
      >
        <form action="#" className="input-wrapper">
          <input
            type="text"
            className=" form-control"
            autoComplete="off"
            placeholder="Search your keyword..."
            required
            value={text}
            onChange={(res) => {
              setText(res.target.value);
            }}
          />
          <button
            className="appear-animation-visible btn btn-search"
            onClick={handleSearch}
          >
            <i className="appear-animation-visible d-icon-search" />
          </button>
        </form>
        {/* End of Search Form */}
        <ul className="appear-animation-visible mobile-menu mmenu-anim">
          <li className={location.pathname === "/" ? "active" : ""}>
            <Link
              to="/"
              onClick={() => {
                global.setBugger(false);
              }}
            >
              <span>Home</span>
            </Link>
          </li>
          <li className={location.pathname === "/aboutus" ? "active" : ""}>
            <Link
              to="/aboutus"
              onClick={() => {
                global.setBugger(false);
              }}
            >
              <span>About Us</span>
            </Link>
          </li>

          <li className={location.pathname === "/contactus" ? "active" : ""}>
            <Link
              to="/contactus"
              onClick={() => {
                global.setBugger(false);
              }}
            >
              <span>Contact Us</span>
            </Link>
          </li>
        </ul>
        {/* End of MobileMenu */}
        <ul className="appear-animation-visible mobile-menu mmenu-anim">
          {global.userInfo ? null : (
            <li
              onClick={() => {
                global.setLogin(true);
                global.setBugger(false);
              }}
            >
              <a>Login</a>
            </li>
          )}

          <li className={location.pathname === "/cart" ? "active" : ""}>
            <Link
              to="/cart"
              onClick={() => {
                global.setLoading(true);
                global.setBugger(false);
              }}
            >
              <span>MY Cart</span>
            </Link>
          </li>

          <li className={location.pathname === "/account" ? "active" : ""}>
            <Link
              to={{
                pathname: "/account",
                data: "wish",
              }}
              onClick={() => {
                global.setLoading(true);
                global.setBugger(false);
              }}
            >
              <span>Wish List</span>
            </Link>
          </li>

          {!global.userInfo ? null : (
            <li
              className="active"
              style={{ cursor: "pointer" }}
              onClick={() => {
                global.Logout();
              }}
            >
              <a>
                <span>Log Out</span>
              </a>
            </li>
          )}
        </ul>
        {/* End of MobileMenu */}
      </div>
    </div>
  );
};

export const Loader = () => {
  return (
    <div className="loader-case">
      <div className="loader-size">
        <img src={boxloader} className="loader" />
      </div>
    </div>
  );
};

export const Modals = ({ open, message, toggleModal }) => {
  return (
    <Modal size="sm" open={open} toggle={toggleModal}>
      <Modal.Header>
        {message === "success"
          ? "SUCCESS"
          : message === "ErrMsg"
            ? "ERROR!"
            : message === "editSuccess"
              ? "SUCCESS"
              : message === "editError"
                ? "ERROR 🚷"
                : "Message"}
      </Modal.Header>
      <Modal.Body>
        <h6>
          {message === "success"
            ? "Your password has been Updated 👍"
            : message === "wishSuccess"
              ? "Your wish list has been Updated 👍"
              : message === "wishError"
                ? "something Went Wrong, we could not update your wish list 😅"
                : message === "ErrMsg"
                  ? "something Went Wrong, Your password will remain UNCHANGED 💯"
                  : message === "editSuccess"
                    ? "Your profile has been Updated 👍"
                    : message === "editError"
                      ? "sorry, something went wrong.Your profile will remain unchanged 💯"
                      : message}
        </h6>
      </Modal.Body>
    </Modal>
  );
};

export const Footer = () => {
  const global = React.useContext(ThemeContext);
  const [mail, setMail] = useState("");

  const handleSub = () => {
    global.setLoading(true);
    fetch(process.env.REACT_APP_API_URL + "/general/subscribers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: mail,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          global.setOpen(true);
          global.setMessage(
            "you have already subscribed to our newsletter 💯"
          );
          global.setLoading(false);
        }
        if (response.ok) {
          global.setOpen(true);
          global.setMessage("your subscription was successful 👍");
          global.setLoading(false);
        }
        global.setLoading(false);
      })
      .catch((error) => {
        global.setLoading(false);
      });
  };
  return (
    <footer className="appear-animation-visible footer appear-animate" style={{ zIndex: '999' }}>
      <div className="appear-animation-visible container">
        <div className="appear-animation-visible footer-top">
          <div className="appear-animation-visible row">
            <div className="appear-animation-visible col-lg-3">
              <span className="appear-animation-visible logo-footer">
                <Link
                  to="/"
                  onClick={() => {
                    global.setLoading(true);
                  }}
                >
                  <img
                    src={logoWhite}
                    alt="logo-footer"
                    width={163}
                    height={39}
                  />
                </Link>
              </span>
              {/* End of FooterLogo */}
            </div>
            <div className="appear-animation-visible col-lg-9">
              <div className="appear-animation-visible widget widget-newsletter form-wrapper form-wrapper-inline">
                <div className="appear-animation-visible newsletter-info mx-auto mr-lg-2 ml-lg-4">
                  <h4 className="appear-animation-visible widget-title">
                    Subscribe to our Newsletter
                  </h4>
                  <p>
                    Get all the latest information on Events, Sales and Offers.
                  </p>
                </div>
                <div action="#" className="input-wrapper input-wrapper-inline">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email address here..."
                    value={mail}
                    onChange={(res) => {
                      setMail(res.target.value);
                    }}
                  />
                  <button
                    className="btn btn-primary btn-md ml-2"
                    style={{ borderRadius: "20px" }}
                    onClick={() => {
                      mail ? handleSub() : console.log();
                    }}
                  >
                    subscribe
                    <i className="appear-animation-visible d-icon-arrow-right" />
                  </button>
                </div>
              </div>
              {/* End of Newsletter */}
            </div>
          </div>
        </div>
        {/* End of FooterTop */}
        <div className="appear-animation-visible footer-middle">
          <div className="appear-animation-visible row">
            <div className="appear-animation-visible col-lg-3 col-md-6">
              <div className="appear-animation-visible widget">
                <h4 className="appear-animation-visible widget-title">
                  Contact Info
                </h4>
                <ul className="appear-animation-visible widget-body">
                  <li>
                    <label>Phone:</label>
                    <span> +2348092205588</span>
                  </li>
                  <li>
                    <label>Email:</label>
                    <span> Support@isheda.com</span>
                  </li>
                  <li>
                    <label>Address:</label>
                    <span>
                      {" "}
                      Green Garage, House 5, Stateline Road, FUTA South Gate,
                      Akure.
                    </span>
                  </li>
                </ul>
              </div>
              {/* End of Widget */}
            </div>
            <div className="appear-animation-visible col-lg-3 col-md-6">
              <div className="appear-animation-visible widget ml-lg-4">
                <h4 className="appear-animation-visible widget-title">
                  My Account
                </h4>
                <ul className="appear-animation-visible widget-body">
                  <li>
                    <Link
                      to="/account"
                      onClick={() => {
                        global.setLoading(true);
                      }}
                    >
                      <span>Dashboard</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{
                        pathname: "/account",
                        data: "orders",
                      }}
                      onClick={() => {
                        global.setLoading(true);
                      }}
                    >
                      <span>Order</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{
                        pathname: "/account",
                        data: "wish",
                      }}
                      onClick={() => {
                        global.setLoading(true);
                      }}
                    >
                      <span>Wish</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{
                        pathname: "/account",
                        data: "edit",
                      }}
                      onClick={() => {
                        global.setLoading(true);
                      }}
                    >
                      <span>Update Profile</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* End of Widget */}
            </div>
          </div>
        </div>
        {/* End of FooterMiddle */}
        <div className="appear-animation-visible footer-bottom display-flex justify-content-center">
          <div className="appear-animation-visible footer-center">
            <p className="appear-animation-visible copyright">
              Isheda © 2021. All Rights Reserved
            </p>
          </div>
        </div>
        {/* End of FooterBottom */}
      </div>
    </footer>
  );
};

export const Error = ({ setLogin }) => {
  const global = React.useContext(ThemeContext);
  return (
    <Container className="d-flex flex-column align-items-center pt-10 pb-5 mt-10">
      {/* <Col lg="10" className="d-flex justify-content-center pt-10 mb-7 mt-10">
        <img width="250" src={people} />
      </Col>

      <Col lg="10" className="d-flex flex-column align-items-center ">
        <h5>Something Went wrong 😅</h5>
        <span>- Try checking your Internet connection.</span>
        <span>- Try Refreshing this page</span>
      </Col>

      <Col lg="10" className="d-flex flex-column align-items-center mt-6 mb-6">
        <Link to="/">
          <Button
            size="lg"
            outline
            squared="true"
            theme="success"
            className="accontButton"
            onClick={() => global.setError(false)}
          >
            GO TO HOMEPAGE
          </Button>
        </Link>
      </Col> */}
    </Container>
  );
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
