import React, { useState, useEffect } from "react";
import { ThemeContext } from "../Hooks/context";
import { Link, useHistory } from "react-router-dom";

import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Header, Footer } from "../components/General";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUser,
  faClipboard,
  faHeart,
  faArrowAltCircleLeft,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const SideBar = ({
  current,
  handleOrders,
  handleDashboard,
  handleReview,
  handleLayby,
  handleWish,
  handleRecent,
  handleEdit,
  handlePassword,
}) => {
  const global = React.useContext(ThemeContext);

  useEffect(() => {
    global.setLoading(false);
  });

  return (
    <Card>
      <Card.Body style={{ padding: "0px" }}>
        <div>
          <h6
            className={
              current === "dashboard" ? "sideCont activeS" : "sideCont"
            }
            onClick={handleDashboard}
          >
            <FontAwesomeIcon icon={faUser} className="sideIcon" />
            MY ISHEDA ACCOUNT
          </h6>

          <h6
            className={current === "orders" ? "sideCont activeS" : "sideCont"}
            onClick={handleOrders}
          >
            {/* <FontAwesomeIcon icon={faUser} className="sideIcon" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-bag"
              viewBox="0 0 16 16"
              className="sideIcon"
            >
              <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
            </svg>
            ORDERS
          </h6>

          {/* <span
              className={current === "review" ? "sideCont activeS" : "sideCont"}
              onClick={handleReview}
            >
              <FontAwesomeIcon icon={faClipboard} className="sideIcon" />
              PENDING REVIEWS
            </span> */}

          <h6
            className={current === "wish" ? "sideCont activeS" : "sideCont"}
            onClick={handleWish}
          >
            <FontAwesomeIcon icon={faHeart} className="sideIcon" />
            SAVED ITEMS
          </h6>

          {/* <h6
            className="sideCont"
            className={current === 'recent' ? 'sideCont activeS' : 'sideCont'}
            onClick={handleRecent}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
              className="sideIcon"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
              RECENTLY VIEWED
            </h6> */}
          <h6
            className={current === "layby" ? "sideCont activeS " : "sideCont"}
            onClick={handleLayby}
          >
            {/* <FontAwesomeIcon icon={faUser} className="sideIcon" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-bag"
              viewBox="0 0 16 16"
              className="sideIcon"
            >
              <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
            </svg>
            LAY BUY
          </h6>
        </div>

        <Row
          style={{
            borderTop: "1px solid #ededed",
            borderBottom: "1px solid #ededed",
            fontSize: "83%",
            marginLeft: "5px",
            padding: "10px 10px 10px 22.3906px",
          }}
        >
          <span
            className="sideSub"
            onClick={handleEdit}
            style={{ cursor: "pointer", padding: "0", margin: "1em 0" }}
          >
            Edit Details
          </span>

          <span
            className="sideSub"
            onClick={handlePassword}
            style={{ cursor: "pointer", padding: "0", margin: "1em 0" }}
          >
            Change Password
          </span>
        </Row>
      </Card.Body>

      <Card.Footer className="sideCard.Footer">
        <Button
          style={{
            background: "transparent",
            color: "#00a567",
            padding: "1em 0 1em 22.3906px",
            fontWeight: "bold",
          }}
          className="accontButton"
          onClick={global.Logout}
        >
          LOGOUT
        </Button>
      </Card.Footer>
    </Card>
  );
};

export const Dashboard = ({ user, setCurrent }) => {
  const global = React.useContext(ThemeContext);
  const [showsmessage, setshowsmessage] = useState(false)
  const [showemessage, setshowemessage] = useState(false)
  const verifyHandler = (id) => {
    // global.setLoading(true);
    fetch(process.env.REACT_APP_API_URL + "/user/verifymail/" + user._id, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + user.token,
      },
      body: JSON.stringify({
        email: user.email,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            // const data = await response.json();
            // setRedirect(true);
            // global.Logout();
            global.setLogin(true);
            // global.setGenerror(
            //   "You have been logged out, please signin again"
            // );
            global.setLoading(false);
          } else {
            global.setOpen(true);
            setshowemessage(true)
            // global.setMessage(
            //   "we could not send you a mail, please try again."
            // );
            global.setLoading(false);
          }
        }
        if (response.ok) {
          global.setOpen(true);
          setshowsmessage(true)
          // global.setMessage(
          //   "We have sent you a mail, please check your inbox to proceed"
          // );
          global.setLoading(false);
          // global.setRefresh(true);
        }
      })
      .catch((error) => {
        global.setOpen(true);
        global.setMessage("something went wrong, please try again");
        global.setLoading(false);
      });
  };
  return (
    <Card style={{ width: "100%" }}>
      <Card.Header
        style={{
          backgroundColor: "transparent",
          borderBottom: "1px solid #ededed",
          color: "#495057",
          padding: "15px",
        }}
      >
        <h5 style={{ margin: "0px" }}>Account Overview</h5>
      </Card.Header>
      {
        showsmessage ?(
          <div
          style={{
            position: "fixed",
            background: "transparent",
            left:'40%',
            zIndex: "999999",
          }}
        >
          <div class="modal-dialog" style={{ zIndex: "9999999999999" }}>
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Hello 👋
                </h5>
              </div>
              <div class="modal-body">We have sent you a mail, please check your inbox to proceed</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => {
                    setshowsmessage(false)
                  }}
                >
                  Ok, thanks
                </button>
              </div>
            </div>
          </div>
        </div>
        ) :
      showemessage ? (
        <div
        style={{
          position: "fixed",
          background: "transparent",
          zIndex: "999999",
          left:'40%',
        }}
      >
        <div class="modal-dialog" style={{ zIndex: "9999999999999" }}>
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Hello 👋
              </h5>
            </div>
            <div class="modal-body">we could not send you a mail, please try again.</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  setshowemessage(false)

                }}
              >
                Ok, thanks
              </button>
            </div>
          </div>
        </div>
      </div>
      ) : (<></>)
      }
   
      {user ? (
        <Card.Body>
          <Row
            style={{
              justifyContent: "space-between",
              padding: "4%",
              paddingBottom: "0px",
            }}
          >
            <Card
              className="accountCard"
              style={{
                height: "fit-content",
              }}
            >
              <Card.Header
                className="accountCard.Header"
                style={{
                  backgroundColor: "transparent",
                  borderBottom: "1px solid #ededed",
                  color: "#495057",
                  padding: "15px",
                }}
              >
                Account Details
              </Card.Header>
              <Card.Body
                style={{
                  backgroundColor: "transparent",
                  color: "#495057",
                  padding: "15px",
                }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    marginBottom: "2px",
                    fontWeight: "bold",
                  }}
                >
                  {user ? user.firstname : ""} {user ? user.lastname : ""}
                </p>
                <span style={{ fontWeight: "400", fontSize: "15px" }}>
                  {user ? user.email : ""}
                </span>
              </Card.Body>
              <Card.Footer
                style={{
                  background: "white",
                  padding: " 10px 15px",
                }}
              >
                <Button
                  style={{
                    background: "white",
                    color: "#00a567",
                    padding: "0",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                  className="accontButton"
                  onClick={() => setCurrent("password")}
                >
                  CHANGE PASSWORD
                </Button>
              </Card.Footer>
            </Card>

            <Card
              className="accountCard"
              style={{
                height: "fit-content",
              }}
            >
              <Card.Header
                className="accountCard.Header"
                style={{
                  backgroundColor: "transparent",
                  borderBottom: "1px solid #ededed",
                  color: "#495057",
                  padding: "15px",
                }}
              >
                Address Book
              </Card.Header>
              <Card.Body
                className="accountCard.Body"
                style={{
                  backgroundColor: "transparent",
                  color: "#495057",
                  padding: "15px",
                }}
              >
                <h6
                  className="text-uppercase "
                  style={{
                    fontSize: "15px",
                    marginBottom: "2px",
                    fontWeight: "bold",
                  }}
                >
                  {user.address ? "Your default shipping address:" : ""}
                </h6>
                <span
                  style={{
                    fontFamily: "sofia",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                >
                  {user.address ? (
                    user.address
                  ) : (
                    <h6
                      style={{
                        color: "#00a567",
                        fontSize: "15px",
                        marginBottom: "2px",
                        fontWeight: "bold",
                      }}
                    >
                      You are Yet to supply Your delivery address, you will not
                      be able to place an order.
                    </h6>
                  )}
                </span>
              </Card.Body>

              <Card.Footer
                className="accountCard.Footer"
                style={{
                  background: "white",
                  padding: " 10px 15px",
                }}
              >
                <Button
                  style={{
                    background: "white",
                    color: "#00a567",
                    padding: "0",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                  className="accontButton"
                  onClick={() => setCurrent("edit")}
                >
                  EDIT PROFILE
                </Button>
              </Card.Footer>
            </Card>
          </Row>

          <Col lg="12" className="d-flex justify-content-center mb-3">
            <Card
              style={{
                border: "1px solid rgb(215, 214, 214)",
                boxShadow: "none",
                marginTop: "3%",
              }}
            >
              <Card.Body
                className="orderCard.Body"
                style={{
                  backgroundColor: "transparent",
                  color: "#495057",
                  padding: "15px",
                }}
              >
                {user.verified ? (
                  <span
                    className="text-center pt-5 pb-5 pl-8 pr-8"
                    style={{ color: "#00a567" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      fill="currentColor"
                      class="bi bi-check2-circle"
                      viewBox="0 0 16 16"
                      className="mr-2"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                    </svg>
                    your account has been verified
                  </span>
                ) : (
                  <span
                    className="text-center pt-5 pb-5"
                    style={{ color: "rgb(187, 145, 21)" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-info-circle"
                      viewBox="0 0 16 16"
                      className="mr-3"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    You are yet to verify your account, you will not be allowed
                    to buy a product
                  </span>
                )}
              </Card.Body>

              {user.verified ? null : (
                <Card.Footer
                  className="sideCard.Footer"
                  style={{
                    background: "transparent",
                    borderTop: "1px solid #ededed",
                  }}
                >
                  <Button
                    style={{
                      background: "transparent",
                      color: "#00a567",
                      padding: "0 0 10px 0",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    className="savedButton"
                    onClick={verifyHandler}
                  >
                    Verify Account
                  </Button>
                </Card.Footer>
              )}
            </Card>
          </Col>
        </Card.Body>
      ) : (
        <></>
      )}
    </Card>
  );
};

export const Orders = ({ user, setCurrent, setFullOrder, fetchUser }) => {
  const global = React.useContext(ThemeContext);

  const handleExt = (value) => {
    setCurrent("orderExt");
    setFullOrder(value);
  };

  // useEffect(() => {
  //   global.setLoading(true);
  //   // fetchUser(global.userInfo);
  // }, []);

  const OrderCard = ({ data, payment }) => {
    return (
      <Col>
        {data.product.map((res) => {
          return (
            <Card
              style={{
                border: "1px solid rgb(215, 214, 214)",
                marginBottom: "4%",
                boxShadow: "none",
              }}
            >
              <Card.Body className="orderCard.Body d-flex flex-row">
                <Col xs="6" lg="4" style={{ textAlign: "center" }}>
                  <Card.Img
                    // className="orderImage"
                    src={res.productId.productImage[0]}
                    style={{
                      width: "110px",
                      height: "110px",
                      margin: "auto",
                      display: "inline-block",
                    }}
                  />
                </Col>
                <Col
                  xs="6"
                  lg="8"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h6>{res.productId.name}</h6>
                  <span>
                    Ordered On:{" "}
                    {new Date(data.placedOn)
                      .toISOString()
                      .replace(/T.*/, "")
                      .split("-")
                      .reverse()
                      .join("-")}
                  </span>
                  <Button
                    size="md"
                    squared="true"
                    theme={
                      payment === "pending"
                        ? "info"
                        : res.status === "pending"
                        ? "light"
                        : res.status === "shipping"
                        ? "secondary"
                        : res.status === "shipped"
                        ? "warning"
                        : res.status === "delivered"
                        ? "success"
                        : res.status === "cancled"
                        ? "danger"
                        : "success"
                    }
                    className="statusButton"
                  >
                    {payment === "pending"
                      ? "Payment Pending"
                      : res.status === "pending"
                      ? "In Store"
                      : res.status === "shipping"
                      ? "Shipped"
                      : res.status === "shipped"
                      ? "Awaiting Delivery"
                      : res.status === "delivered"
                      ? "Delivered"
                      : res.status}
                  </Button>
                </Col>
              </Card.Body>
              <Card.Footer
                className="sideCard.Footer"
                style={{
                  borderTop: "1px solid #ededed",
                  backgroundColor: "transparent",
                }}
              >
                <Button
                  style={{
                    background: "transparent",
                    color: "#00a567",
                    padding: "0",
                    fontWeight: "bold",
                    margin: "15px 0",
                    border: "none",
                    fontSize: "16px",
                  }}
                  className="savedButton"
                  onClick={() => {
                    handleExt(data);
                  }}
                >
                  See Details
                </Button>
              </Card.Footer>
            </Card>
          );
        })}
      </Col>
    );
  };

  useEffect(() => {
    global.setLoading(false);
  });
  console.log(user);
  let sorted = user.orders.sort(function (a, b) {
    return new Date(b.orderId.placedOn) - new Date(a.orderId.placedOn);
  });
  return (
    <Col className="sideCol2">
      <Card className="shadow-none">
        <Card.Header
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #ededed",
            color: "#495057",
            padding: "15px",
          }}
        >
          <h5 style={{ margin: "0px" }}>Orders</h5>
        </Card.Header>
        <Card.Body>
          <Col style={{ justifyContent: "space-between", padding: "4%" }}>
            {/* <Row style={{ marginBottom: "4%" }}>
              <Button
                size="md"
                outline
                squared="true"
                theme="success"
                className="orderButton"
              >
                OPEN ORDERS
              </Button>

              <Button
                size="md"
                outline
                squared="true"
                theme="success"
                className="orderButton"
              >
                CLOSED ORDERS
              </Button>
            </Row> */}

            {sorted.map((data) => {
              return data.orderId.active ? (
                <OrderCard
                  data={data.orderId}
                  payment={data.orderId.payment.status}
                />
              ) : null;
            })}
          </Col>
        </Card.Body>
      </Card>
    </Col>
  );
};

export const OrderExt = ({ data, setCurrent }) => {
  const global = React.useContext(ThemeContext);
  const [deliver, setDeliver] = useState("pen");
  const handleFlutterPayment = useFlutterwave(data.config);

  const handleOrders = () => {
    setCurrent("orders");
  };

  const statusHandler = (productId, orderId) => {
    global.setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL +
        "/user/orderstatus/" +
        global.userInfo._id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productId: productId,
          orderId: orderId,
          status: "delivered",
        }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          global.setMessage(
            "We could not update your delivery confirmation, please try again 😅"
          );
          global.setOpen(true);
          global.setLoading(false);
        }
        if (response.ok) {
          global.setMessage(
            "Delivery confirmation successful, thanks for using Isheda 🥰"
          );
          global.setOpen(true);
          global.setLoading(false);
        }
      })
      .catch((error) => {
        global.setMessage("Something went wrong, please try again 😅");
        global.setOpen(true);
        global.setExit(true);
        global.setLoading(false);
      });
  };

  useEffect(() => {
    global.setLoading(false);
  });
  return (
    <Col className="sideCol2">
      <Card className="shadow-none">
        <Card.Header
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #ededed",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "#495057",
          }}
        >
          <Button
            style={{
              background: "transparent",
              color: "#00a567",
              padding: "15px",
              fontWeight: "bold",
              border: "none",
            }}
            onClick={handleOrders}
          >
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{ width: "15px", height: "15px", margin: "0 5px" }}
            />
            Go Back
          </Button>

          <div
            style={{ display: "flex", justifyContent: "center", width: "60%" }}
          >
            Order Details
          </div>
        </Card.Header>
        <Card.Body>
          <Col style={{ justifyContent: "space-between", padding: "4%" }}>
            <Col
              style={{
                marginBottom: "10%",
                borderBottom: "1px solid #ededed",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h6>Order nº {data.orderNumber}</h6>
              <span style={{ fontWeight: "400", margin: "10px 0" }}>
                {data.product.length} Items
              </span>
              <span style={{ fontWeight: "400", margin: "10px 0" }}>
                Placed on :{" "}
                {new Date(data.placedOn)
                  .toISOString()
                  .replace(/T.*/, "")
                  .split("-")
                  .reverse()
                  .join("-")}
              </span>
              <span style={{ fontWeight: "400", margin: "10px 0" }}>
                Total: ₦ {data.payment.itemsTotal}
              </span>
            </Col>

            {data.payment.status !== "pending" ? null : (
              <Col className="d-flex flex-column text-center justify-content-center align-items-center">
                <h5>Your Payment for this order has not been confirmed</h5>
                <Button
                  size="md"
                  outline
                  squared="true"
                  theme="success"
                  style={{
                    borderRadius: "5px",
                    marginBottom: "8%",
                    width: "",
                  }}
                  onClick={() => {
                    handleFlutterPayment({
                      callback: (response) => {
                        closePaymentModal(); // this will close the modal programmatically
                      },
                      onClose: () => {
                        global.setRefresh(true);
                      },
                    });
                  }}
                >
                  <h5 style={{ margin: "0px", color: "inherit" }}>
                    CONFIRM NOW
                  </h5>
                </Button>
              </Col>
            )}

            <Col>
              <div style={{ backgroundColor: "transparent" }}>
                <h6 style={{ margin: "10px 0" }}>ITEMS IN YOUR ORDER</h6>
              </div>

              {data.product.map((res) => {
                return (
                  <Card
                    style={{
                      border: "1px solid rgb(215, 214, 214)",
                      boxShadow: "none",
                      marginTop: "4%",
                    }}
                  >
                    <Card.Body className="orderCard.Body d-flex flex-row">
                      <Col xs="6" md="4" style={{ textAlign: "center" }}>
                        <Card.Img
                          // className="orderImage"
                          src={res.productId.productImage[0]}
                          style={{ width: "120px", height: "120px" }}
                        />
                      </Col>
                      <Col
                        xs="6"
                        md="8"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h6 style={{ margin: "5px 0" }}>
                          {res.productId.name}
                        </h6>
                        <h6 style={{ fontFamily: "sofia", margin: "5px 0" }}>
                          On :
                          {new Date(data.placedOn)
                            .toISOString()
                            .replace(/T.*/, "")
                            .split("-")
                            .reverse()
                            .join("-")}
                        </h6>

                        <h6 style={{ fontFamily: "sofia", margin: "5px 0" }}>
                          QTY: {res.quantity}
                        </h6>

                        <h6 style={{ fontFamily: "sofia", margin: "5px 0" }}>
                          <h6
                            style={{
                              color: "green",
                              textTransform: "uppercase",
                            }}
                          >
                            {data.payment.status === "pending"
                              ? "Payment Pending"
                              : res.status === "pending"
                              ? "In Store"
                              : res.status === "shipping"
                              ? "Shipped"
                              : res.status === "shipped"
                              ? "Awaiting Delivery"
                              : res.status === "delivered"
                              ? "Delivered"
                              : res.status}
                          </h6>
                        </h6>
                      </Col>
                    </Card.Body>

                    {res.status === "delivered" ||
                    res.status !== "shipped" ? null : (
                      <Card.Footer
                        className="sideCard.Footer "
                        style={{
                          borderTop: "1px solid #ededed",
                        }}
                      >
                        {deliver !== "pen" ? null : (
                          <Col className="align-items-center d-flex flex-column">
                            <span
                              className="text-center pt-5 pb-5"
                              style={{ color: "rgb(187, 145, 21)" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-info-circle"
                                viewBox="0 0 16 16"
                                className="mr-3"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                              </svg>
                              Do not confirm a delivery until it has been
                              delivered to you!
                            </span>
                            <Button
                              size="md"
                              outline
                              squared="true"
                              theme="success"
                              onClick={() => {
                                setDeliver("con");
                              }}
                              style={{ border: "0px", borderRadius: "5px" }}
                            >
                              <span
                                style={{
                                  fontSize: "120%",
                                  fontFamily: "proximaB",
                                }}
                              >
                                CONFIRM DELIVERY
                              </span>
                            </Button>
                          </Col>
                        )}

                        {deliver !== "con" ? null : (
                          <Col className="align-items-center d-flex flex-column">
                            <h6>
                              Are you sure you want to confirm this delivery ?
                            </h6>

                            <Col className="justify-content-center d-flex flex-row">
                              <Button
                                size="md"
                                squared="true"
                                theme="danger"
                                onClick={() => {
                                  setDeliver("pen");
                                }}
                                style={{ border: "0px", borderRadius: "5px" }}
                              >
                                <span
                                  style={{
                                    fontSize: "120%",
                                    fontFamily: "proximaB",
                                  }}
                                >
                                  NO
                                </span>
                              </Button>
                              <Button
                                size="md"
                                squared="true"
                                className="ml-5"
                                theme="success"
                                style={{ border: "0px", borderRadius: "5px" }}
                                onClick={() => {
                                  statusHandler(res.productId._id, data._id);
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "120%",
                                    fontFamily: "proximaB",
                                  }}
                                >
                                  YES
                                </span>
                              </Button>
                            </Col>
                          </Col>
                        )}
                      </Card.Footer>
                    )}
                  </Card>
                );
              })}

              <Row
                style={{
                  justifyContent: "space-between",
                  marginTop: "4%",
                }}
              >
                <Card className="orderDtlsCard">
                  <Card.Header
                    className="accountCard.Header"
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: "1px solid #ededed",
                      color: "#495057",
                      padding: "15px",
                    }}
                  >
                    <h5 style={{ margin: "0px" }}> Payment Information</h5>
                  </Card.Header>
                  <Card.Body className="accountCard.Body">
                    <div style={{ padding: "0 15px" }}>
                      <h5 style={{ marginBottom: "0px" }}>Payment Method</h5>
                      <span style={{ fontWeight: "400" }}>
                        {data.payment.paymentMethod}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "4%",
                        fontFamily: "sofia",
                        padding: "0 15px",
                      }}
                    >
                      <h5 style={{ marginBottom: "0px" }}>Payment Details</h5>
                      <span style={{ fontWeight: "400" }}>
                        Items total: ₦ {data.payment.itemsTotal}
                      </span>
                      <span style={{ fontWeight: "400" }}>
                        Shipping Fees: ₦ {data.payment.deliveryFee}
                      </span>

                      <h6 style={{ marginTop: "2%", marginBottom: "0px" }}>
                        Total: ₦ {data.payment.total}
                      </h6>
                    </div>
                  </Card.Body>
                </Card>

                <Card className="orderDtlsCard">
                  <Card.Header
                    className="accountCard.Header"
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: "1px solid #ededed",
                      color: "#495057",
                      padding: "15px",
                    }}
                  >
                    <h5 style={{ margin: "0px" }}>Delivery Information</h5>
                  </Card.Header>
                  <Card.Body className="accountCard.Body">
                    <div style={{ padding: "0 15px" }}>
                      <h5 style={{ marginBottom: "0px" }}>Delivery Method</h5>
                      <span style={{ fontWeight: "400" }}>
                        {data.delivery.method}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "4%",
                        fontFamily: "sofia",
                        padding: "0 15px",
                      }}
                    >
                      <h5 style={{ marginBottom: "0px" }}>Shipping Address</h5>
                      {/* <span>Ajetunmobi Abel Tayo</span> */}
                      <span style={{ fontWeight: "400" }}>
                        {data.delivery.address}
                      </span>
                      <span style={{ fontWeight: "400" }}>
                        Phone Number: {data.delivery.phoneNo}
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
          </Col>
        </Card.Body>

        <Card.Footer
          className="sideCard.Footer"
          style={{
            borderTop: "1px solid #ededed",
            textAlign: "center",
            padding: "15px 0",
          }}
        >
          <Button
            style={{
              background: "transparent",
              color: "#00a567",
              fontWeight: "bold",
            }}
            href="mailto: support@isheda.com"
          >
            <span
              style={{
                fontSize: "120%",
                fontFamily: "proximaB",
                color: "#17c671",
              }}
            >
              NEED HELP?
            </span>
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export const Review = () => {
  const global = React.useContext(ThemeContext);

  useEffect(() => {
    global.setLoading(false);
  });
  return (
    <Col className="sideCol2">
      <Card>
        <Card.Header
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #ededed",
            display: "flex",
            justifyContent: "center",
            color: "#495057",
          }}
        >
          Pending Reviews
        </Card.Header>
        <Card.Body>
          <Col>
            <Card
              style={{
                border: "1px solid rgb(215, 214, 214)",
                boxShadow: "none",
              }}
            >
              <Card.Body className="orderCard.Body">
                <Card.Img
                  className="orderImage"
                  src="https://green-market-58d06.appspot.com.storage.googleapis.com/7b3361f0-6ed0-11eb-9d79-cf8f8e36cee1_mac1.jpg"
                />
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <h6>
                    Apple MacBook Pro - 16" Touch Bar - Intel Core I9 - 16GB -
                    AMD Radeon Pro 5500M - 1TB SSD - Space Gray
                  </h6>
                  <span>Order nº: 317432537</span>
                  <span style={{ color: "#00a567" }}>
                    Delivered on 06-10-20
                  </span>
                </Col>
              </Card.Body>

              <Card.Footer
                className="sideCard.Footer"
                style={{
                  borderTop: "1px solid #ededed",
                }}
              >
                <Button
                  size="md"
                  outline
                  squared="true"
                  theme="success"
                  className="orderButton"
                >
                  RATE THIS PRODUCT
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Card.Body>
      </Card>
    </Col>
  );
};

export const Wish = ({ user }) => {
  const global = React.useContext(ThemeContext);

  useEffect(() => {
    global.setLoading(false);
  }, []);

  const cartHandler = (id) => {
    global.setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL +
        "/wish/removewish/" +
        global.userInfo.wishId._id,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            propName: "items",
            value: { product: id },
          },
        ]),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          // global.setLoading(false);
          global.setRefresh(true);
        }
      })
      .catch((error) => {
        global.setLoading(false);
        // error.message === "Network request failed"
        //   ? setErrorMsg("net")
        //   : setErrorMsg("wrong");
      });
  };

  function WishCard({ data }) {
    return (
      <Col>
        <Card
          style={{
            border: "1px solid rgb(215, 214, 214)",
            boxShadow: "none",
            margin: "20px",
          }}
        >
          <Card.Body
            className="d-flex flex-wrap align-items-center"
            // style={{ justifyContent: "flex-start" }}
          >
            <Col xs="6" lg="2">
              <Card.Img style={{ width: "100px" }} src={data.productImage[0]} />
            </Col>
            <Col
              xs="6"
              lg="8"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h6>{data.name}</h6>
              {/* <span>{data.price}</span> */}
              <span style={{ color: "#00a567" }}>
                ₦ {numberWithCommas(data.price)}
              </span>
            </Col>

            <Col
              xs="12"
              lg="2"
              className="d-flex flex-wrap justify-content-center"
            >
              <Link
                to={{
                  pathname: `/product/c920d${data._id}ui89`,
                  data: data,
                }}
                style={{ marginTop: "5%" }}
                onClick={() => {
                  global.setLoading(true);
                  localStorage.setItem("product", JSON.stringify(data));
                }}
              >
                <Button
                  size="md"
                  squared="true"
                  theme="success"
                  style={{ width: "100%", margin: "10px" }}
                  className="savedButton"
                >
                  BUY NOW
                </Button>
              </Link>

              <Button
                style={{
                  background: "transparent",
                  color: "#00a567",
                  padding: "0",
                  fontWeight: "bold",
                  margin: "10px",
                  border: "none",
                }}
                onClick={() => {
                  cartHandler(data._id);
                }}
              >
                REMOVE
              </Button>
            </Col>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  return (
    <Col className="sideCol2">
      <Card>
        <Card.Header
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #ededed",
            color: "#495057",
            padding: "15px",
          }}
        >
          <h5 style={{ margin: "0px" }}>Saved Items</h5>
        </Card.Header>
        <Card.Body>
          {user.wishId.items.length < 1 ? (
            <EmptyWish />
          ) : (
            user.wishId.items.map((res, idx) => {
              return <WishCard data={res.product} key={idx} />;
            })
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export const Recent = ({ user }) => {
  const global = React.useContext(ThemeContext);

  useEffect(() => {
    global.setLoading(false);
  }, []);

  const cartHandler = (id) => {
    global.setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL +
        "/wish/removewish/" +
        global.userInfo.wishId._id,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            propName: "items",
            value: { product: id },
          },
        ]),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          // global.setLoading(false);
          global.setRefresh(true);
        }
      })
      .catch((error) => {
        global.setLoading(false);
        // error.message === "Network request failed"
        //   ? setErrorMsg("net")
        //   : setErrorMsg("wrong");
      });
  };

  function RecentCard({ data }) {
    return (
      <Col lg="6">
        <Card
          style={{
            border: "1px solid rgb(215, 214, 214)",
            boxShadow: "none",
            margin: "15px 5px",
            minHeight: "150px",
            width: "100%",
          }}
        >
          <Card.Body className="d-flex flex-row flex-wrap">
            <Col xs="6" lg="3" style={{ width: "fit-content" }}>
              <Card.Img
                style={{ width: "70px", height: "70px" }}
                src={data.productImage[0]}
              />
            </Col>
            <Col
              xs="6"
              lg="9"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h6>{data.name}</h6>
              <span style={{ color: "#00a567" }}>
                ₦ {numberWithCommas(data.price)}
              </span>
            </Col>
          </Card.Body>
          <Card.Footer
            className="sideCard.Footer"
            style={{
              borderTop: "1px solid #ededed",
            }}
          >
            <Link
              to={{
                pathname: `/product/c920d${data._id}ui89`,
                data: data,
              }}
              onClick={() => {
                global.setLoading(true);
                localStorage.setItem("product", JSON.stringify(data));
              }}
            >
              <Button
                style={{
                  background: "transparent",
                  color: "#00a567",
                  padding: "0",
                  fontWeight: "bold",
                  margin: "15px 0",
                  border: "none",
                }}
                className="savedButton"
              >
                View Item
              </Button>
            </Link>
          </Card.Footer>
        </Card>
      </Col>
    );
  }

  return (
    <Col className="sideCol2">
      <Card>
        <Card.Header
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #ededed",
            color: "#495057",
            padding: "15px",
          }}
        >
          <h5 style={{ margin: "0" }}> Recently Viewed Items</h5>
        </Card.Header>
        <Card.Body>
          {user.recentId.items.length < 1 ? (
            <EmptyRecent />
          ) : (
            <div className="d-flex flex-row flex-wrap">
              {user.recentId.items.map((res, idx) => {
                if (res.product) {
                  if (res.product.deleted === false) {
                    return <RecentCard data={res.product} key={idx} />;
                  }
                }
              })}
            </div>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export const Edit = ({ user, cities, setMessage, setOpen, setExit }) => {
  const global = React.useContext(ThemeContext);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState();

  const [success, setSuccess] = useState(false);

  const ffirstname = user.firstname !== firstname ? firstname : null;
  const flastname = user.lastname !== lastname ? lastname : null;
  const fphoneNo = user.phoneNo !== phoneNo ? phoneNo : null;
  const faddress = user.address !== address ? address : null;
  const fcity = user.city !== city ? city : null;

  const HandleUpload = (res) => {
    global.setLoading(true);
    res.preventDefault();

    fetch(process.env.REACT_APP_API_URL + "/user/" + user._id, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + global.userInfo.token,
      },
      body: JSON.stringify([
        ffirstname
          ? {
              propName: "firstname",
              value: ffirstname,
            }
          : {},
        flastname
          ? {
              propName: "lastname",
              value: flastname,
            }
          : {},
        fphoneNo
          ? {
              propName: "phoneNo",
              value: fphoneNo,
            }
          : {},
        faddress
          ? {
              propName: "address",
              value: faddress,
            }
          : {},
        fcity
          ? {
              propName: "city",
              value: fcity,
            }
          : {},
      ]),
    })
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            global.Logout();
            global.setLogin(true);
            global.setLoading(false);
          }
          // setError(true);
          // global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          setSuccess(true);
          setMessage("editSuccess");
          setOpen(true);
          setExit(true);
          // setRefresh(true);
          window.location.reload();
          // global.setLoading(false);
        }
      })
      .catch((error) => {
        setMessage("editError");
        setOpen(true);
        // setError(true);
        global.setLoading(false);
        // setToast(true);
      });
  };

  useEffect(() => {
    global.setLoading(false);
    setFirstname(user.firstname);
    setLastName(user.lastname);
    setPhoneNo(user.phoneNo);
    setAddress(user.address);
    setCity(user.city);
  }, []);
  return (
    <Col className="sideCol2">
      <Card style={{ minHeight: "100%" }}>
        <Card.Header
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #ededed",
            color: "#495057",
            padding: "15px",
          }}
        >
          <h5 style={{ margin: "0" }}>Details</h5>
        </Card.Header>
        <Card.Body>
          <Col>
            <Row>
              <Col sm="12" lg="5" style={{ margin: "20px 10px 20px 15px" }}>
                <Form>
                  <Form.Group>
                    <label htmlFor="username" style={{ fontSize: "14px" }}>
                      First Name
                    </label>
                    <Form.Control
                      style={{
                        borderRadius: ".375rem",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        padding: "10px 5px",
                      }}
                      id="LastName"
                      value={firstname}
                      onChange={(res) => {
                        setFirstname(res.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>

              <Col sm="12" lg="5" style={{ margin: "20px 10px 20px 15px" }}>
                <Form>
                  <Form.Group>
                    <label htmlFor="username" style={{ fontSize: "14px" }}>
                      Last Name
                    </label>
                    <Form.Control
                      style={{
                        borderRadius: ".375rem",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        padding: "10px 5px",
                      }}
                      id="LastName"
                      value={lastname}
                      onChange={(res) => {
                        setLastName(res.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col sm="12" lg="5" style={{ margin: "20px 10px 20px 15px" }}>
                <Form>
                  <Form.Group>
                    <label htmlFor="username" style={{ fontSize: "14px" }}>
                      Phone Number
                    </label>
                    <Form.Control
                      style={{
                        borderRadius: ".375rem",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        padding: "10px 5px",
                      }}
                      id="LastName"
                      value={phoneNo}
                      onChange={(res) => {
                        setPhoneNo(res.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>

              <Col sm="12" lg="5" style={{ margin: "20px 10px 20px 15px" }}>
                <Form>
                  <Form.Group>
                    <label htmlFor="username" style={{ fontSize: "14px" }}>
                      Address
                    </label>
                    <Form.Control
                      style={{
                        borderRadius: ".375rem",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        padding: "10px 5px",
                      }}
                      required
                      value={address}
                      onChange={(res) => {
                        setAddress(res.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col
                sm="12"
                md="4"
                lg="6"
                style={{ margin: "20px 10px 20px 15px" }}
              >
                <label htmlFor="username" style={{ fontSize: "14px" }}>
                  City (required)
                </label>
                <Form.Select
                  style={{
                    borderRadius: ".375rem",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    padding: "10px 5px",
                  }}
                  onChange={(event) => {
                    function checkName(city) {
                      return city.city === event.target.value;
                    }
                    let cityInfo = cities.find(checkName);
                    setCity(cityInfo.code);
                    // setBankName(cityInfo.city);
                  }}
                >
                  <option disabled defaultChecked={city ? false : true}>
                    Select a city
                  </option>
                  {cities?.map((cityLis, idx) => {
                    return (
                      <option
                        key={idx}
                        selected={cityLis.code === city ? true : false}
                      >
                        {cityLis.city}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Card.Body>

        <Card.Footer
          className="sideCard.Footer"
          style={{
            borderTop: "1px solid #ededed",
            background: "transparent",
            textAlign: "center",
          }}
        >
          <Button
            style={{
              background: "transparent",
              color: "#00a567",
              padding: "0",
              fontWeight: "bold",
              margin: "15px auto",
              border: "none",
              textAlign: "center",
              fontSize: "15px",
            }}
            className="deliveredButton"
            onClick={HandleUpload}
          >
            SAVE
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export const ChangePassword = ({
  user,
  setLogin,
  setRefresh,
  setMessage,
  setOpen,
  setExit,
}) => {
  const global = React.useContext(ThemeContext);
  //-- included by perfection --//
  const [oldPassword, setOldPassword] = useState("");
  //-- included by perfection end --//
  const [password, setPassword] = useState("");
  const [Conpassword, setConPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const oPassword = oldPassword.length > 0 ? oldPassword : null;
  const fpassword = password === Conpassword ? Conpassword : null;
  const updatePassword = (res) => {
    res.preventDefault();
    if (oPassword === null || fpassword === null) {
      return;
    }
    global.setLoading(true);
    fetch(process.env.REACT_APP_API_URL + "/user/updatepass/" + user._id, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + global.userInfo.token,
      },
      body: JSON.stringify(
        fpassword
          ? {
              //-- included by perfection end --//
              oldPassword: oldPassword,
              //-- included by perfection end --//
              newPassword: fpassword,
            }
          : {}
      ),
    })
      .then(async (response) => {
        if (!response.ok) {
          //-- included by perfection end --//
          if (response.status === 500) {
            const data = await response.json();
            setMessage(data.message);
            setOpen(true);
            global.setLoading(false);
            //-- included by perfection end --//
          } else if (response.status === 401) {
            // const data = await response.json();

            global.setLogin(true);

            global.setLoading(false);
          }
          // setError(true);
          // global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          setMessage("success");
          setExit(true);
          setOpen(true);
          setSuccess(true);
          global.setLoading(false);
        }
      })
      .catch((error) => {
        setMessage("ErrPass");
        setOpen(true);
        global.setLoading(false);
      });
  };

  useEffect(() => {
    global.setLoading(false);
  }, []);
  return (
    <Col className="sideCol2">
      <Card style={{ minHeight: "100%" }}>
        <Card.Header
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #ededed",
            color: "#495057",
            padding: "15px",
          }}
        >
          <h5 style={{ margin: "0" }}>Change Password</h5>
        </Card.Header>
        <Card.Body>
          <Col sm="12" lg="12">
            {/* //-- included by perfection --// */}
            <Form style={{ margin: "10px 20px" }}>
              <Form.Group>
                <label
                  htmlFor="username"
                  style={{
                    fontSize: "14px",
                    margin: "10px 0",
                    display: "block",
                  }}
                >
                  Old Password
                </label>
                <Form.Control
                  required
                  type="password"
                  style={{ borderRadius: ".375rem" }}
                  id="LastName"
                  value={oldPassword}
                  onChange={(res) => {
                    setOldPassword(res.target.value);
                  }}
                />
              </Form.Group>
            </Form>
            {/* //-- included by perfection end --// */}
            <Form style={{ margin: "10px 20px" }}>
              <Form.Group>
                <label
                  htmlFor="username"
                  style={{
                    fontSize: "14px",
                    margin: "10px 0",
                    display: "block",
                  }}
                >
                  New Password
                </label>
                <Form.Control
                  required
                  type="password"
                  style={{ borderRadius: ".375rem" }}
                  id="LastName"
                  value={password}
                  onChange={(res) => {
                    setPassword(res.target.value);
                  }}
                />
              </Form.Group>
            </Form>
            <Form style={{ margin: "10px 20px" }}>
              <Form.Group>
                <label
                  htmlFor="username"
                  style={{
                    fontSize: "14px",
                    margin: "10px 0",
                    display: "block",
                  }}
                >
                  Retype New Password
                </label>
                <Form.Control
                  required
                  type="password"
                  style={{ borderRadius: ".375rem" }}
                  id="LastName"
                  value={Conpassword}
                  onChange={(res) => {
                    setConPassword(res.target.value);
                  }}
                  invalid={password === Conpassword ? false : true}
                />
              </Form.Group>
            </Form>
          </Col>
        </Card.Body>

        <Card.Footer
          className="sideCard.Footer"
          style={{
            borderTop: "1px solid #ededed",
            textAlign: "center",
          }}
        >
          <Button
            style={{
              background: "transparent",
              color: "#00a567",
              padding: "0",
              fontWeight: "bold",
              margin: "15px auto",
              border: "none",
              textAlign: "center",
            }}
            className="deliveredButton"
            onClick={updatePassword}
          >
            SUBMIT
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
};
export const LayBy = ({ user }) => {
  const global = React.useContext(ThemeContext);
  const [data, setdata] = useState();
  useEffect(() => {
    let mounted = true;
    layByHandler().then((response) => {
      if (mounted) {
        let sorted = response.laidBy.sort(function (a, b) {
          return new Date(b.addedOn) - new Date(a.addedOn);
        });
        setdata(sorted);
        console.log(sorted);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const layByHandler = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/laidby", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    return response.json();
  };

  return (
    <Col className="sideCol2">
      <Card>
        <Card.Header
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #ededed",
            color: "#495057",
            padding: "15px",
          }}
        >
          <h5 style={{ margin: "0" }}> Lay Buy Items</h5>
        </Card.Header>
        <Card.Body>
          {data ? (
            <>
              {data.length < 1 ? (
                <EmptyLayby />
              ) : (
                <div className="d-flex flex-row flex-wrap">
                  {data.map((res, idx) => {
                    return <LayByCard data={res} key={idx} />;
                  })}
                </div>
              )}
            </>
          ) : (
            <EmptyLayby />
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};
export const LayByCard = ({ data }) => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();
  const [laidby, setlaidby] = useState();
  const [orderDetails, setorderDetails] = useState(null);

  let handleFlutterPayment = useFlutterwave(
    orderDetails ? orderDetails.config : {}
  );

  useEffect(() => {
    let mounted = true;
    layByProduct().then((res) => {
      if (mounted) {
        setlaidby(res);
        console.log(res);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);
  const layByProduct = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/laidby/${data._id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${global.userInfo.token}`,
        },
      }
    );
    return response.json();
  };
  if (orderDetails !== null) {
    setTimeout(() => {
      pay();
    }, 900);
  }
  const layByOrder = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/laidby/order`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${global.userInfo.token}`,
        },
        body: JSON.stringify({
          laidById: laidby.laidBy._id,
        }),
      }
    );
    return response.json();
  };
  const pay = () => {
    handleFlutterPayment({
      callback: (response) => {
        closePaymentModal(); // this will close the modal programmatically
      },
      onClose: () => {
        // global.setRedirect(true);
        // global.setRefresh(true);
        history.push({
          pathname: "/account",
          data: "layby",
          text: "You can now view this product in lay buy",
        });
        window.location.reload();
      },
    });
  };
  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    var dateFormat = result.toLocaleDateString().split("/");
    return `${dateFormat[1]}/${dateFormat[0]}/${dateFormat[2]}`;
    // return result.toLocaleDateString()
  };
  if (laidby) {
    return (
      <Col lg="12">
        <Card
          style={{
            border: "1px solid rgb(215, 214, 214)",
            boxShadow: "none",
            margin: "20px",
            position: "relative",
          }}
        >
          <Card.Body
            className="d-flex flex-wrap align-items-center layby"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              width: "100%",
              height: "fit-content",
            }}
          >
            <Col lg="4">
              <Card.Img
                style={{
                  width: "200px",
                  height: "200px",
                  paddingRight: "10px",
                }}
                src={laidby.laidBy.product.productId.productImage[0]}
              />
            </Col>
            <Col lg="7" style={{ display: "flex", flexDirection: "column" }}>
              <h6>{laidby.laidBy.product.productId.name}</h6>
              <Row>
                <Col lg="6">
                  <p style={{}}>Quantity : {laidby.laidBy.product.quantity}</p>
                </Col>
                <Col lg="6">
                  <p>
                    {laidby.laidBy.isComplete
                      ? "payment completed"
                      : "incomplete payment"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <p style={{}}>
                    Added On : {addDays(new Date(laidby.laidBy.addedOn), 0)}
                  </p>
                </Col>
                <Col lg="6">
                  <p>
                    Deadline : {addDays(new Date(laidby.laidBy.addedOn), 90)}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <p style={{ color: "#00a567" }}>
                    Total Amount Paid : ₦{" "}
                    {numberWithCommas(
                      +laidby.laidBy.totalprice - +laidby.laidBy.paymentLeft
                    )}
                  </p>
                </Col>
                <Col lg="6">
                  <p style={{ color: "#00a567" }}>
                    Amount Left : ₦{" "}
                    {numberWithCommas(+laidby.laidBy.paymentLeft)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Button
                    style={{
                      background: "transparent",
                      color: "#00a567",
                      padding: "0",
                      fontWeight: "bold",
                      fontSize: "14px",
                      margin: "10px 0",
                      border: "none",
                      textAlign: "left",
                    }}
                    onClick={() => {
                      history.push(`/lay-buy/${laidby.laidBy._id}`);
                    }}
                  >
                    Pay Installment
                  </Button>
                </Col>
                <Col lg="6">
                  <Button
                    style={{
                      background: "transparent",
                      color: "#00a567",
                      padding: "0",
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px 0",
                      border: "none",
                      textAlign: "left",
                    }}
                    onClick={() => {
                      if (+laidby.laidBy.paymentLeft > 0) {
                        alert(
                          "Hello 🖐,\n \n hope you are having a pleasant day. \n All installment must be paid first to checkout. \n \n Thank you!!!"
                        );
                      } else {
                        layByOrder().then((res) => {
                          console.log(res);
                          setorderDetails(res);
                        });
                      }
                    }}
                  >
                    Checkout (delivery fee)
                  </Button>
                </Col>
              </Row>
            </Col>

            {/* <Col
            xs="12"
            lg="0"
            className="d-flex flex-wrap justify-content-center"
          >
            <Link
              to={{
                pathname: `/product/c920d${data._id}ui89`,
                // data: data,
              }}
              style={{ marginTop: '5%' }}
              onClick={() => {
                global.setLoading(true);
                localStorage.setItem('product', JSON.stringify(data));
              }}
            >
              <Button
                size="md"
                squared="true"
                theme="success"
                style={{ width: '100%', margin: '10px',display:'none' }}
                className="savedButton"
              >
                BUY NOW
            </Button>
            </Link>

            <Button
              style={{
                background: "transparent",
                color: "#00a567",
                padding: '0',
                fontWeight: 'bold',
                margin: "10px",
                border: 'none',
                display:'none'
              }}
              onClick={() => {
                // cartHandler(data._id);
              }}
            >
              REMOVE
          </Button>
          </Col> */}
          </Card.Body>
        </Card>
      </Col>
    );
  } else {
    return <h3>Loading Lay Buy Products....</h3>;
  }
};
export const EmptyUser = ({ setLogin }) => {
  const global = React.useContext(ThemeContext);
  return (
    <div className="container mt-12 pt-6 mb-4">
      <div
        className="row gutter-lg"
        style={{ marginTop: "5%", marginBottom: "5%" }}
      >
        <span
          style={{
            justifyContent: "center",
            fontSize: "100%",
            textTransform: "uppercase",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textTransform: "uppercase",
          }}
        >
          <img
            src="https://www.jumia.com.ng/assets_he/images/people.ec5b815c.svg"
            alt="cart icon"
            width={150}
            height={150}
          />
          <h5
            className="text-center"
            style={{ marginTop: "8%", color: "#666" }}
          >
            You need to be logged in to View this Page
          </h5>

          <div className="cart-action" style={{ marginTop: "5%" }}>
            <span
              className="btn btn-primary btn-md ml-2 pt-2 pb-2 "
              onClick={() => {
                global.setLogin(true);
              }}
            >
              <span>Login</span>
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};

export const EmptyOrder = () => {
  return (
    <Col className="sideCol2">
      <div className="container mt-12 pt-6 mb-4">
        <div
          className="row gutter-lg"
          style={{ marginTop: "5%", marginBottom: "5%" }}
        >
          <span
            style={{
              justifyContent: "center",
              fontSize: "100%",
              textTransform: "uppercase",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textTransform: "uppercase",
            }}
          >
            <img
              src="https://www.jumia.com.ng/assets_he/images/people.ec5b815c.svg"
              alt="order icon"
              width={150}
              height={150}
            />
            <span
              style={{ marginTop: "20px", fontSize: "150%", color: "#666" }}
            >
              You are yet to place an order
            </span>

            <div className="cart-action" style={{ marginTop: "5%" }}>
              <span className="btn btn-primary btn-md ml-2">
                <Link to="/">
                  <span>Start shopping</span>
                </Link>
              </span>
            </div>
          </span>
        </div>
      </div>
    </Col>
  );
};

export const EmptyWish = () => {
  return (
    <Col className="sideCol2">
      <div className="container mt-12 pt-6 mb-4">
        <div
          className="row gutter-lg"
          style={{ marginTop: "5%", marginBottom: "5%" }}
        >
          <span
            style={{
              justifyContent: "center",
              fontSize: "100%",
              textTransform: "uppercase",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textTransform: "uppercase",
            }}
          >
            <img
              src="https://www.jumia.com.ng/assets_he/images/people.ec5b815c.svg"
              alt="order icon"
              width={150}
              height={150}
            />
            <span
              style={{ marginTop: "20px", fontSize: "150%", color: "#666" }}
            >
              You are yet to save a product
            </span>

            <div className="cart-action" style={{ marginTop: "5%" }}>
              <span className="btn btn-primary btn-md ml-2">
                <Link to="/">
                  <span>Start shopping</span>
                </Link>
              </span>
            </div>
          </span>
        </div>
      </div>
    </Col>
  );
};

export const EmptyRecent = () => {
  return (
    <Col className="sideCol2">
      <div className="container mt-12 pt-6 mb-4">
        <div
          className="row gutter-lg"
          style={{ marginTop: "5%", marginBottom: "5%" }}
        >
          <span
            style={{
              justifyContent: "center",
              fontSize: "100%",
              textTransform: "uppercase",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textTransform: "uppercase",
            }}
          >
            <img
              src="https://www.jumia.com.ng/assets_he/images/people.ec5b815c.svg"
              alt="order icon"
              width={150}
              height={150}
            />
            <span
              style={{ marginTop: "20px", fontSize: "150%", color: "#666" }}
            >
              New Here?, try to view a product
            </span>

            <div className="cart-action" style={{ marginTop: "5%" }}>
              <span className="btn btn-primary btn-md ml-2">
                <Link to="/">
                  <span>Start shopping</span>
                </Link>
              </span>
            </div>
          </span>
        </div>
      </div>
    </Col>
  );
};

export const EmptyLayby = () => {
  return (
    <Col className="sideCol2">
      <div className="container mt-12 pt-6 mb-4">
        <div
          className="row gutter-lg"
          style={{ marginTop: "5%", marginBottom: "5%" }}
        >
          <span
            style={{
              justifyContent: "center",
              fontSize: "100%",
              textTransform: "uppercase",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textTransform: "uppercase",
            }}
          >
            <img
              src="https://www.jumia.com.ng/assets_he/images/people.ec5b815c.svg"
              alt="order icon"
              width={150}
              height={150}
            />
            <span
              style={{ marginTop: "20px", fontSize: "150%", color: "#666" }}
            >
              No product on Lay Buy
            </span>

            <div className="cart-action" style={{ marginTop: "5%" }}>
              <span className="btn btn-primary btn-md ml-2">
                <Link to="/">
                  <span>Start shopping</span>
                </Link>
              </span>
            </div>
          </span>
        </div>
      </div>
    </Col>
  );
};
