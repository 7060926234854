import React, { useState, useEffect } from 'react';
import { ThemeContext } from '../Hooks/context';
import { useMediaQuery } from 'react-responsive';
import {
  Category_1,
  Category_2,
  Major_1,
  // Sponsors,
  HomeSlide,
  HomeFeatures,
  MainCategories,
  ProductSlider,
  FeaturedCat,
  MainNav,
  Sponsors,
} from '../components/homeComp';
import { Link, useHistory } from 'react-router-dom';

import 'pure-react-carousel/dist/react-carousel.es.css';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  // CardImg,
  Button,
  // CardFooter,
} from 'react-bootstrap';
import { Modals } from '../components/General';

import '../styles/css/demo1.min.css';
import '../styles/css/style.min.css';
import '../styles/vendor/owl-carousel/owl.carousel.min.css';
import '../styles/vendor/magnific-popup/magnific-popup.min.css';
import '../styles/vendor/animate/animate.min.css';
import '../styles/vendor/fontawesome-free/css/all.min.css';
import '../styles/images/icons/favicon.png';

import diy from '../styles/images/diy.png';
import arduino from '../styles/images/arduino.png';
import tinker from '../styles/images/tinker.png';
import home from '../styles/images/home.png';
import art from '../styles/images/art.png';
import clothes from '../styles/images/clothes.png';

import { get_request } from '../services/makeRequest';

const featCat = [
  {
    name: 'Arduino',
    img: 'https://ng.jumia.is/unsafe/fit-in/300x300/filters:fill(white)/product/63/246492/1.jpg?8728',
  },
  {
    name: 'Mobile Phones',
    img: 'https://ng.jumia.is/cms/0-7-top-categories/white-bg/phones.jpg',
  },
  {
    name: 'Acessories',
    img: 'https://ng.jumia.is/cms/0-7-top-categories/white-bg/accessories.jpg',
  },
  {
    name: 'Electronics',
    img: 'https://ng.jumia.is/cms/0-7-top-categories/white-bg/electronics.jpg',
  },
  {
    name: 'Printers',
    img: 'https://ng.jumia.is/cms/0-7-top-categories/white-bg/printers.jpg',
  },
  {
    name: 'Laptop',
    img: 'https://ng.jumia.is/cms/0-7-top-categories/white-bg/laptops.jpg',
  },
  {
    name: 'Fashion',
    img: 'https://ng.jumia.is/cms/0-7-top-categories/white-bg/fashion.jpg',
  },
  {
    name: 'Games',
    img: 'https://ng.jumia.is/cms/0-7-top-categories/white-bg/playstation-5.jpg',
  },
  {
    name: 'Generators',
    img: 'https://ng.jumia.is/cms/0-7-top-categories/white-bg/generators.jpg',
  },
  {
    name: 'Entertainment',
    img: 'https://ng.jumia.is/cms/0-1-weekly-cps/2021/w11/collections/home-entertainment_web.jpg',
  },
  {
    name: 'Store House',
    img: 'https://ng.jumia.is/cms/0-1-weekly-cps/2021/w11/collections/store-house_web.jpg',
  },
  {
    name: 'Servers',
    img: 'https://ng.jumia.is/cms/0-categories-tng/computing/L1/computers/servers-d.jpg',
  },
];

const Home = () => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();

  // STATES
  const [products, setProducts] = useState([]);
  const [recent, setRecent] = useState();
  const [home, setHome] = useState();
  const [catImg, setCatImg] = useState([]);

  React.useEffect(() => {
    GetHome();
    let mounted = true;
    getCategories().then((res) => {
      if (mounted) {
        setCatImg(res.categories);
      }
    });
    return () => {
      mounted = false;
    };
  }, [global.userInfo]);

  console.log(catImg);

  const getCategories = async () => {
    const request = await fetch(
      process.env.REACT_APP_API_URL + '/general/categories',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return request.json();
  };
  const GetHome = async () => {
    global.setLoading(true);
    try {
      const url = '/general/home';

      const response = await get_request(url);

      if (!response) {
        global.setError(true);
        global.setLoading(false);
      }

      if (response) {
        const data = response.data;
        setHome(data.home);

        const url = '/products';

        const prod_response = await get_request(url);

        if (!prod_response) {
          global.setError(true);
          global.setLoading(false);
        }

        if (prod_response) {
          const data = prod_response.data;
          setProducts(data.product);

          if (global.userInfo) {
            const url = '/recent/' + global.userInfo.recentId._id;

            const recent_response = await get_request(url);

            if (!recent_response) {
              global.setLoading(false);
            }

            if (recent_response) {
              const data = recent_response.data;
              data.recent.items.reverse();
              setRecent(data.recent.items);
              global.setLoading(false);
            }
          } else {
            global.setLoading(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
      global.setError(true);
      global.setLoading(false);
    }
  };

  if (global.loading || global.error) {
    return null;
  }
  console.log(products)
  return (
    <>
      <div className="mt-10">
        <div className="appear-animation-visible page-wrapper ">
          <h1 className="appear-animation-visible d-none">isheda</h1>
          {/* End of Header */}
          <main
            className="appear-animation-visible main"
            style={{ backgroundColor: '#f6f7f9' }}
          >
            <div className="appear-animation-visible page-content">
              {/* home Slide */}
              {home && home.slide && <HomeSlide data={home.slide} />}
              <HomeFeatures />
              <MainCategories data={catImg} />
              <ProductSlider data={products} title="Recommended for you" />
              {/* {!recent || recent.length < 1 ? null : (
                <ProductSlider data={recent} title="Recently viewed" />
              )} */}
              {/* pair element's Layout */}

              {/* //-- included by perfection --// */}
              {/* {home && home.pair[0] && <Major_1 data={home.pair[0]} />} */}
              {/* //-- included by perfection end --// */}

              {/* unlock this when the pictures are ready */}
              {/* <FeaturedCat title="FEATURED CATEGORIES" data={featCat} /> */}
              <ProductSlider data={products} title="Best selling" />

              {/* //-- included by perfection --// */}
              {/* <MainNav /> */}
              {/* //-- included by perfection end --// */}
              {/* <Client data={sponsors} /> */}
              <section>
                {home && home.pair[1] && <Major_1 data={home.pair[1]} />}
              </section>
            </div>
          </main>
        </div>

        <span
          id="scroll-top"
          title="Top"
          role="button"
          className="appear-animation-visible scroll-top"
        >
          <i className="appear-animation-visible fas fa-chevron-up" />
        </span>
      </div>
    </>
  );
};

export default Home;
