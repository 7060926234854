import React, { useState, useEffect } from "react";
import { ThemeContext } from "../Hooks/context";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tooltip,
} from "react-bootstrap";
import { Header, Footer } from "../components/General";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SellerProfile = (prop) => {
  const global = React.useContext(ThemeContext);
  const [seller, setSeller] = useState();
  const [tip, setTip] = useState();

  React.useEffect(() => {
    if (prop.location.data) {
      setSeller(prop.location.data);
    }
    if (!prop.location.data) {
      const value = localStorage.getItem("seller");
      if (value) {
        setSeller(JSON.parse(value));
      } else {
        global.setRedirect(true);
        // setEmpty(true);
      }
    }
    global.setLoading(false);
  }, [prop.location.data]);

  function checkSales(res) {
    return res.retrieved;
  }

  function RecentCard({ data }) {
    return (
      <Col xs="6" lg="6">
        <Card
          style={{
            border: "1px solid rgb(215, 214, 214)",
            boxShadow: "none",
            marginTop: "3%",
          }}
        >
          <Card.Body
            className="orderCard.Body"
            style={{ justifyContent: "flex-start" }}
          >
            <Col sm="12" md="3" lg="4">
              <Card.Img style={{ height: "150px" }} src={data.productImage[0]} />
            </Col>
            <Col
              sm="12"
              md="4"
              lg="8"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h6>{data.name}</h6>

              {/* <span>{data.price}</span> */}
              <span style={{ color: "#00a567" }}></span>
            </Col>
          </Card.Body>
          <Card.Footer
            className="sideCard.Footer"
            style={{
              borderTop: "1px solid #ededed",
            }}
          >
            <Link
              to={{
                pathname: "/product",
                data: data,
              }}
              onClick={() => {
                global.setLoading(true);
                localStorage.setItem("product", JSON.stringify(data));
              }}
            >
              <Button
                size="md"
                squared="true"
                theme="success"
                style={{ width: "100%" }}
                className="savedButton"
              >
                View Item
              </Button>
            </Link>
          </Card.Footer>
        </Card>
      </Col>
    );
  }

  return !seller || global.loading ? null : (
    <div>
      <Col className="sideCol2">
        <Card
          style={{ marginTop: "10%", marginBottom: "5%", boxShadow: "none" }}
        >
          <Card style={{ boxShadow: "none" }}>
            <Card.Header className="d-flex flex-row justify-content-center accountCard.Header">
              <h4 style={{ margin: "0px" }}>{seller.displayName}</h4>
            </Card.Header>

            <Card.Body>
              <Row style={{ justifyContent: "center" }}>
                <Card
                  style={{
                    border: "1px solid rgb(215, 214, 214)",
                    boxShadow: "none",
                    width: "90%",
                  }}
                >
                  <Card.Body style={{ padding: "4%" }}>
                    <Col lg="12" className="d-flex flex-column pt-5 pb-5">
                      <h6 className="mb-0">
                        Address:
                        <span
                          style={{
                            fontFamily: "sanfrancisco",
                            marginLeft: "1%",
                          }}
                        >
                          {seller.business.address}
                        </span>
                      </h6>
                      <h6>
                        Contact Number:
                        <span
                          style={{
                            fontFamily: "sanfrancisco",
                            marginLeft: "1%",
                          }}
                        >
                          {seller.phoneNo}
                        </span>
                      </h6>

                      <h5>Performance</h5>

                      <div className="d-flex flex-row ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#00a567"
                          class="bi bi-star"
                          viewBox="0 0 16 16"
                          className="mr-2"
                        >
                          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                        </svg>
                        <h6 class="mb-0">Order Fulfillment Rate: Excellent</h6>
                      </div>

                      <div className="d-flex flex-row ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#00a567"
                          class="bi bi-star"
                          viewBox="0 0 16 16"
                          className="mr-2"
                        >
                          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                        </svg>
                        <h6 class="mb-0">Quality Score: Excellent</h6>
                      </div>
                    </Col>
                  </Card.Body>
                  <Card.Footer style={{ backgroundColor: "white", justifyContent:"center", display:"flex" }}>
                    <div
                      className=" mr-3"
                      id="shear"
                      style={{ cursor: "pointer"}}
                      onClick={(res) => {
                        navigator.clipboard.writeText(
                          "https://www.isheda.com/sellerprofile/" + seller._id
                        );
                        setTip(true);
                      }}
                    >
                      <h4 className="btn-product btn-wishlist pr-4 pl-4">
                        <i class="fas fa-share-alt mr-3"></i>
                        share
                      </h4>
                      <Tooltip
                        open={tip}
                        target="#shear"
                        toggle={() => {
                          setTip(tip ? !tip : null);
                        }}
                      >
                        <h6 className="mb-0">Link Copied ✌️</h6>
                      </Tooltip>
                    </div>
                  </Card.Footer>
                </Card>
              </Row>
            </Card.Body>
          </Card>

          <Card.Body>
            <div>
              <Card style={{ boxShadow: "none" }}>
                <Card.Header
                  className="d-flex justify-content-center"
                  style={{ backgroundColor: "white" }}
                >
                  All Products
                </Card.Header>
                <Card.Body className="d-flex flex-row flex-wrap ">
                  {seller.products.map((res, idx) => {
                    return <RecentCard data={res.productId} key={idx} />;
                  })}
                </Card.Body>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default SellerProfile;
