import React, { useEffect, useState } from "react";
import {
  Header,
  Footer,
  Mobilenav,
  MobileSidebar,
} from "../components/General";
import Login from "./login";
import {
  TheCart,
  TheCheckout,
  Emptycart,
  EmptyUser,
  LaidByPayment,
} from "../components/cartComp";
import { ThemeContext } from "../Hooks/context";
import { get_request } from "../services/makeRequest";

const Cart = () => {
  const [login, setLogin] = useState(false);
  const [cart, setCart] = useState();
  const [showSum, setShowSum] = useState(false);
  const [laybyPurchase, setlaybyPurchase] = useState()
  const [layBy, setlayBy] = useState()
  const [layByDetails, setlayByDetails] = useState({})
  const [empty, setEmpty] = useState(false);
  const global = React.useContext(ThemeContext);

  // paystack config file
  const [order, setOrder] = useState();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    // global.setLoading(true);
    if (!global.userInfo) {
      const value = localStorage.getItem("userInfo");
      value && JSON.parse(value).length > 1
        ? handleCart(JSON.parse(value))
        : emptyLogin();
    }
    if (global.userInfo) {
      handleCart(global.userInfo);
    }
  }, [global.userInfo]);

  const emptyLogin = () => {
    setEmpty(true);
    global.setLoading(false);
  };

  const handleCart = async (value) => {
    global.setLoading(true);
    try {
      const url = "/cart/" + value.cartId;

      const response = await get_request(url);

      if (!response) {
        global.setLoading(false);
      }

      if (response) {
        const data = response.data;
        setCart(data.cart);
        setEmpty(false);

        global.setLoading(false);
      }
    } catch (err) {
      global.setError(true);
      global.setLoading(false);
    }
  };

  return global.loading || !cart || global.error ? null : (
    <>
      <div className="page-wrapper">
        {/* End Header */}
        <main className="main cart pt-10 pb-10">
          <div
            className="page-content pt-10 pb-10"
            // style={{ backgroundColor: "#f5f5f5" }}
            className="mainMargin"
          >
            {empty || cart.items.length < 1 ? (
              <Emptycart />
            ) : !global.userInfo ? (
              <EmptyUser />
            ) : (
              <>
                {!showSum && !layBy ? (
                  <TheCart
                    cart={cart}
                    order={order}
                    setOrder={setOrder}
                    setShowSum={setShowSum}
                    setlaybyPurchase={setlaybyPurchase}
                    // layBuyPayment = {layBuyPayment}
                    setlayByDetails={setlayByDetails}
                    layByDetails={layByDetails}
                  />
                ) : null}
                {showSum && !layBy ? 
                <TheCheckout 
                cart={cart} 
                order={order} 
                laybyPurchase={laybyPurchase} 
                setlayBy={setlayBy} 
                layByDetails={layByDetails}/> 
                : null}
                {layBy ? <LaidByPayment layByDetails={layByDetails} order={order} /> : null}

              </>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default Cart;



