import React, { useState } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { ThemeContext } from "../Hooks/context";
import { useMediaQuery } from "react-responsive";
import { Row, Col, Card, Button, Form, Modal } from "react-bootstrap";
// import { PaystackConsumer } from "react-paystack";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const opts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const Cartproduct = ({
  data,
  setShowSum,
  setOrder,
  setlaybyPurchase,
  setlayByDetails,
  layByDetails,
}) => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 479px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  //do not remove this
  const opts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const incCartHandler = (id, value) => {
    global.setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL +
        "/cart/updatecart/" +
        global.userInfo.global,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            propName: "product",
            value: id,
          },
          {
            propName: "quantity",
            value: value,
          },
        ]),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          // global.setLoading(false);
          global.setRefresh(true);
        }
      })
      .catch((error) => {
        global.setLoading(false);
        // error.message === "Network request failed"
        //   ? setErrorMsg("net")
        //   : setErrorMsg("wrong");
      });
  };

  const removeCartHandler = (id) => {
    global.setLoading(true);
    console.log(global.userInfo);

    fetch(
      process.env.REACT_APP_API_URL +
        "/cart/removecart/" +
        global.userInfo.cartId,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            propName: "items",
            value: {
              product: id,
            },
          },
        ]),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          global.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          // global.setLoading(false);
          global.setRefresh(true);
        }
      })
      .catch((error) => {
        global.setLoading(false);
        // error.message === "Network request failed"
        //   ? setErrorMsg("net")
        //   : setErrorMsg("wrong");
      });
  };
  const handleAddress = () => {
    history.push({
      pathname: "/account",
      data: "edit",
      text: "You need to supply an address and select a city before creating an order ✌️",
    });
    global.setLoading(true);
  };
  const handleOrder = async () => {
    global.setLoading(true);
    const request = await fetch(process.env.REACT_APP_API_URL + "/orders", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${global.userInfo.token}`,
      },
      body: JSON.stringify({
        userId: global.userInfo._id,
      }),
    });
    return request.json();
  };
  let val = data.product.price;
  let dis = data.product.discount ? data.product.discount / 100 : 0 / 100;
  let discountPrice = val - val * dis;

  return (
    <Card
      style={{
        border: "1px solid rgb(215, 214, 214)",
        boxShadow: "none",
        marginBottom: "3%",
        marginTop: "3%",
      }}
    >
      <Card.Body
        style={{
          justifyContent: "flex-row",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Col xs="6" lg="2">
          <Card.Img
            style={{ with: "100%" }}
            src={data.product.productImage[0]}
          />
        </Col>

        <Col
          xs="6"
          lg="5"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h6 className="mb-0">{data.product.name}</h6>
        </Col>

        <Col
          xs="4"
          lg="1"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px",
          }}
        >
          {isMobile || isTablet ? <h6 className="mt-5">QTY</h6> : null}
          <span style={{ color: "#00a567", marginLeft: "1px solid #eee" }}>
            <select
              style={{ cursor: "pointer" }}
              onChange={(event) => {
                incCartHandler(data.product._id, event.target.value);
              }}
              className="pr-4"
            >
              {opts.map((res, idx) => {
                return (
                  <option
                    key={idx}
                    selected={data.quantity === res ? true : false}
                    style={{ cursor: "pointer" }}
                  >
                    {res}
                  </option>
                );
              })}
            </select>
          </span>
        </Col>

        <Col
          xs="4"
          lg="2"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "1px solid #eee",
          }}
        >
          {isMobile || isTablet ? (
            <h6 className="mt-5 mb-1">Unit price</h6>
          ) : null}
          <span>₦ {numberWithCommas(discountPrice)}</span>
          {data.product.discount && data.product.discount > 0 ? (
            <span style={{ textDecoration: "line-through", color: "#00a567" }}>
              ₦ {numberWithCommas(data.product.price)}
            </span>
          ) : null}
        </Col>

        <Col
          xs="4"
          lg="2"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "1px solid #eee",
          }}
        >
          {isMobile || isTablet ? (
            <h6 className="mt-5 mb-1">Sub-Total</h6>
          ) : null}
          <span style={{ color: "#00a567" }}>
            ₦ {numberWithCommas(data.sumprice)}
          </span>
        </Col>

        {/* <Row sm="12" md="4" lg="4">
          <Button
            size="md"
            squared="true"
            theme="success"
            style={{ width: "100%" }}
            className="savedButton"
          >
            BUY NOW
          </Button>

          <Button
            size="md"
            outline
            squared="true"
            theme="danger"
            style={{
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              border: "none",
              height: "5%",
              marginTop: "5%",
            }}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{
                width: "18%",
                height: "20%",
                margin: "0px",
              }}
            />
            REMOVE
          </Button>
        </Row> */}
      </Card.Body>
      <Card.Footer
        className="sideCard.Footer"
        style={{
          // borderBottom: "1px solid #ededed",
          borderTop: "1px solid #ededed",
          display: "flex",
          background: "transparent",
          justifyContent: "center",
          padding:'0',
        }}
      >
        {/* <Col lg="3">
          <Button
            size="sm"
            squared="true"
            theme="success"
            style={{ width: "100%" }}
            className="savedButton"
          >
            BUY NOW
          </Button>
        </Col> */}
        <Col lg="1"></Col>
        <Col lg="5">
          <Button
            type="submit"
            style={{
              background: "transparent",
              border: "none",
              margin: "10px auto",
              padding: "10px 20px",
              color: "#00a567",
              fontWeight: "bold",
              display: "inline-block",
              textAlign: "center",
              fontSize: '14px',

            }}
            onClick={() => {
              setShowSum(true);
              setlaybyPurchase(data);
            }}
          >
            CHECKOUT USING LAY BUY
          </Button>
          {/*  */}
          {/* // global.userInfo.address && global.userInfo.city
                  //   ? handleOrder()
                  //     .then(res => {
                  //       setOrder(res)
                  //       global.setLoading(false);
                  //     })
                  //     .catch((error) => {
                  //       global.setLoading(false);
                  //     })
                  //   : handleAddress(); */}
        </Col>
        <Col lg="3" style={{ textAlign: "center" }}>
          <Button
            style={{
              background: "transparent",
              border: "none",
              margin: "10px auto",
              padding: "10px 20px",
              color: "#00a567",
              fontWeight: "bold",
              fontSize: '14px',
              display: "inline-block",
            }}
            onClick={() => {
              removeCartHandler(data.product._id);
            }}
          >
            REMOVE
          </Button>
        </Col>
        <Col lg="2"></Col>
      </Card.Footer>
    </Card>
  );
};

export const TheCart = ({
  cart,
  order,
  setShowSum,
  setOrder,
  setlaybyPurchase,
  layBuyPayment,
  setlayByDetails,
  layByDetails,
}) => {
  const global = React.useContext(ThemeContext);
  const isMobile = useMediaQuery({ query: "(max-width: 479px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const history = useHistory();

  const handleAddress = () => {
    history.push({
      pathname: "/account",
      data: "edit",
      text: "You need to supply an address and select a city before creating an order ✌️",
    });
    global.setLoading(true);
  };
  const handleOrder = () => {
    global.setLoading(true);
    return fetch(process.env.REACT_APP_API_URL + "/orders/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: global.userInfo._id,
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        setShowSum(true);
        setOrder(data);
        global.setLoading(false);
      })
      .catch((error) => {
        global.setLoading(false);
      });
  };
  return (
    <div className="container mt-8 mb-4">
      <div
        className="row gutter-lg"
        style={{
          justifyContent: "center",
          fontSize: "100%",
          textTransform: "uppercase",
        }}
      >
        <div className="col-lg-8 col-md-12">
          <div className="shop-table cart-table mt-2">
            {isMobile || isTablet ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span>Item</span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "50%",
                  }}
                >
                  <div>
                    <span>Quantity</span>
                  </div>
                  <div>
                    <span>Unit Price</span>
                  </div>
                  <div>
                    <div>Subtotal</div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="mt-5"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Card
                style={{
                  border: 'none'
                }}
              >
                <Card.Body>
                  <Col>
                    {cart.items.map((res, index) => {
                      return res.product.active ? (
                        <Cartproduct
                          data={res}
                          key={index}
                          setShowSum={setShowSum}
                          setOrder={setOrder}
                          cart={cart}
                          order={order}
                          setlaybyPurchase={setlaybyPurchase}
                          setlayByDetails={setlayByDetails}
                          layByDetails={layByDetails}
                        />
                      ) : null;
                    })}
                  </Col>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div
            className="mb-6 pt-6"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "150%",
            }}
          >
            <span style={{ marginRight: "15px" }}>TOTAL :</span>
            <span>₦ {numberWithCommas(cart.totalprice)}</span>
          </div>
          <span className="mb-3 d-flex flex-row-reverse text-lowercase">
            Delivery fee not included yet
          </span>
          <div className="cart-actions mb-6 pt-6">
            <button
              type="submit"
              className="btn btn-md btn-icon-left"
              style={{
                backgroundColor: "#00a567",
                color: "white",
                marginLeft: "20px",
                marginBottom: "20px",
                border: "none",
              }}
              onClick={() => {
                global.userInfo.address && global.userInfo.city
                  ? handleOrder()
                  : handleAddress();
              }}
            >
              <i className="d-icon-refresh" />
              Proceed to Checkout
            </button>

            <Link
              to="/"
              onClick={() => {
                global.setLoading(true);
              }}
            >
              <button
                type="submit"
                className="btn btn-md btn-outline btn-black btn slide-animate"
                style={{ marginLeft: "20px", marginBottom: "20px" }}
              >
                Continue Shopping
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TheCheckout = ({
  cart,
  order,
  laybyPurchase,
  setlayBy,
  layByDetails,
}) => {
  const history = useHistory();
  const global = React.useContext(ThemeContext);
  const handleFlutterPayment = useFlutterwave(order.config);
  8;

  const [address, setaddress] = useState();
  const [phoneNo, setphoneNo] = useState();
  console.log(global);

  const mapped = laybyPurchase ? [laybyPurchase] : cart.items;
  React.useEffect(() => {
    setaddress(global.userInfo.address);
    setphoneNo(global.userInfo.phoneNo);
  }, []);

  const updateInfo = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/user/${global.userInfo._id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${global.userInfo.token}`,
        },
        body: JSON.stringify([
          {
            propName: "phoneNo",
            value: phoneNo,
          },
          {
            propName: "address",
            value: address,
          },
        ]),
      }
    );
    return response.json();
  };
  console.log(order)
  return (
    <Col lg="10" style={{ margin: "auto" }}>
      <Col style={{ textAlign: "center" }}>
        <div style={{ backgroundColor: "transparent" }}>
          <h4 style={{ color: "#00a567", margin: "0 auto" }}>ORDER SUMMARY</h4>
        </div>
      </Col>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="12">
              <Card
                style={{
                  border: "0px solid rgb(215, 214, 214)",
                  boxShadow: "none",
                }}
              >
                <Card.Body className="accountCard.Body">
                  <Row style={{ display: "flex", flexDirection: "row" }}>
                    {mapped.map((res, idx) => {
                      return (
                        <Col key={idx} lg="6">
                          <Card
                            style={{
                              border: "1px solid rgb(215, 214, 214)",
                              boxShadow: "none",
                              marginTop: "4%",
                            }}
                          >
                            <Card.Body
                              className="orderCard.Body"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "0",
                              }}
                            >
                              <Row style={{ margin: "auto", width: "100%" }}>
                                <Col sm="4">
                                  <Card.Img
                                    className="orderImage"
                                    src={res.product.productImage[0]}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                </Col>
                                <Col
                                  sm="8"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <h6 style={{ marginBottom: "5px" }}>
                                    {res.product.name}
                                  </h6>

                                  <span
                                    style={{
                                      fontFamily: "sofia",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    QTY: {res.product.quantity}
                                  </span>

                                  <h5>
                                    SUB TOTAL:
                                    <span
                                      style={{
                                        color: "#00a567 ",
                                        textTransform: "uppercase",
                                        margin: "5px 0",
                                      }}
                                    >
                                      ₦ {numberWithCommas(res.sumprice)}
                                    </span>
                                  </h5>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Card.Body>

                {/* <Card.Footer
                  className="accountCard.Footer"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    borderTop: "1px solid #ededed",
                  }}
                >
                   <Button
                  size="md"
                  outline
                  squared="true"
                  theme="success"
                  className="accontButton"
                >
                  EDIT PROFILE
                </Button> 
                </Card.Footer> */}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" style={{ marginTop: "10px" }}>
              <Card
                style={{
                  border: "1px solid rgb(215, 214, 214)",
                  boxShadow: "none",
                  padding: "15px",
                }}
              >
                <Card.Header className="accountCard.Header" style={{backgroundColor:'transparent'}}>
                  <h5 style={{ margin: "0px" }}>Address Book</h5>
                </Card.Header>
                <Card.Body className="accountCard.Body">
                  <Row>
                    <Col
                      sm="12"
                      lg="5"
                      style={{ margin: "20px 10px 20px 15px" }}
                    >
                      <Form>
                        <Form.Group>
                          <label
                            htmlFor="username"
                            style={{ fontSize: "14px" }}
                          >
                            Phone Number
                          </label>
                          <Form.Control
                            style={{
                              borderRadius: ".375rem",
                              borderTop: "none",
                              borderLeft: "none",
                              borderRight: "none",
                              padding: "10px 5px",
                            }}
                            id="LastName"
                            value={phoneNo}
                            onChange={(res) => {
                              setphoneNo(res.target.value);
                            }}
                          />
                        </Form.Group>
                      </Form>
                    </Col>

                    <Col
                      sm="12"
                      lg="5"
                      style={{ margin: "20px 10px 20px 15px" }}
                    >
                      <Form>
                        <Form.Group>
                          <label
                            htmlFor="username"
                            style={{ fontSize: "14px" }}
                          >
                            Address
                          </label>
                          <Form.Control
                            style={{
                              borderRadius: ".375rem",
                              borderTop: "none",
                              borderLeft: "none",
                              borderRight: "none",
                              padding: "10px 5px",
                            }}
                            required
                            value={address}
                            onChange={(res) => {
                              setaddress(res.target.value);
                            }}
                          />
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                  <p style={{ fontFamily: "sofia" }}>
                    {global.userInfo.address ? (
                      <></>
                    ) : (
                      <h6>
                        You Are Yet to supply Your delivery address, you will
                        not be able to place an order.
                      </h6>
                    )}
                  </p>
                  <Button
                    style={{
                      background: "transparent",
                      color: "#00a567",
                      padding: "0",
                      fontWeight: "bold",
                      margin: "10px 0",
                      border: "none",
                      textAlign: "center",
                      fontSize:'15px'
                    }}
                    onClick={() => {
                      updateInfo().then((res) => console.log(res));
                    }}
                  >
                    Update
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col lg="6" style={{ marginTop: "10px" }}>
              <Card
                style={{
                  border: "1px solid rgb(215, 214, 214)",
                  boxShadow: "none",
                }}
              >
                <Card.Body style={{ padding: "15px" }}>
                  <Col>
                    {laybyPurchase ? (
                      <>
                        <h6>First payment should be a minimum of 20%</h6>
                        <div className="sumRow">
                          <h6>Subtotal</h6>
                          <h6>
                            ₦{" "}
                            {numberWithCommas(
                              laybyPurchase
                                ? laybyPurchase.sumprice
                                : cart.totalprice
                            )}
                          </h6>
                        </div>
                        <div className="sumRow">
                          <h6>Minimum</h6>
                          <h6>
                            ₦{" "}
                            {numberWithCommas(
                              (20 / 100) * laybyPurchase.sumprice
                            )}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="sumRow">
                          <h6>Subtotal</h6>
                          <h6>
                            ₦{" "}
                            {numberWithCommas(
                              laybyPurchase
                                ? laybyPurchase.sumprice
                                : cart.totalprice
                            )}
                          </h6>
                        </div>
                        <div
                          className="sumRow"
                          style={{
                            borderBottom: "1px solid rgb(215, 214, 214)",
                            marginBottom: "4%",
                          }}
                        >
                          <h6>Delivery Fee</h6>
                          <h6>
                            ₦{" "}
                            {laybyPurchase
                              ? ""
                              : order
                              ? numberWithCommas(order.payment.deliveryFee)
                              : ""}
                          </h6>
                        </div>
                        <div className="sumRow">
                          <h4 className="mb-0">Total</h4>
                          <h5 style={{ color: "#00a567 " }}>
                            ₦{" "}
                            {numberWithCommas(
                              (laybyPurchase
                                ? +laybyPurchase.sumprice
                                : +cart.totalprice) +
                                (laybyPurchase
                                  ? +laybyPurchase.shipping
                                  : +cart.totalshipping) +
                                (laybyPurchase
                                  ? 0
                                  : order
                                  ? +order.payment.deliveryFee
                                  : 0)
                            )}
                          </h5>
                        </div>
                      </>
                    )}

                    <div className="sumRow d-flex justify-content-center">
                      <p
                        className="text-center pt-5 pb-5 d-flex flex-column"
                        style={{ color: "#17c671" }}
                      >
                        If you wish to pay by bank transfer, send the Total
                        amount above to Greenlab Microfactory UBA 1023074968{" "}
                        then contact the admin via +2348092205588 or mail to
                        Support@isheda.com for confirmation.
                      </p>
                    </div>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer
          className="accountCard.Footer"
          style={{
            justifyContent: "center",
            display: "flex",
            borderTop: "1px solid #ededed",
          }}
        >
          {/* <PaystackConsumer {...componentProps}>
            {({ initializePayment }) => (
              <Button
                size="lg"
                outline
                squared="true"
                theme="success"
                className="accontButton"
                onClick={() => initializePayment(handleSuccess, handleClose)}
              >
                <h5 style={{ margin: "0px", color: "inherit" }}>
                  PROCEED TO PAYMENT
                </h5>
              </Button>
            )}
          </PaystackConsumer> */}
          {laybyPurchase ? (
            <Button
              size="lg"
              outline
              squared="true"
              theme="success"
              style={{ margin: "10px auto" }}
              onClick={() => {
                history.push(`/laybuy/${laybyPurchase._id}`);
                localStorage.setItem(
                  "layByAmount",
                  JSON.stringify((20 / 100) * laybyPurchase.sumprice)
                );
              }}
              // setlayBy(true)
            >
              <h5 style={{ margin: "0px", color: "inherit" }}>
                PROCEED TO PAYMENT
              </h5>
            </Button>
          ) : (
            <Button
              size="lg"
              outline
              squared="true"
              theme="success"
              style={{ margin: "10px auto" }}
              onClick={() => {
                handleFlutterPayment({
                  callback: (response) => {
                    closePaymentModal(); // this will close the modal programmatically
                  },
                  onClose: () => {
                    global.setRedirect(true);
                    global.setRefresh(true);
                  },
                });
              }}
            >
              <h5 style={{ margin: "0px", color: "inherit" }}>
                PROCEED TO PAYMENT
              </h5>
            </Button>
          )}
        </Card.Footer>
      </Card>
    </Col>
  );
};
export const LaidByPayment = (props) => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();
  let { id } = useParams();
  const [amount, setamount] = useState("");
  const [msg, setmsg] = useState(null);
  global.setLoading(false);
  console.log(msg);
  let handleFlutterPayment = useFlutterwave(msg && msg.message !== "Payment already complete. Please proceed to checkout" ? msg.paymentConfig.payments.config : {});
  const hello = async () => {
    const request = await fetch(
      process.env.REACT_APP_API_URL + "/laidby/checkout",
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${global.userInfo.token}`,
        },
        body: JSON.stringify({
          laidById: id,
          amount: +amount,
        }),
      }
    );
    return request.json();
  };

  const pay = () => {
    handleFlutterPayment({
      callback: (response) => {
        // closePaymentModal(); // this will close the modal programmatically
        global.setLoading(true);
        history.push({
          pathname: "/account",
          data: "layby",
          text: "You can now view this product in lay by",
        });
        global.setRefresh(true);
      },
      onClose: () => {
        global.setLoading(true);
        history.push({
          pathname: "/account",
          data: "layby",
          text: "You can now view this product in lay by",
        });
        global.setRefresh(true);
      },
    });
  };
  if (
    msg !== null &&
    msg.message !== "Payment already complete. Please proceed to checkout"
  ) {
    pay();
  }
  if (
    msg !== null &&
    msg.message === "Payment already complete. Please proceed to checkout"
  ) {
    setTimeout(() => {
      history.push("/");
    }, 900);
  }
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        class="layby"
        style={
          {
            // display: 'flex',
            // alignItems:'center',
          }
        }
      >
        <label
          style={{
            display: "block",
            margin: "0 0 10px 0",
          }}
        >
          {msg ? msg.message : ""}
        </label>
        <span>
          <label
            style={{
              display: "block",
              margin: "0 0 10px 0",
            }}
          >
            How much would you like to lay down
          </label>
          <div>
            <b
              style={{
                border: "1px solid #D8D8D8",
                borderRight: "0px solid transparent ",
                padding: "17.5px  20px 19px 20px",
                width: "10%",
                margin: "auto",
              }}
            >
              ₦
            </b>
            <input
              type="number"
              style={{
                border: "1px solid #D8D8D8",
                padding: "20px",
                margin: "auto",
              }}
              placeholder="Enter amount of ₦100 minimum"
              value={amount}
              onChange={(e) => setamount(e.target.value)}
              required
            />
          </div>
          <div
            style={{
              display: +amount < 100 ? "none" : "block",
            }}
          >
            <b
              style={{
                border: "1px solid #D8D8D8",
                borderRight: "0px solid transparent ",
                padding: "17.5px  20px 19px 20px",
                width: "10%",
                margin: "auto",
              }}
            >
              ₦
            </b>
            <input
              type="number"
              style={{
                border: "1px solid #D8D8D8",
                padding: "20px",
                margin: "10px auto",
              }}
              placeholder="Enter amount of ₦100 minimum"
              value={Math.ceil(+amount + +amount * (1.4 / 100))}
              disabled
            />
            <label
              style={{
                display: "block",
                margin: "0 0 10px 0",
              }}
            >
              1.4% payment fee
            </label>
          </div>
        </span>
        <span>
          <button
            style={{
              border: "none",
              padding: "20px 0",
              width: "98.5%",
              margin: "2em auto",
            }}
            disabled={+amount < 100 || amount === undefined}
            onClick={() => {
              hello().then((res) => {
                console.log(amount);
                setmsg(res);
              });
            }}
          >
            Pay Installment
          </button>
        </span>
      </div>
    </div>
  );
};
export const Emptycart = () => {
  return (
    <div className="container mt-8 mb-4">
      <div
        className="row gutter-lg"
        style={{ marginTop: "5%", marginBottom: "5%" }}
      >
        <span
          style={{
            justifyContent: "center",
            fontSize: "100%",
            textTransform: "uppercase",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textTransform: "uppercase",
          }}
        >
          <img
            src="https://www.jumia.com.ng/images/oshun/cart/empty-cart.png"
            alt="cart icon"
            width={150}
            height={150}
          />
          <Col lg="10" className="d-flex flex-column align-items-center ">
            <h5>Your cart is currently empty 😅</h5>

            <span className="mt-4">
              - Add a product to your cart and see what happens 🤭.
            </span>
            <span className="mt-4">
              - You can also view your saved items and see the ones you would
              love to buy 💯.
            </span>
          </Col>

          <div className="cart-action" style={{ marginTop: "5%" }}>
            <span className="btn btn-primary btn-md ml-2">
              <Link to="/">
                <span>Start shopping</span>
              </Link>
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};

export const EmptyUser = () => {
  const global = React.useContext(ThemeContext);
  return (
    <div className="container mt-8 mb-4">
      <div
        className="row gutter-lg"
        style={{ marginTop: "5%", marginBottom: "5%" }}
      >
        <span
          style={{
            justifyContent: "center",
            fontSize: "100%",
            textTransform: "uppercase",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textTransform: "uppercase",
          }}
        >
          <img
            src="https://www.jumia.com.ng/images/oshun/cart/empty-cart.png"
            alt="cart icon"
            width={150}
            height={150}
          />
          <span style={{ marginTop: "20px", fontSize: "150%", color: "#666" }}>
            You need to Signin to View your cart !
          </span>

          <div className="cart-action" style={{ marginTop: "5%" }}>
            <span className="btn btn-primary btn-md ml-2">
              <span onClick={() => global.setLogin(true)}>Login</span>
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};

export const LaidByPay = (props) => {
  const global = React.useContext(ThemeContext);
  global.setLoading(false);

  const history = useHistory();
  let { id } = useParams();
  const [amount, setamount] = useState("");
  const [msg, setmsg] = useState(null);

  const [show, setShow] = useState(true);

  const handleShow = () => setShow(true);
  let amt = JSON.parse(localStorage.getItem("layByAmount"));

  let handleFlutterPayment = useFlutterwave(msg && msg.message === "Checkout with laidby has been successfully initiated. " ? msg.data.payments[0].config : {} );
  const hello = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/laidby/checkout",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${global.userInfo.token}`,
        },
        body: JSON.stringify({
          cartProductId: id,
          amount: +amount,
        }),
      }
    );
    return response.json();
  };
  // layBuyPayment()
  // .then(res => {
  //   localStorage.setItem("layByDetails", JSON.stringify(res))
  //   console.log(res)
  //   setShowSum(true)
  //   setlaybyPurchase(data)
  // })
  const pay = () => {
    handleFlutterPayment({
      callback: (response) => {
        global.setLoading(true);
        history.push({
          pathname: "/account",
          data: "layby",
          text: "You can now view this product in lay by",
        });
        global.setRefresh(true);
      },
      onClose: () => {
        global.setLoading(true);
        history.push({
          pathname: "/account",
          data: "layby",
          text: "You can now view this product in lay by",
        });
        global.setRefresh(true);
      },
    });
  };
  if (
    msg !== null &&
    msg.message === "Checkout with laidby has been successfully initiated. "
  ) {
    pay();
  }

  return (
    <div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          class="layby"
          style={
            {
              // display: 'flex',
              // alignItems:'center',
            }
          }
        >
          <label
            style={{
              display: "block",
              margin: "0 0 10px 0",
            }}
          >
            {msg ? msg.message : ""}
          </label>
          <span>
            <label
              style={{
                display: "block",
                margin: "0 0 10px 0",
              }}
            >
              How much would you like to lay down
            </label>
            <div>
              <b
                style={{
                  border: "1px solid #D8D8D8",
                  borderRight: "0px solid transparent ",
                  padding: "17.5px  20px 19px 20px",
                  width: "10%",
                  margin: "auto",
                }}
              >
                ₦
              </b>
              <input
                type="number"
                style={{
                  border: "1px solid #D8D8D8",
                  padding: "20px",
                  margin: "auto",
                }}
                placeholder={`Enter amount of 20% (₦${amt}) minimum`}
                value={amount}
                onChange={(e) => setamount(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                display:
                  +amount < +amt || amount === undefined ? "none" : "block",
              }}
            >
              <b
                style={{
                  border: "1px solid #D8D8D8",
                  borderRight: "0px solid transparent ",
                  padding: "17.5px  20px 19px 20px",
                  width: "10%",
                  margin: "auto",
                }}
              >
                ₦
              </b>
              <input
                type="number"
                style={{
                  border: "1px solid #D8D8D8",
                  padding: "20px",
                  margin: "10px auto",
                }}
                placeholder="Enter amount of ₦100 minimum"
                value={Math.ceil(+amount + +amount * (1.4 / 100))}
                disabled
              />
              <label
                style={{
                  display: "block",
                  margin: "0 0 10px 0",
                }}
              >
                1.4% payment fee
              </label>
            </div>
          </span>
          <span>
            <button
              style={{
                border: "none",
                padding: "20px 0",
                width: "98.5%",
                margin: "2em auto",
              }}
              disabled={+amount < +amt || amount === undefined}
              onClick={() => {
                hello()
                  .then((res) => {
                    console.log(res);
                    console.log(res.data.payments[0].config);
                    setmsg(res);
                    // handleShow()
                  })
                  .catch((res) => {
                    setmsg(res);
                  });
              }}
            >
              Pay Installment
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};
