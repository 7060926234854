import React, { useState } from "react";
import "../styles/css/login.css";
import { ThemeContext } from "../Hooks/context";
import { Loader } from "../components/General";
import { post_request } from "../services/makeRequest";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [errorMsg, setErrorMsg] = React.useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgot, setForgot] = useState("");
  const [forgotError, setForgotError] = useState(false);
  const [forgotMsg, setForgotMsg] = useState("");

  const [firstname, SetFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [signEmail, setSignEmail] = useState("");
  const [signPassword, setSignPassword] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const global = React.useContext(ThemeContext);
  // const usernames = "usernames";

  const HandleLogin = async (res) => {
    setLoading(true);
    res.preventDefault();
    try {
      const url = "/user/login";

      const payload = {
        email: email,
        password: password,
      };

      const response = await post_request(url, payload);

      if (!response) {
        setErrorMsg("res");
        setError(true);
        setLoading(false);
      }

      if (response) {
        const data = response.data;
        global.setUserInfo(data);
        localStorage.setItem("userInfo", JSON.stringify(data));
        localStorage.setItem("token", data.token);
        global.setRefresh(true);
        global.setLogin(false);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      err.message === "Network request failed"
        ? setErrorMsg("net")
        : setErrorMsg("wrong");
    }
  };

  const HandleSignup = async (res) => {
    setLoading(true);
    res.preventDefault();
    try {
      const url = "/user/signup";

      const payload = {
        firstname: firstname,
        lastname: lastname,
        password: signPassword,
        email: signEmail,
        phoneNo: phoneNo,
      };

      const response = await post_request(url, payload);

      if (!response) {
        if (response.status === 409) {
          setErrorMsg("taken");
          setError(true);
          setLoading(false);
        } else {
          setErrorMsg("resReg");
          setError(true);
          setLoading(false);
        }
      }

      if (response) {
        const data = response.data;
        global.setUserInfo(data);
        localStorage.setItem("userInfo", JSON.stringify(data));
        global.setRefresh(true);
        global.setLogin(false);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      err.message === "Network request failed"
        ? setErrorMsg("net")
        : setErrorMsg("wrong");
    }
  };

  const HandleReset = async (res) => {
    setLoading(true);
    res.preventDefault();
    try {
      const url = "/user/mail";

      const payload = {
        email: forgot,
      };

      const response = await post_request(url, payload);

      if (!response) {
        const data = response.data;
        setForgotMsg("res");
        setForgotError(true);
        setLoading(false);
      }

      if (response) {
        setForgotMsg("nice");
        setForgotError(true);
        setLoading(false);
      }
    } catch (err) {
      setForgotMsg("wrong");
      setForgotError(true);
      setLoading(false);
    }
  };

  return (
    <div className="carton">
      <div className="login-wrap">
        <div className="login-html">
          {error === true ? (
            <div className="error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-info-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
              <span className="error-text" style={{ textAlign: "center" }}>
                {errorMsg === "net"
                  ? "No internet connection found"
                  : errorMsg === "res"
                  ? "Invalid username or password."
                  : errorMsg === "resReg"
                  ? "something went wrong during your registration."
                  : errorMsg === "taken"
                  ? "The email you entered has been taken by another user."
                  : "Something went wrong please try again"}
              </span>
            </div>
          ) : null}

          <input
            id="tab-1"
            type="radio"
            name="tab"
            className="sign-in"
            defaultChecked
          />
          <label
            onClick={() => {
              setError(false);
            }}
            htmlFor="tab-1"
            className="tab"
          >
            Login
          </label>
          <input id="tab-2" type="radio" name="tab" className="sign-up" />

          <label
            onClick={() => {
              setError(false);
            }}
            htmlFor="tab-2"
            className="tab"
          >
            Sign Up
          </label>
          <input id="tab-3" type="radio" name="tab" className="forgot" />
          <label
            htmlFor="tab-3"
            className="tab"
            style={{ visibility: "hidden" }}
          >
            Forgot
          </label>

          <div className="login-form">
            <form className="sign-in-htm" onSubmit={HandleLogin}>
              <div className="group">
                <label htmlFor="user" className="label">
                  Email
                </label>
                <input
                  id="user"
                  type="email"
                  className="input"
                  value={email}
                  onChange={(res) => {
                    setEmail(res.target.value);
                  }}
                />
              </div>
              <div className="group">
                <label htmlFor="pass" className="label">
                  Password
                </label>
                <input
                  id="pass"
                  type="password"
                  className="input"
                  data-type="password"
                  value={password}
                  onChange={(res) => {
                    setPassword(res.target.value);
                  }}
                />
              </div>
              <div className="group space">
                <input
                  id="check"
                  type="checkbox"
                  className="check"
                  defaultChecked
                />
                <label htmlFor="check ">
                  <span className="icon " /> Keep me Signed in
                </label>
              </div>

              <div className="group">
                {email && password ? (
                  <button
                    type="submit"
                    className="button space"
                    defaultValue="Sign In"
                    title="sign in"
                  >
                    Login
                  </button>
                ) : (
                  <span className="button-dark button space">Login</span>
                )}
              </div>

              <div className="hr" />
              <div className="foot-lnk">
                <label
                  onClick={() => {
                    setError(false);
                  }}
                  htmlFor="tab-3"
                >
                  Forgot Password ?
                </label>
              </div>
            </form>
            <form className="sign-up-htm" onSubmit={HandleSignup}>
              <div className="name-case">
                <div className="group ">
                  <label htmlFor="pass" className="label">
                    First name
                  </label>
                  <input
                    id="pass"
                    type="text"
                    className="input"
                    value={firstname}
                    onChange={(res) => {
                      SetFirstname(res.target.value);
                    }}
                  />
                </div>
                <div className="group group-margin">
                  <label className="label">Last name</label>
                  <input
                    id="pass"
                    type="text"
                    className="input"
                    value={lastname}
                    onChange={(res) => {
                      setLastname(res.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="group">
                <label className="label">Email Address</label>
                <input
                  className="input"
                  value={signEmail}
                  onChange={(res) => {
                    setSignEmail(res.target.value);
                  }}
                />
              </div>
              <div className="group">
                <label htmlFor="pass" className="label">
                  Password
                </label>
                <input
                  id="pass"
                  type="password"
                  className="input"
                  data-type="password"
                  value={signPassword}
                  onChange={(res) => {
                    setSignPassword(res.target.value);
                  }}
                />
              </div>

              <div className="group">
                <label htmlFor="user" className="label">
                  Phone number
                </label>
                <input
                  type="text"
                  className="input"
                  value={phoneNo}
                  onChange={(res) => {
                    setPhoneNo(res.target.value);
                  }}
                />
              </div>

              <div className="group">
                {signEmail &&
                signPassword &&
                firstname &&
                lastname &&
                phoneNo ? (
                  <button
                    type="submit"
                    className="button space"
                    defaultValue="Sign Up"
                    title="sign Up"
                  >
                    Signup
                  </button>
                ) : (
                  <span className="button-dark button space">Signup</span>
                )}
              </div>

              <div className="hr" />
              <div className="foot-lnk">
                <label htmlFor="tab-1">Already Member?</label>
              </div>
            </form>
            <form className="forgot-htm" onSubmit={HandleReset}>
              <div className="group">
                <label className="label mb-9" style={{ textAlign: "center" }}>
                  {forgotMsg === "nice" ? (
                    <span style={{ color: "#00a567" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        fill="currentColor"
                        class="bi bi-check2-circle"
                        viewBox="0 0 16 16"
                        className="mr-2"
                      >
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                      </svg>
                      We have sent you an email to , please check your inbox to
                      proceed
                    </span>
                  ) : forgotMsg === "res" ? (
                    <span style={{ color: "rgb(206, 40, 40)" }}>
                      we were unable to send you a mail, please try again
                    </span>
                  ) : forgotMsg === "wrong" ? (
                    <span style={{ color: "rgb(206, 40, 40)" }}>
                      something went wrong please try again
                    </span>
                  ) : (
                    "Enter your email address and we'll help you recover your Account"
                  )}
                </label>
              </div>

              <div className="group mb-5">
                <label htmlFor="user" className="label">
                  Email Address
                </label>
                <input
                  type="email"
                  className="input"
                  value={forgot}
                  onChange={(res) => {
                    setForgot(res.target.value);
                  }}
                />
              </div>

              <div className="group">
                {forgot ? (
                  <button
                    type="submit"
                    className="button space"
                    defaultValue="Sign Up"
                    title="reset password"
                  >
                    Reset Password
                  </button>
                ) : (
                  <span className="button-dark button space">
                    Reset Password
                  </span>
                )}
              </div>

              <div className="hr" />
              <div className="foot-lnk">
                <label htmlFor="tab-1">Remember password now?</label>
              </div>
            </form>
          </div>
        </div>
      </div>
      <span
        class=" mobile-menu-close"
        onClick={() => {
          global.setLogin(false);
        }}
      >
        <i class="d-icon-times"></i>
      </span>
      {loading ? <Loader /> : null}
    </div>
  );
};

export default Login;
