export const storeData = async (name, value) => {
  try {
    const jsonValue = value === String ? value : JSON.stringify(value);
    await localStorage.setItem(name, jsonValue);
  } catch (e) {
    // saving error
    console.log("shit didn't save");
  }
};

export const removeData = async (name) => {
  try {
    await localStorage.removeItem(name); //removeItem()
  } catch (e) {
    // saving error
    console.error("shit didn't remove");
  }
};

export const getData = async (name) => {
  try {
    const value = await localStorage.getItem(name); //removeItem() getAllKeys()
    const jsonValue = value ? value.replace(/['"]+/g, "") : null;

    return jsonValue;
  } catch (e) {
    console.error("shit could not be read");
  }
};
