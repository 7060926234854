import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { ThemeContext } from "../Hooks/context";

const Products = (props) => {
  const themeContext = React.useContext(ThemeContext);
  const history = useHistory();

  const id = props.match.params.id;
  const url = "https://isheda-backend.onrender.com";

  useEffect(() => {
    GetProducts();
  }, []);

  function GetProducts() {
    fetch(url + "/products/" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          themeContext.setError(true);
          themeContext.setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          console.log(data.product);
          localStorage.setItem("product", JSON.stringify(data.product));
          history.push({
            pathname: "/product",
            data: data.product,
          });
          themeContext.setLoading(false);
        }
      })
      .catch((error) => {
        themeContext.setError(true);
        themeContext.setLoading(false);
      });
  }

  return themeContext.loading || themeContext.error ? null : <div></div>;
};

export default Products;
