import React, { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import {
  CarouselProvider,
  Slider,
  Slide,
  // ButtonBack,
  // ButtonNext,
} from 'pure-react-carousel';
import { get_request, post_request } from '../services/makeRequest';
import { ThemeContext } from '../Hooks/context';
import { useMediaQuery } from 'react-responsive';

import HugeProjectsMobile from '../assets/Huge-Projects.jpg'
import HugeProjectsDesktop from '../assets/Huge-ProjectsDekstop.jpg'

// Reusable components
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const Category_1 = ({ data }) => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });

  const handleCategory = async (value) => {
    global.setLoading(true);
    // res.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + '/products/category';

      const payload = {
        category: value,
      };
      let response;
      if (value.includes("uino")) {
        response = await post_request(url, { category: "Arduino" });
      } else {
        response = await post_request(url, payload);
      }

      if (!response) {
        // global.setError(true);

        global.setLoading(false);
      }

      if (response) {
        const data = await response.data;
        localStorage.setItem('productlist', JSON.stringify(data.category));
        history.push({
          pathname: '/productlist',
          data: data.category,
          text: value,
        });
        global.setLoading(false);
      }
    } catch (err) {
      // global.setError(true);
      global.setLoading(false);
    }
  };

  return (
    <div
      className="appear-animation-visible col-md-2 col-6 mb-4"
      style={{ cursor: 'pointer' }}
    >
      <div
        className="appear-animation-visible category category-default category-default-1 category-absolute overlay-zoom"
        style={{ borderRadius: '5px' }}
        onClick={() => handleCategory(data.title)}
      >
        <span>
          <figure className="appear-animation-visible category-media">
            {/* <img src={data.img} alt="category" width={280} height={280} /> */}
            <div
              style={{
                backgroundImage: 'url(' + data.img + ')',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',
                backgroundSize: 'cover',
                height: isTablet ? '12rem' : '18rem',
              }}
            />
          </figure>
        </span>
        <div className="appear-animation-visible category-content">
          <h4 className="appear-animation-visible category-name">
            <span>{data.title}</span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export const Category_2 = ({ data }) => {
  const global = React.useContext(ThemeContext);

  let val = data.price;
  let dis = data.discount ? data.discount / 100 : 0 / 100;
  let discountPrice = val - val * dis;

  function PostRecent(value) {
    fetch(
      process.env.REACT_APP_API_URL +
      '/recent/updaterecent/' +
      global.userInfo.recentId._id,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product: value,
        }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          global.setError(true);
          global.setLoading(false);
          const data = await response.json();
        }
        if (response.ok) {
          const data = await response.json();
          // global.setLogin(false);
        }
      })
      .catch((error) => {
        global.setError(true);
        global.setLoading(false);
      });
  }

  return (
    <div className="appear-animation-visible product product-box">
      <Link
        to={{
          pathname: `/product/c920d${data._id}ui89`,
          data: data,
        }}
        onClick={() => {
          global.userInfo ? PostRecent(data._id) : console.log('null');
          global.setLoading(true);
          localStorage.setItem('product', JSON.stringify(data));
        }}
      >
        <figure className="appear-animation-visible product-media">
          {/* <div
            // src={}
            style={{
              height: "15rem",
              // backgroundImage: `url(${data.productImage[0]})`,
              backgroundImage: "url(" + data.productImage[0] + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          /> */}

          <span>
            <img
              src={data !== undefined && data.productImage[0] !== undefined ? data.productImage[0] : ''}
              style={{
                height: '17rem',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            />
          </span>

          {data.discount && data.discount > 0 ? (
            <div className="product-label-group">
              <label className="product-label label-sale">
                {data.discount}% off
              </label>
            </div>
          ) : null}
          {/* <div className="appear-animation-visible product-label-group">
          <label className="appear-animation-visible product-label label-new">
            new
          </label>
        </div> */}
          {/* <div className=" product-action-vertical">
            <span
              className="btn-product-icon btn-cart"
              data-toggle="modal"
              data-target="#addCartModal"
              title="Add to cart"
              onClick={() => {
                global.userInfo ? cartHandler(data._id) : console.log();
              }}
            >
              <i className=" d-icon-bag" />
            </span>
          </div> */}
          {/* <div className="product-action">
          <span
            className="btn-product btn-quickview"
            title="Quick View"
          >
            Quick View
          </span>
        </div> */}
        </figure>
        <div className="appear-animation-visible product-details">
          {/* <span
            className="appear-animation-visible btn-wishlist"
            title="Add to wishlist"
          >
            <i className="appear-animation-visible d-icon-heart" />
          </span> */}
          <div className="appear-animation-visible product-cat">
            <span>{data.category}</span>
          </div>
          <h3 className="appear-animation-visible product-name">
            <span>{data.name}</span>
          </h3>
          <div className="appear-animation-visible product-price">
            <ins className="appear-animation-visible new-price">
              ₦ {numberWithCommas(discountPrice)}
            </ins>
            {data.discount && data.discount > 0 ? (
              <del className="appear-animation-visible old-price">
                ₦ {numberWithCommas(data.price)}
              </del>
            ) : null}
          </div>
          {/* <div className="appear-animation-visible ratings-container">
            <div className="appear-animation-visible ratings-full">
              <span
                className="appear-animation-visible ratings"
                style={{ width: "100%" }}
              />
              <span className="appear-animation-visible tooltiptext tooltip-top" />
            </div>
          </div> */}
        </div>
      </Link>
    </div>
  );
};

export const Category_3 = ({ data }) => {
  return (
    <Card>
      <Card.Header>here cat</Card.Header>
      <Card.Body>
        <Col>
          <Col>
            <image src="https://ng.jumia.is/cms/0-7-top-categories/white-bg/phones.jpg" />
          </Col>

          <Col>
            <image src="https://ng.jumia.is/cms/0-7-top-categories/white-bg/electronics.jpg" />
          </Col>
        </Col>
      </Card.Body>
    </Card>
  );
};

export const Major_1 = ({ data }) => {
  const history = useHistory();

  const global = React.useContext(ThemeContext);

  const isMobile = useMediaQuery({ query: '(max-width: 479px)' });

  const handleCategory = async (value) => {
    global.setLoading(true);

    try {
      const url = process.env.REACT_APP_API_URL + '/products/category';

      const payload = {
        category: value,
      };
      const response = await post_request(url, payload);

      if (!response) {
        // global.setError(true);
        global.setLoading(false);
      }

      if (response) {
        const data = await response.data;
        localStorage.setItem('productlist', JSON.stringify(data.category));
        history.push({
          pathname: '/productlist',
          data: data.category,
          text: value,
        });
        global.setLoading(false);
      }
    } catch (err) {
      // global.setError(true);
      global.setLoading(false);
    }
  };

  if (data.length < 1) {
    return;
  }

  return (
    <Col className="major-one-case container">
      <Col
        lg="6"
        className="major-one-img-case"
        onClick={() => {
          handleCategory(data.path_1);
        }}
      >
        <img
          className="major-one-img transition click"
          src={!isMobile ? data.img_1 : data.img_1_mb}
          alt="logo"
          width={163}
          height={39}
        />
      </Col>

      <Col
        lg="6"
        className="major-one-img-case"
        onClick={() => {
          handleCategory(data.path_2);
        }}
      >
        <img
          className="major-one-img transition click"
          src={!isMobile ? data.img_2 : data.img_2_mb}
          alt="logo"
          width={163}
          height={39}
        />
      </Col>
    </Col>
  );
};

export const Sponsors = ({ data }) => {
  return (
    <figure>
      <img src={data} alt="brand" width={180} height={100} />
    </figure>
  );
};

export const ProductSlider = ({ data, title }) => {
  const slideRef = useRef()
  const slideRight = () => { slideRef.current.scrollLeft += 480; }
  const slideLeft = () => { slideRef.current.scrollLeft -= 480; }
  return (
    <section className="appear-animation-visible product-wrapper container appear-animate mt-5  appear-animation-visible">
      <Card
        style={{
          boxShadow: 'none',
          padding: '20px',
          borderRadius: '5px'
        }}
      >
        <Card.Header className="homeCard.Header" style={{ backgroundColor:"transparent" }}>
          <h5 className="mt-2" style={{ margin: '0px',backgroundColor:"transparent" }}>
            {title}
          </h5>
          {/* <hr /> */}
        </Card.Header>
        <Card.Body className="homeCat">
          <div className="nav-btns">
            <button className="prev" onClick={slideLeft}><b>➤</b></button>
            <button className="next" onClick={slideRight}><b>➤</b></button>
          </div>

          <div
            className="appear-animation-visible owl-carousel owl-theme row owl-nav-full cols-2 cols-md-3 cols-lg-6 appear-animation-visible"
            ref={slideRef}
          >
            {data.length > 0 &&
              data.map((res, index, arr) => {
                if (title === "Recently viewed") {
                  if (res.product) {
                    if (res.product.deleted === false) {
                      return (
                        <Category_2
                          data={res.product ? res.product : res}
                          key={index}
                        />
                      );
                    }
                  }
                } else {
                  if (res) {
                    return (
                      <Category_2
                        data={res.product ? res.product : res}
                        key={index}
                      />
                    );
                  }
                }

              })}
          </div>
        </Card.Body>
      </Card>
    </section>
  );
};

export const FeaturedCat = ({ data, title }) => {
  if (data.length < 1) {
    return null;
  }

  return (
    <section className="appear-animation-visible product-wrapper pb-10 container appear-animate">
      <Card
        style={{
          boxShadow: 'none',
        }}
      >
        <Card.Header className="homeCard.Header">
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <h5 className="mt-2" style={{ margin: '0px' }}>
              {title}
            </h5>
          </Row>
        </Card.Header>
        <Card.Body className="homeCat">
          <Col lg="12">
            <div className="d-flex flex-row flex-wrap">
              {data.map((res) => {
                return (
                  <Col
                    lg="2"
                    xs="6"
                    className="d-flex flex-column pb-2 align-items-center  justify-content-center shadow1 transition major-one-img"
                  >
                    <img src={res.img} />
                    <span>{res.name}</span>
                  </Col>
                );
              })}
            </div>
          </Col>
        </Card.Body>
      </Card>
    </section>
  );
};

// main components
export const HomeSlide = ({ data }) => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();

  //  RESOLUTION HOOKS
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const isHd = useMediaQuery({
    query: '(min-width: 1920px)',
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1924px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 479px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });

  const handleCategory = async (value) => {
    console.log(value)
    global.setLoading(true);
    try {
      const url = '/products/category';

      const payload = {
        category: value,
      };

      const response = await post_request(url, payload);

      if (!response) {
        global.setLoading(false);
      }

      if (response) {
        const data = response.data;
        localStorage.setItem('productlist', JSON.stringify(data.category));
        history.push({
          pathname: '/productlist',
          data: data.category,
          text: value,
        });
        global.setLoading(false);
      }
    } catch (err) {
      global.setLoading(false);
    }
  };
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={120}
      totalSlides={data.length}
      isPlaying={true}
      interval={3000}
      // className="container pl-0 pr-0 border-radius-1"
      style={{ borderRadius: '8px' }}
    >
      <Slider
        // className="mainMargin"
        style={
          isBigScreen
            ? {
              width:"100%",
              height: '128rem',
              borderRadius: '8px',
              marginTop: '2%',
            }
            : isHd
              ? {
                height: '83vh',
                borderRadius: '8px',
                marginTop: '3%',
              }
              : isDesktopOrLaptop
                ? {
                  width:"100%",
                  height: '83vh',
                  borderRadius: '8px',
                  marginTop: '6%',
                }
                : isMobile
                  ? {
                    height: '28rem',
                    // borderRadius: "8px",
                    marginTop: '19%',
                  }
                  : isTablet
                    ? {
                      height: '28.5rem',
                      // borderRadius: "8px",
                      marginTop: '8%',
                    }
                    : {
                      height: '40rem',
                      borderRadius: '8px',
                      marginTop: '6%',
                    }
        }
      >
      {data.map((res, idx) => {
          return (
            <Slide index={idx} key={idx}>
              <div
                onClick={() => handleCategory(res.path)}
                style={{
                  backgroundImage: !isMobile
                    ? 'url(' + HugeProjectsDesktop + ')'
                    : 'url(' + HugeProjectsMobile + ')',
                  backgroundRepeat: 'no-repeat',
                  cursor: 'pointer',
                  backgroundSize: isBigScreen
                  ? '100%'
                  : isHd
                    ? '100%'
                    : isDesktopOrLaptop
                      ? '100%'
                      : isMobile
                        ? '100%'
                        : isTablet
                          ? '100%'
                          : '100%',
                  width: '100%',
                  height: isBigScreen
                    ? '128rem'
                    : isHd
                      ? '83vh'
                      : isDesktopOrLaptop
                        ? '83vh'
                        : isMobile
                          ? '28rem'
                          : isTablet
                            ? '28.5rem'
                            : '28rem',
                }}
              ></div>
            </Slide>
          );
        })}

        {/* <Slide index={1}>
                    <div
                      onClick={() => handleCategory("Arduino")}
                      style={{
                        backgroundImage: !isMobile
                          ? "url(" + slideTop2 + ")"
                          : "url(" + slideTop2MB + ")",
                        cursor: "pointer",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: isBigScreen
                          ? "128rem"
                          : isHd
                          ? "64rem"
                          : isDesktopOrLaptop
                          ? "45rem"
                          : isMobile
                          ? "28rem"
                          : isTablet
                          ? "28.5rem"
                          : "28rem",
                      }}
                    ></div>
                  </Slide> */}
      </Slider>
      {/* <ButtonBack>Back</ButtonBack>
                <ButtonNext>Next</ButtonNext> */}
    </CarouselProvider>
  );
};

export const HomeFeatures = () => {
  return (
    <section className="appear-animation-visible intro-section">
      <div className="appear-animation-visible service-list container appear-animate fadeIn appear-animation-visible">
        <div className="appear-animation-visible owl-carousel owl-theme row cols-lg-3 cols-sm-2 cols-1 ">
          <div className="appear-animation-visible icon-box icon-box-side icon-box1 appear-animate appear-animation-visible">
            <i className="appear-animation-visible icon-box-icon d-icon-truck appear-animation-visible" />
            <div className="appear-animation-visible icon-box-content">
              <h4 className="appear-animation-visible icon-box-title">
                Reduced Shipping Cost
              </h4>
              <p>secure and fast delivery</p>
            </div>
          </div>
          <div className="appear-animation-visible icon-box icon-box-side icon-box2 appear-animate appear-animation-visible">
            <i className="appear-animation-visible icon-box-icon d-icon-service appear-animation-visible" />
            <div className="appear-animation-visible icon-box-content">
              <h4 className="appear-animation-visible icon-box-title">
                Customer Support 24/7
              </h4>
              <p>Instant access to perfect support</p>
            </div>
          </div>
          <div
            className="appear-animation-visible icon-box icon-box-side icon-box3 appear-animate appear-animation-visible"
            data-animation-options="{
                              'name': 'fadeInRightShorter',
                              'delay': '.5s'
                          }"
          >
            <i className="appear-animation-visible icon-box-icon d-icon-secure appear-animation-visible" />
            <div className="appear-animation-visible icon-box-content">
              <h4 className="appear-animation-visible icon-box-title">
                100% Secure Payment
              </h4>
              <p>We ensure secure payment!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export const MainCategories = ({ data }) => {
  return (
    <section
      className="appear-animation-visible grey-section pt-10 pb-10 appear-animate appear-animation-visible"
      data-animation-options="{
              'delay': '.3s'
          }"
    >
      <div className="appear-animation-visible container pt-3">
        <h2 className="appear-animation-visible title">
          Browse Our Categories
        </h2>
        <div className=" appear-animation-visible owl-carousel owl-theme row owl-nav-full cols-2 cols-md-3 cols-lg-6 appear-animation-visible">
          {data.length < 1
            ? null
            : data.map((res, index) => {
              return <Category_1 data={res} key={index} />;
            })}
        </div>
      </div>
    </section>
  );
};

export const MainNav = () => {
  const global = React.useContext(ThemeContext);
  const history = useHistory();

  const handleDiscount = async () => {
    global.setLoading(true);
    try {
      const url = '/products/filter';

      const payload = {
        discount: 10,
      };

      const response = await post_request(url, payload);

      if (!response) {
        global.setLoading(false);
      }

      if (response) {
        const data = response.data;
        localStorage.setItem('productlist', JSON.stringify(data.product));
        history.push({
          pathname: '/productlist',
          data: data.product,
          text: '10% Discount',
        });
        global.setLoading(false);
      }
    } catch (err) {
      console.log(err);
      global.setLoading(false);
    }
  };
  return (
    <section
      className="appear-animation-visible banner parallax home-layout my-3"
      style={{ backgroundColor: '#44352d' }}
    >
      <div>
        <div className="appear-animation-visible container banner-content appear-animate text-center">
          <h3 className="appear-animation-visible banner-subtitle text-white font-weight-bold mb-2">
            <span className="appear-animation-visible label-star bg-primary text-uppercase text-white ml-2 mr-2">
              10% Off
            </span>
          </h3>
          <h2 className="appear-animation-visible banner-title font-weight-bold text-uppercase text-white mb-2">
            All Categories
          </h2>
          <p className="appear-animation-visible font-primary text-white mb-6">
            with a secure and fast delivery service
          </p>
          <span
            className="appear-animation-visible btn btn-solid pl-5 pr-5"
            onClick={handleDiscount}
          >
            Explore&nbsp;Now
          </span>
        </div>
      </div>
    </section>
  );
};

export const Client = ({ data }) => {
  return (
    <section
      className="appear-animation-visible mt-3 pt-10 pb-10 appear-animate"
      data-animation-options="{
              'delay': '.3s'
          }"
    >
      <div className="appear-animation-visible container">
        <h2 className="appear-animation-visible title">Our Clients</h2>
        <div className="appear-animation-visible owl-carousel owl-theme row brand-carousel cols-xl-6 cols-lg-5 cols-md-4 cols-sm-3 cols-2">
          {data.map((res, index) => {
            return <Sponsors data={res} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
};
