import React from "react";
import Login from "./login";
import { Sponsors } from "../components/homeComp";
import { Customer } from "../components/aboutComp";
import { ThemeContext } from "../Hooks/context";

const sponsors = [
  "https://ng.jumia.is/cms/0-categories-tng/computing/L1/official-stores/hp-d.png",
  "https://ng.jumia.is/cms/0-categories-tng/computing/L1/official-stores/intel-d.png",
  "https://ng.jumia.is/cms/0-categories-tng/computing/L1/official-stores/asus-d.png",
  "https://ng.jumia.is/cms/0-categories-tng/computing/L1/official-stores/mcafee-d.png",
  "https://ng.jumia.is/cms/0-categories-tng/computing/L1/official-stores/microsoft-d.png",
  "https://ng.jumia.is/cms/0-categories-tng/computing/L1/official-stores/dell-dv2.png",
];

const customer = [
  {
    name: "Adewale Lizzy",
    image:
      "https://images.unsplash.com/photo-1495307299410-57b5ba03852b?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTV8fGxhZHl8ZW58MHwwfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    review: "They deliver on time without any complication",
  },
  {
    name: "John King",
    image:
      "https://images.unsplash.com/photo-1481608726045-7407244fb7b6?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8c3VpdHxlbnwwfDB8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    review: "I got my the suit i ordered for in less than 3 days, great work",
  },
  {
    name: "Bode Thomas",
    image:
      "https://images.unsplash.com/photo-1612846195705-b05358be56e0?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjV8fHN1aXR8ZW58MHwwfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    review: "They deliver on time without any complication",
  },
];

const About = () => {
  const [login, setLogin] = React.useState(false);
  const global = React.useContext(ThemeContext);

  React.useEffect(() => {
    global.setLoading(false);
  }, []);

  return global.loading || global.error ? null : (
    <>
      <div className="page-wrapper">
        {/* End Header */}
        <main className="main">
          <div
            className="page-header "
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1489348611450-4c0d746d949b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=tristan-colangelo-d4OnMIl5OYk-unsplash.jpg)",
              marginTop: "7%",
            }}
          >
            <h1 className="about-title" style={{ color: "#fff" }}>
              Your one-stop shop for creative designs and DIY components
            </h1>
          </div>
          <div className="page-content mt-10 pt-7">
            <section className="about-section">
              <div className="container">
                <h2 className="title mb-lg-9">About Isheda Store</h2>
                <div className="row mb-10">
                  <div className="col-md-6">
                    <img
                      className="w-100 mb-4 appear-animation-visible"
                      data-animation-options="{'name':'fadeInLeftShorter'}"
                      src="https://www.wantedinafrica.com/i/featured/storage/uploads/2020/02/Best_Malls_in_Lagos.jpg.webp"
                      alt="isheda Store"
                      style={{
                        position: "sticky",
                        top: "2rem",
                        borderRadius: "8px",
                        boxShadow: "0px 0px 11px 0px rgba(0, 0, 0, 0.43)",
                      }}
                      width={587}
                      height={517}
                    />
                  </div>
                  <div className="col-md-6 order-md-first pt-md-5">
                    {/* <p className="text-uppercase text-grey mb-0">Who we are</p> */}
                    <h5 className="lh-1 ls-m">ISHEDA ONLINE MALL</h5>
                    <p className="font-primary ">
                      Isheda is the online marketplace for the Industrious
                      Nigerians. Isheda, a yoruba word meaning creation
                      describes the core principle of the company, offering a
                      platform to help you bring your creations to life and sell
                      them. Isheda offers you DIY electronic components at
                      affordable unbeatable prices as well as gives creative
                      individuals or SMEs, both in arts and crafts an
                      opportunity to reach a wider customer base.{" "}
                      <span
                        style={{ fontFamily: "proximaB", fontSize: "1.8rem" }}
                      >
                        Just like creation isn't limited, neither is Isheda
                      </span>
                      . As a company Isheda strives to lead with our principles,
                      reachable by the mass, affordable for all, useable, and
                      makes it possible for YOU to create. Through Isheda, we
                      hope to achieve a sustainable ecosystem that supports
                      global influence, for all businesses regardless of their
                      current capacity.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* End About Section*/}
            {/* <section className="reviews-section grey-section pt-10 pb-10">
              <div
                className="container appear-animation-visible"
                data-animation-options="{'name':'fadeInRightShorter'}"
              >
                <h2 className="title mt-2">Customers Reviews</h2>
                <div
                  style={{ display: "flex" }}
                  className="owl-carousel owl-shadow-carousel owl-same-height owl-theme row cols-lg-3 cols-sm-2 cols-1 pb-4"
                >
                  {customer.map((res) => {
                    return <Customer data={res} />;
                  })}
                </div>
              </div>
            </section> */}
            {/* End Reviews Section */}
            {/* <section className="team-section pt-10 pb-9">
              <div className="container">
                <h2 className="title mt-2">Meet our support team</h2>
                <div className="row cols-sm-2 cols-md-4">
                  <div
                    className="member appear-animation-visible"
                    data-animation-options="{'name': 'fadeInLeftShorter'}"
                  >
                    <figure>
                      <img
                        src="https://images.unsplash.com/photo-1612939969377-0f04327467b2?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzN8fHN1aXR8ZW58MHwwfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        alt="team member"
                        width={280}
                        height={280}
                      />
                      <div className="overlay social-links">
                        <span
                          href="#"
                          className="social-link social-facebook fab fa-facebook-f"
                        />
                        <span
                          href="#"
                          className="social-link social-twitter fab fa-twitter"
                        />
                        <span
                          href="#"
                          className="social-link social-linkedin fab fa-linkedin-in"
                        />
                      </div>
                    </figure>
                    <h4 className="member-name">David Smith</h4>
                    <h5 className="member-job">Ceo / Founder</h5>
                  </div>
                  <div
                    className="member appear-animation-visible"
                    data-animation-options="{'name': 'fadeInLeftShorter', 'delay': '.3s'}"
                  >
                    <figure>
                      <img
                        src="https://images.unsplash.com/photo-1559548331-f9cb98001426?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzR8fHN1aXR8ZW58MHwwfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        alt="team member"
                        width={280}
                        height={280}
                      />
                      <div className="overlay social-links">
                        <span
                          href="#"
                          className="social-link social-facebook fab fa-facebook-f"
                        />
                        <span
                          href="#"
                          className="social-link social-twitter fab fa-twitter"
                        />
                        <span
                          href="#"
                          className="social-link social-linkedin fab fa-linkedin-in"
                        />
                      </div>
                    </figure>
                    <h4 className="member-name">Dylan Chavez</h4>
                    <h5 className="member-job">Support manager / Founder</h5>
                  </div>
                  <div
                    className="member appear-animation-visible"
                    data-animation-options="{'name': 'fadeInLeftShorter', 'delay': '.4s'}"
                  >
                    <figure>
                      <img
                        src="https://images.unsplash.com/photo-1546598210-02780ad97a4e?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDl8fHN1aXR8ZW58MHwwfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        alt="team member"
                        width={280}
                        height={280}
                      />
                      <div className="overlay social-links">
                        <span
                          href="#"
                          className="social-link social-facebook fab fa-facebook-f"
                        />
                        <span
                          href="#"
                          className="social-link social-twitter fab fa-twitter"
                        />
                        <span
                          href="#"
                          className="social-link social-linkedin fab fa-linkedin-in"
                        />
                      </div>
                    </figure>
                    <h4 className="member-name">Christina Fuller</h4>
                    <h5 className="member-job">Supporter</h5>
                  </div>
                  <div
                    className="member appear-animation-visible"
                    data-animation-options="{'name': 'fadeInLeftShorter', 'delay': '.5s'}"
                  >
                    <figure>
                      <img
                        src="https://images.unsplash.com/photo-1558218977-446dc6335015?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NTl8fHN1aXR8ZW58MHwwfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        alt="team member"
                        width={280}
                        height={280}
                      />
                      <div className="overlay social-links">
                        <span
                          href="#"
                          className="social-link social-facebook fab fa-facebook-f"
                        />
                        <span
                          href="#"
                          className="social-link social-twitter fab fa-twitter"
                        />
                        <span
                          href="#"
                          className="social-link social-linkedin fab fa-linkedin-in"
                        />
                      </div>
                    </figure>
                    <h4 className="member-name">Fashola David</h4>
                    <h5 className="member-job">Support</h5>
                  </div>
                </div>
              </div>
            </section>
            End Team Section */}
            {/* End Clients Section */}
          </div>
        </main>
        {/* End Main */}
      </div>
    </>
  );
};

export default About;
