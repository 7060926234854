import React, { useState, useEffect } from "react";
import { ThemeContext } from "../Hooks/context";
import { Container, Row, Col } from "react-bootstrap";
import { Modals } from "../components/General";

import {
  Dashboard,
  SideBar,
  Orders,
  OrderExt,
  Review,
  Wish,
  Edit,
  ChangePassword,
  EmptyUser,
  EmptyOrder,
  Recent,
  LayBy,
} from "../components/accountComp";

import { get_request, post_request } from "../services/makeRequest";

const UserHome = (prop) => {
  const global = React.useContext(ThemeContext);
  const [login, setLogin] = useState(false);

  const [current, setCurrent] = useState("dashboard");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [exit, setExit] = useState();
  const [message, setMessage] = useState(false);

  const [user, setUser] = useState(null);
  const [cities, setCities] = useState();
  const [empty, setEmpty] = useState(false);
  const [fullOrder, setFullOrder] = useState();

  const fetchUser = async (value) => {
    global.setLoading(true);
    try {
      const url = "/user/" + value._id;

      const response = await get_request(url);

      if (!response) {
        if (response.status === 401) {
          setMessage(
            "You have been disconnected for too long, please login Again"
          );
          setOpen(true);
          emptyLogin();
        } else {
          global.setError(true);
          global.setLoading(false);
        }
      }

      if (response) {
        const data = response.data;
        localStorage.setItem("userInfo", JSON.stringify(data));
        setUser(data);
        global.setUserInfo(data);
        global.setLoading(false);
      }
    } catch (err) {
      global.setError(true);
      global.setLoading(false);
    }
  };

  const getCities = async () => {
    global.setLoading(true);
    try {
      const url = "/delivery/city";

      const response = await get_request(url);

      if (!response) {
        global.setError(true);
      }

      if (response) {
        const data = response.data;
        setCities(data.cities);
      }
    } catch (err) {
      global.setError(true);
    }
  };

  const handleOrders = () => {
    setCurrent("orders");
  };

  const handleDashboard = () => {
    // global.setLoading(true);
    setCurrent("dashboard");
  };

  const handleExt = () => {
    setCurrent("orderExt");
  };

  const handleReview = () => {
    setCurrent("review");
  };

  const handleWish = () => {
    setCurrent("wish");
  };

  const handleRecent = () => {
    setCurrent("recent");
  };

  const handleLayby = () => {
    setCurrent("layby");
  };

  const handleEdit = () => {
    setCurrent("edit");
  };

  const handlePassword = () => {
    setCurrent("password");
  };

  function toggleModal() {
    setOpen(!open);
    if (exit) {
      global.setRefresh(true);
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
    getCities();
    prop.location.data
      ? setCurrent(prop.location.data)
      : setCurrent("dashboard");

    if (!global.userInfo) {
      const value = localStorage.getItem("userInfo");
      value ? fetchUser(JSON.parse(value)) : emptyLogin();
    }

    if (global.userInfo) {
      fetchUser(global.userInfo);
    }

    if (prop.location.text) {
      setMessage(prop.location.text);
      setOpen(true);
    }
  }, [prop.location.data]);



  const emptyLogin = () => {
    setEmpty(true);
    global.setLoading(false);
  };

  if (global.loading || global.error) {
    return null;
  }
  return (
    <div className="pt-10 pb-10" style={{ backgroundColor: "#f5f5f5" }}>
      {empty ? (
        <EmptyUser setLogin={setLogin} />
      ) : (
        <Container style={{ marginBottom: "4%" }}>
          <Row className="mainMargin account-page" style={{ display: "flex", flexWrap: "wrap", verticalAlign: 'top' }}>
            <Col sm={3}>
              <SideBar
                current={current}
                handleOrders={handleOrders}
                handleDashboard={handleDashboard}
                handleExt={handleExt}
                handleReview={handleReview}
                handleLayby={handleLayby}
                handleWish={handleWish}
                handleRecent={handleRecent}
                handleEdit={handleEdit}
                handlePassword={handlePassword}

              />
            </Col>
            <Col sm={9}>
              {current === "dashboard" ? (
                <Dashboard user={user} setCurrent={setCurrent} />
              ) : null}

              {refresh ? window.location.reload() : null}

              {current === "orders" ? (
                user.orders.length !== 0 ? (
                  <Orders
                    user={user}
                    setCurrent={setCurrent}
                    setFullOrder={setFullOrder}
                    fetchUser={fetchUser}
                  />
                ) : (
                  <EmptyOrder />
                )
              ) : null}

              {current === "orderExt" ? (
                <OrderExt data={fullOrder} setCurrent={setCurrent} />
              ) : null}

              {current === "review" ? <Review /> : null}

              {current === "wish" ? <Wish user={user} /> : null}

              {current === "recent" ? <Recent user={user} /> : null}

              {current === "layby" ? <LayBy user={user} /> : null}


              {current === "edit" ? (
                <Edit
                  user={user}
                  setLogin={setLogin}
                  setRefresh={setRefresh}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setExit={setExit}
                  cities={cities}
                />
              ) : null}

              {current === "password" ? (
                <ChangePassword
                  user={user}
                  setLogin={setLogin}
                  setRefresh={setRefresh}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setExit={setExit}
                />
              ) : null} {/* */}
            </Col>
          </Row>
        </Container>
      )}
      <Modals
        open={open}
        toggleModal={toggleModal}
        message={message}
        exit={exit}
      />
    </div>
  );
};

export default UserHome;
