import React from "react";
import Login from "./login";
// import bg from "../styles/images/page-header.jpg";
import { Header, Footer } from "../components/General";
// import { Store, About } from "../components/countactusComp";
import { ThemeContext } from "../Hooks/context";
import { post_request } from "../services/makeRequest";

const Contactus = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const global = React.useContext(ThemeContext);

  React.useEffect(() => {
    global.setLoading(false);
  }, []);

  const handleContact = async (value) => {
    global.setLoading(true);
    try {
      const url = "/contactus";

      const payload = {
        name: name,
        email: email,
        message: message,
      };

      const response = await post_request(url, payload);

      if (!response) {
        global.setOpen(true);
        global.setMessage(
          response.data.message
            ? response.data.message
            : "something went wrong please try again 😅"
        );
        global.setExit(true);
        global.setLoading(false);
      }

      if (response) {
        global.setOpen(true);
        global.setMessage(
          "Your Message has been recieved and will be attended to as soon as possible 👍"
        );
        global.setExit(true);
        global.setLoading(false);
      }
    } catch (err) {
      global.setOpen(true);
      global.setMessage("something went wrong please try again 😅");
      global.setExit(true);
      global.setLoading(false);
    }
  };

  return global.loading || global.error ? null : (
    <>
      {/* Main CSS File */}
      <div className="page-wrapper">
        {/* End Header */}
        <main className="main pb-10 pt-10">
          <div
            className="page-header"
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1476973422084-e0fa66ff9456?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFwfGVufDB8MHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60)",
              marginTop: "3%",
            }}
          >
            <h1 className="page-title" style={{ color: "#fff" }}>
              Get in touch
            </h1>
          </div>
          <div className="page-content mt-10 pt-4">
            <section className="contact-section">
              <div className="container">
                {/* <spanbout /> */}

                <div className="row">
                  <div className="col-lg-3 col-md-4 col-xs-5 ls-m pt-3">
                    <h2 className="font-weight-bold text-uppercase ls-m mb-2">
                      Contact us
                    </h2>
                    <p>
                      Looking for help? Fill the form and start a new adventure.
                    </p>
                    <h4 className="mb-1 text-uppercase">Headquarters</h4>
                    <p>
                      Green Garage, House 5, Stateline Road, FUTA South Gate,
                      Akure.
                    </p>
                    <h4 className="mb-1 text-uppercase">Phone</h4>
                    <p>
                      <span>+2348092205588</span>
                    </p>
                    <h4 className="mb-1 text-uppercase">Support</h4>
                    <p>
                      <a href="mailto: support@isheda.com">support@iseda.com</a>
                    </p>
                  </div>
                  <div className="col-lg-9 col-md-8 col-xs-7">
                    <form
                      className="ml-lg-2 pt-8 pb-10 pl-4 pr-4 pl-lg-6 pr-lg-6 grey-section"
                      action="#"
                      onSubmit={handleContact}
                    >
                      <h3 className="ls-m mb-1">Let’s Connect</h3>
                      <p className="text-grey">
                        Your email address will not be published. Required
                        fields are marked *
                      </p>
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Name *"
                            required
                            value={name}
                            onChange={(res) => {
                              setName(res.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-6 mb-4">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Email *"
                            required
                            value={email}
                            onChange={(res) => {
                              setEmail(res.target.value);
                            }}
                          />
                        </div>
                        <div className="col-12 mb-4">
                          <textarea
                            className="form-control"
                            required
                            placeholder="Your Message *"
                            value={message}
                            onChange={(res) => {
                              setMessage(res.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-md btn-primary mb-2"
                      >
                        Send Message
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </section>

            {/* End Map Section */}
          </div>
        </main>
        {/* End Main */}
      </div>
    </>
  );
};

export default Contactus;
