import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
} from "react-bootstrap";
import { ThemeContext } from "../Hooks/context";

const VerifyAccount = (props) => {
  const global = React.useContext(ThemeContext);

  const [user, setUser] = useState();
  const [open, setOpen] = useState();
  const [message, setMessage] = useState();
  const [error, setError] = useState(true);

  const token = props.match.params.token;
  const id = props.match.params.id;
  const url = "https://isheda-backend.onrender.com";

  useEffect(() => {
    handleInfo();
  }, []);

  function toggleModal() {
    setOpen(!open);
    global.setLoading(true);
    global.setRedirect(true);
  }

  const handleInfo = () => {
    global.setLoading(true);
    fetch(url + "/user/" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          global.setError(true);
          global.setLoading(false);
          // global.setLoading(false)
        }
        if (response.ok) {
          const data = await response.json();
          setUser(data);
          global.setLoading(false);
        }
      })
      .catch((error) => {
        global.setError(true);
        global.setLoading(false);
        // error.message === "Network request failed"
        //   ? setErrorMsg("net")
        //   : setErrorMsg("wrong");
      });
  };

  const handleVerification = (res) => {
    res.preventDefault();
    global.setLoading(true);
    fetch(url + "/user/verifyaccount/" + user._id, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify({
        email: user.email,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            // global.setLogin(true);
            setMessage("ErrMsg");
            setOpen(true);
            global.setLoading(false);
          }
          // setError(true);
          // global.setLoading(false);
        }
        if (response.ok) {
          setMessage("success");
          setOpen(true);
          global.setLoading(false);
        }
      })
      .catch((error) => {
        setMessage("ErrMsg");
        setOpen(true);
        global.setLoading(false);
      });
  };

  const Modals = ({ open, message, toggleModal }) => {
    return (
      <Modal size="md" open={open} toggle={toggleModal}>
        <Modal.Header className="d-flex justify-content-center mt-2">
          {message === "success"
            ? "SUCCESS"
            : message === "ErrMsg"
            ? "ERROR!"
            : "Message"}
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-center mt-6">
            {message === "success"
              ? "Your has account has been verified 👍"
              : message === "ErrMsg"
              ? "something Went Wrong, Your account will remain Unverified 💯"
              : message}
          </h6>
        </Modal.Body>
      </Modal>
    );
  };

  return global.loading || global.error ? null : (
    <Container className="d-flex flex-column align-items-center pt-10 pb-5">
      <Col lg="10" className="d-flex justify-content-center pt-10">
        <img
          width="250"
          src="https://img.freepik.com/free-vector/open-locker_53876-25497.jpg?size=626&ext=jpg&ga=GA1.2.1954951261.1617148800"
        />
      </Col>

      <Col lg="10" className="d-flex flex-column align-items-center ">
        <h5>Welldone {user.firstname} for getting this far 👍</h5>
        <span>
          Verify your account to Unlock your account and start Exploring the
          world of Isheda
        </span>
      </Col>

      <Col lg="10" className="d-flex flex-column align-items-center mt-6 mb-6">
        <Button
          size="lg"
          outline
          theme="success"
          className="accontButton"
          onClick={handleVerification}
        >
          Verify Account
        </Button>
      </Col>
      <Modals open={open} message={message} toggleModal={toggleModal} />
    </Container>
  );
};

export default VerifyAccount;
